import {
  ClusterProfile,
  ProjectProfile,
  FunderUserProfile,
  FunderOrganizationProfile,
  ClusterProjectsProfile,
} from 'api';

export const keys = {
  fetchClusterProfile: 'FETCH_CLUSTER_PROFILE',
  fetchProjectProfile: 'FETCH_PROJECT_PROFILE',
};

export const getClusterProfile = id => {
  return ClusterProfile.find(id);
};

export const getProjectProfile = id => {
  return ProjectProfile.find(id);
};

export const getFunderUserProfile = id => {
  return FunderUserProfile.find(id);
};

export const getFunderOrganizationProfile = id => {
  return FunderOrganizationProfile.find(id);
};

export const getProjectsByClusterProfile = id => {
  return ClusterProjectsProfile.find(id);
};
