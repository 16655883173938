import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  AttachMoney,
  Visibility,
  VisibilityOff,
  CheckCircleOutlineRounded,
} from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import {
  capitalTypesFetcher,
  pledgesFetcher,
  authFetcher,
} from 'common/fetchers';
import { useInput, usePasswordValidation } from 'common/hooks';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { isEmpty, validate } from 'validate.js';

const GuestPledgeModal = props => {
  const { open, handleClose, clusterId } = props;
  const [pledgeInstruction, setPledgeInstruction] = useInput('');
  const [capitalTypes, setCapitalTypes] = useState({});
  const [error, setError] = useState('');
  const [isPasswordVisible, setShowPassword] = useState(false);
  const [showPasswordFields, setShowPasswordFields] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);

  const PAGE_SELECT_PLEDGE_TYPE = 'select-pledge-type';
  const PAGE_ENTER_GUEST_DETAILS = 'guest-details';
  const PAGE_SUCCESS_ALERT = 'success-page';
  const [currentPage, setCurrentPage] = useState(PAGE_SELECT_PLEDGE_TYPE);

  const [createGuestPledge] = useMutation(pledgesFetcher.savePublic);
  const history = useHistory();
  const initialFormState = {
    isValid: false,
    values: { guestInfo: {}, password: '', retypePassword: '' },
    touched: {},
    errors: {},
  };
  const [formState, setFormState] = useState(initialFormState);

  const closeModal = () => {
    setShowPasswordFields(false);
    setError('');
    handleClose();
    setCurrentPage(PAGE_SELECT_PLEDGE_TYPE);
    setFormState(initialFormState);
  };

  const [
    validLength,
    hasNumber,
    upperCase,
    lowerCase,
    match,
    specialChar,
  ] = usePasswordValidation({
    firstPassword: formState.values.password,
    secondPassword: formState.values.retypePassword,
  });

  const [registerUser] = useMutation(authFetcher.registerFetcher);

  const handleSubmit = async event => {
    event.preventDefault();
    formState.values.pledgeInstruction =
      pledgeInstruction?.trim() === '' ? null : pledgeInstruction;
    formState.values.clusterId = clusterId;
    setFormSubmitting(() => true);
    let createPledge = true;
    if (showPasswordFields) {
      createPledge = await registerUser(
        {
          email: formState.values.guestInfo?.email,
          firstName: formState.values.guestInfo?.firstName,
          lastName: formState.values.guestInfo?.lastName,
          password: formState.values.password,
          passwordConfirmation: formState.values.password,
          userType: 'fund',
        },
        {
          onError: () => {
            setError(
              'There was a problem creating your account.\nPlease try again later or contact an admin'
            );
            setFormSubmitting(() => false);
            return false;
          },
          onSuccess: () => {
            return true;
          },
        }
      );
    }
    if (createPledge) {
      createGuestPledge(formState.values, {
        onError: error => {
          setError(
            error?.response?.data?.message ??
              'There was a problem creating this pledge.\nPlease try again later or contact an admin.'
          );
        },
        onSuccess: () => {
          setCurrentPage(PAGE_SUCCESS_ALERT);
          setTimeout(() => closeModal(), 6000);
        },
      }).then(() => {
        setFormSubmitting(() => false);
      });
    }
  };

  const passwordValid =
    match && hasNumber && lowerCase && upperCase && specialChar & validLength;

  const checkPassword = () => {
    const error = [];

    !validLength && error.push('Password should contain at least 8 characters');
    !upperCase && error.push('Password should contain an uppercase character');
    !lowerCase && error.push('Password should contain a lowercase character');
    !hasNumber && error.push('Password should contain a digit');
    !specialChar &&
      error.push(
        'Password should contain a special character -+_!@#$%^&*., ?;'
      );

    return error;
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  useEffect(() => {
    capitalTypesFetcher.all().then(({ data }) => {
      setCapitalTypes(data.capitalTypes);
    });
  }, []);

  const schema = {
    email: {
      email: {
        message: 'Please enter a valid email address',
      },
    },
  };
  const handleChange = (field, event) => {
    event.persist();
    const errors = validate(formState.values, schema, { fullMessages: false });

    if (field === 'guestInfo') {
      formState.values.guestInfo[event.target.name] =
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value;
    } else {
      formState.values[event.target.name] =
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value;
    }
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  };

  let pledgeForm = (
    <>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              required
              label="First Name"
              onChange={handleChange.bind(this, 'guestInfo')}
              name="firstName"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              required
              label="Last Name"
              onChange={handleChange.bind(this, 'guestInfo')}
              name="lastName"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              error={hasError('email')}
              helperText={hasError('email') ? formState.errors.email[0] : null}
              label="Contact Email"
              onChange={handleChange.bind(this, 'guestInfo')}
              type="email"
              name="email"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              label="Organization"
              onChange={handleChange.bind(this, 'guestInfo')}
              variant="outlined"
              name="organizationName"
            />
          </Grid>
          <Divider />
          <Grid item container alignItems="center" spacing={2}>
            <Grid item xs={8} justify="flex-start">
              <FormControl style={{ minWidth: '100%' }}>
                <InputLabel>Capital Type</InputLabel>
                <Select
                  name="capitalTypeId"
                  required
                  onChange={handleChange.bind(this, '')}>
                  {Object.keys(capitalTypes).map(index => {
                    const { id, name } = capitalTypes[index];
                    return (
                      <MenuItem key={id} value={id}>
                        {name}{' '}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                required
                // onChange={handleChange}
                onChange={handleChange.bind(this, '')}
                InputProps={{
                  inputProps: {
                    min: 0,
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <AttachMoney />
                    </InputAdornment>
                  ),
                }}
                label="Amount"
                variant="outlined"
                type="number"
                name="amount"
              />
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            justify="flex-end"
            alignItems="right">
            <TextField
              maxLength={255}
              multiline
              variant="outlined"
              label="Pledge Instructions"
              value={pledgeInstruction}
              rows={8}
              onChange={setPledgeInstruction}
              placeholder="Leave instructions for the allocation of your pledge"
            />
            <Typography
              color={pledgeInstruction.length >= 255 ? 'secondary' : 'initial'}
              variant="subtitle2">
              {pledgeInstruction.length}/255 Characters
            </Typography>
          </Grid>
          <Grid container style={{ margin: '12px 0px' }}>
            <Grid container item>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      onClick={() => setShowPasswordFields(!showPasswordFields)}
                    />
                  }
                  value={showPasswordFields}
                  label="Sign up and create a Blueprint account"
                />
              </FormControl>
            </Grid>
            <Grid
              container
              spacing={2}
              style={{
                display: showPasswordFields ? 'inline-flex' : 'none',
              }}>
              <Grid item xs={6}>
                <TextField
                  error={hasError('password')}
                  fullWidth
                  label="Password"
                  name="password"
                  onChange={handleChange.bind(this, '')}
                  type={isPasswordVisible ? 'text' : 'password'}
                  value={formState.values.password || ''}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!isPasswordVisible)}>
                          {isPasswordVisible ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {formState.values.password.length !== 0
                  ? checkPassword()?.map((error, index) => {
                      return (
                        <FormHelperText
                          key={index}
                          style={{ marginLeft: '14px' }}
                          error>
                          {error}
                        </FormHelperText>
                      );
                    })
                  : null}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  error={hasError('password')}
                  fullWidth
                  helperText={
                    hasError('retypePassword')
                      ? formState.errors.password[0]
                      : null
                  }
                  label="Confirm Password"
                  name="retypePassword"
                  placeholder="Retype your password"
                  onChange={handleChange.bind(this, '')}
                  type={formState.showPassword ? 'text' : 'password'}
                  value={formState.values.retypePassword || ''}
                  variant="outlined"
                />
                {match || (
                  <FormHelperText style={{ marginLeft: '14px' }} error>
                    Passwords don&apos;t match
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
      {error && (
        <Grid item xs={12} style={{ marginTop: '2em' }}>
          <Alert severity="error" onClose={() => setError('')}>
            {error}
          </Alert>
        </Grid>
      )}
      <DialogActions>
        <Button
          color="secondary"
          onClick={handleSubmit}
          disabled={
            !formState.isValid ||
            isEmpty(formState?.values?.guestInfo?.firstName) ||
            isEmpty(formState?.values?.guestInfo?.lastName) ||
            isEmpty(formState?.values?.guestInfo?.email) ||
            isEmpty(formState?.values?.guestInfo?.organizationName) ||
            isEmpty(formState?.values?.amount) ||
            isEmpty(formState?.values?.capitalTypeId) ||
            (showPasswordFields === true && !passwordValid)
          }>
          {formSubmitting ? (
            <CircularProgress size={32} />
          ) : showPasswordFields ? (
            'Sign Up & Make Pledge'
          ) : (
            'Pledge'
          )}
        </Button>
        <Button onClick={closeModal} color="default">
          Close
        </Button>
      </DialogActions>
    </>
  );

  let pledgeDesc = (
    <>
      <Grid container item spacing={2} direction="row">
        <Grid item>
          <Button
            variant="contained"
            onClick={() =>
              history.push({
                pathname: '/sign-in',
                state: {
                  redirect: true,
                  redirectLink: window.location.pathname,
                },
              })
            }>
            Log In &amp; Make a Pledge
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setCurrentPage(PAGE_ENTER_GUEST_DETAILS)}>
            Pledge as a Guest
          </Button>
        </Grid>
      </Grid>
    </>
  );

  let successContent = (
    <Grid container direction="column" style={{ padding: '2rem' }}>
      <Grid item xs style={{ textAlign: 'center' }}>
        <CheckCircleOutlineRounded
          style={{ fontSize: '120px' }}
          color="primary"
        />
      </Grid>
      <Grid item xs style={{ textAlign: 'center' }} spacing={4}>
        <Typography
          variant="h2"
          style={{ marginBottom: '2rem', fontWeight: 'bold' }}>
          Thank you!
        </Typography>
        <Typography variant="h4">
          Your pledge has been submitted successfully.
        </Typography>
      </Grid>
    </Grid>
  );

  const switchPage = () => {
    switch (currentPage) {
      case PAGE_SELECT_PLEDGE_TYPE:
        return pledgeDesc;
      case PAGE_ENTER_GUEST_DETAILS:
        return pledgeForm;
      case PAGE_SUCCESS_ALERT:
        return successContent;
      default:
        return pledgeDesc;
    }
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          closeModal();
        }}>
        {currentPage !== PAGE_SUCCESS_ALERT && (
          <DialogTitle disableTypography={false}>
            <Typography variant="h5">Pledge to this Cluster</Typography>
          </DialogTitle>
        )}
        <DialogContent>{switchPage()}</DialogContent>
      </Dialog>
    </>
  );
};

GuestPledgeModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  clusterId: PropTypes.string,
};

export default GuestPledgeModal;
