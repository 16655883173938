import React from 'react';
import { Main as MainLayout, Minimal as MinimalLayout } from 'layouts';
import { RouteWithLayout } from 'components';
import { loadCookie } from 'common/cookies';

const { user } = loadCookie();
const FundersPage = React.lazy(() => import('./Funders'));
const FunderProfile = React.lazy(() => import('./views/FunderProfile'));
const AddUpdateFunder = React.lazy(() => import ('./views/AddUpdateFunder'))

const FundersRoutes = {
  list: (
    <RouteWithLayout
      component={FundersPage}
      exact
      layout={MainLayout}
      path="/funders"
      requireActive
      userAccess={['net', 'admin', 'viewFunder']}
      isPrivate
      redirect={'/dashboard'}
      key="list-funders"
    />
  ),
  profile: (
    <RouteWithLayout
      component={FunderProfile}
      exact
      layout={user ? MainLayout : MinimalLayout}
      path="/profile/funder/organization/:id"
      isPrivate={false}
      key="profile-funder"
    />
  ),
  create: (
    <RouteWithLayout
      component={AddUpdateFunder}
      exact
      layout={MainLayout}
      path="/funders/create"
      requireActive
      userAccess={['admin']}
      redirect={'/dashboard'}
      key="create-funder"
    />
  ),
  edit: (
    <RouteWithLayout
      component={AddUpdateFunder}
      exact
      layout={MainLayout}
      path="/funders/:id/edit"
      requireActive
      userAccess={['admin']}
      redirect={'/dashboard'}
      key="edit-funder"
    />
  ),
};

export default FundersRoutes;
