import React, { useState, useEffect } from 'react';
import { Avatar, Grid, Typography, Chip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { formatDistance } from 'date-fns';
import { useQuery } from 'react-query';
import { EnhancedTable, MenuAction } from 'components';
import {
  fundersFetcher,
  areasFetcher,
  demographicsFetcher,
} from 'common/fetchers';
import { makeStyles } from '@material-ui/styles';
import { loadCookie } from 'common/cookies';

const useStyles = makeStyles(theme => ({
  root: {},
  pink: {
    background: theme.palette.pink,
  },
  filterButton: {
    margin: theme.spacing(1),
  },
}));

const FundersTable = () => {
  const classes = useStyles;
  const { push } = useHistory();
  const { user } = loadCookie();

  const actionsFunders = [
    {
      itemName: 'Table Actions',
      itemActionEvent: function () {
        console.log();
      },
      // eslint-disable-next-line react/display-name
      render: function (rows) {
        return (
          <MenuAction
            items={[
              {
                itemName: 'Edit Funder',
                itemActionEvent: function () {
                  push(`/funders/${rows?.id}/edit`);
                },
              },
            ]}
          />
        );
      },
    },
  ];
  const headCellsFunders = [
    {
      id: 'createdAt',
      numeric: false,
      label: 'Organization',
      disablePadding: false,
      // eslint-disable-next-line react/display-name
      render: function (row, _index, value) {
        return (
          <Grid
            container
            direction="row"
            spacing={7}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={3}>
              <Avatar src={row?.profileImage} />
            </Grid>
            <Grid item container spacing={2} direction="column">
              <Typography
                href={`/profile/funder/organization/${row?.id}`}
                component="a"
                varint="overline"
              >
                {row?.orgName}
              </Typography>
              <Typography variant="subtitle2">
                Created{' '}
                {formatDistance(new Date(value), new Date(), {
                  addSuffix: true,
                })}
              </Typography>
            </Grid>
          </Grid>
        );
      },
    },
    {
      id: 'website',
      numeric: false,
      label: 'Website',
      disablePadding: false,
      // eslint-disable-next-line react/display-name
      render: function (row, _index, value) {
        return (
          <Typography component="a" href={value} variant="overline">
            {value}
          </Typography>
        );
      },
    },
    {
      id: 'email',
      numeric: false,
      label: 'Organization Email',
      disablePadding: false,
      // eslint-disable-next-line react/display-name
      render: function (row, _index, value) {
        return (
          <Typography component="a" href={`mailto:${value}`} variant="overline">
            {value}
          </Typography>
        );
      },
    },
    {
      id: 'zipcode',
      numeric: false,
      label: 'Zipcode',
      disablePadding: false,
      // eslint-disable-next-line react/display-name
      render: function (row, _index, value) {
        return <Typography variant="subtitle2">{value}</Typography>;
      },
    },
    {
      id: 'primaryAreas',
      numeric: false,
      label: 'Areas of Interest',
      disablePadding: false,
      // eslint-disable-next-line react/display-name
      render: function (row, _index, value) {
        return (
          <Grid container spacing={2}>
            {value?.map(({ name, id }) => {
              return (
                <Grid key={`Funders-Table-Area-${id}`} item>
                  <Chip variant="outlined" color="primary" label={name} />
                </Grid>
              );
            })}
          </Grid>
        );
      },
    },
    {
      id: 'capitalTypes',
      numeric: false,
      label: 'Capital Interests',
      disablePadding: false,
      // eslint-disable-next-line react/display-name
      render: function (row, _index, value) {
        return (
          <Grid container spacing={2}>
            {value?.map(({ name, id }) => {
              return (
                <Grid key={`Funders-Table-Capital-Type-${id}`} item>
                  <Chip variant="outlined" color="secondary" label={name} />
                </Grid>
              );
            })}
          </Grid>
        );
      },
    },
    {
      id: 'geography',
      numeric: false,
      label: 'Locations',
      disablePadding: false,
      // eslint-disable-next-line react/display-name
      render: function (row, _index, value) {
        return (
          <Grid container spacing={2}>
            {value
              ?.split(/\r?\n/)
              ?.filter(value => value !== '')
              .map((value, index) => {
                return (
                  <Grid key={`Funders-Table-Geo-Location-${index}`} item>
                    <Chip variant="outlined" label={value} />
                  </Grid>
                );
              })}
          </Grid>
        );
      },
    },
  ];

  const [funders, setFunders] = useState([]);
  const [geography, setGeography] = useState([]);

  const [areaOfWorkFilter, setAreaOfWorkFilter] = useState([]);
  const [geographyFilter, setGeographyFilter] = useState([]);
  const [demographicFilter, setDemographicFilter] = useState([]);
  const [activeFilters, setActiveFilters] = useState([]);

  const { data: areasOfWorkData } = useQuery(
    areasFetcher.keys.fetch,
    areasFetcher.all
  );
  const areas = areasOfWorkData?.data?.areas || [];

  const { data: demographicsData } = useQuery(
    demographicsFetcher.keys.fetch,
    demographicsFetcher.all
  );
  const demographics = demographicsData?.data?.demographics || [];

  const getGeographyListFromFunder = funders => {
    let geographiesWithDuplicates = funders
      .map(funder => {
        return funder.geography?.trim().split('\n') || [];
      })
      .flat()
      .filter(geo => geo !== 'null');
    let uniqueGeographyArray = geographiesWithDuplicates.filter(
      (v, i, a) => a.indexOf(v) === i
    );
    return uniqueGeographyArray.map(geography => {
      return { name: geography, id: geography };
    });
  };
  const searchFilters = [
    {
      label: 'Areas of Interest',
      onApplyFilter: function (filterBy) {
        setAreaOfWorkFilter(() => filterBy);
      },
      options: areas,
      selectedFilters: areaOfWorkFilter,
      styles: classes.filterButton,
    },
    {
      label: 'Demographics',
      onApplyFilter: function (filterBy) {
        setDemographicFilter(() => filterBy);
      },
      options: demographics,
      selectedFilters: demographicFilter,
      styles: classes.filterButton,
    },
    {
      label: 'Locations',
      onApplyFilter: function (filterBy) {
        setGeographyFilter(() => filterBy);
      },
      options: geography,
      selectedFilters: geographyFilter,
      styles: classes.filterButton,
    },
  ];

  const { status: fundersStatus, data: fundersData } = useQuery(
    fundersFetcher.keys.fetch,
    () => fundersFetcher.all(),
    { cacheTime: 100 }
  );
  useEffect(() => {
    if (fundersStatus === 'success') {
      setFunders(() => fundersData?.data);
      setGeography(getGeographyListFromFunder(fundersData?.data));
    }
  }, [fundersStatus, fundersData]);

  const resetFilters = setSearchQuery => {
    setAreaOfWorkFilter([]);
    setDemographicFilter([]);
    setGeographyFilter([]);
    setSearchQuery('');
  };
  const getFilteredRows = rows => {
    const filteredRows = rows.filter(row => {
      let filteredByArea =
        areaOfWorkFilter.length === 0
          ? true
          : row?.primaryAreas
            ?.map(area => area.name)
            .some(area =>
              areaOfWorkFilter.map(filter => filter.name).includes(area)
            );
      let filteredByDemographic =
        demographicFilter.length === 0
          ? true
          : row?.demographics
            ?.map(demo => demo.name)
            .some(demo =>
              demographicFilter.map(filter => filter.name).includes(demo)
            );
      let filteredByGeography =
        geographyFilter.length === 0
          ? true
          : geographyFilter
            .map(geography => geography.name)
            .some(geography => row.geography?.includes(geography));

      return filteredByArea && filteredByDemographic && filteredByGeography;
    });

    // console.log(areaOfWorkFilter, demographicFilter, geographyFilter);

    return filteredRows;
  };

  const getFilter = (filterList, filterType) => {
    return filterList.map(option => {
      return `${filterType}: ${option.name}`;
    });
  };

  useEffect(() => {
    setActiveFilters(
      getFilter(areaOfWorkFilter, 'Area Of Work')
        .concat(getFilter(demographicFilter, 'Demographic'))
        .concat(getFilter(geographyFilter, 'Location'))
    );
  }, [demographicFilter, areaOfWorkFilter, geographyFilter]);

  return (
    <EnhancedTable
      title={''}
      rows={funders || []}
      headers={headCellsFunders}
      loading={fundersStatus === 'loading'}
      primaryLabel="orgName"
      actions={actionsFunders}
      collapseContent={() => null}
      defaultOrderBy="orgName"
      searchFields={['orgName', 'email', 'zipCode', 'website']}
      searchFilters={searchFilters}
      getFilteredRows={getFilteredRows}
      activeFilters={activeFilters}
      resetFilters={resetFilters}
      admin={user?.isAdminUser}
      editFunc={(row) => { push(`/funders/${row?.id}/edit`) }}
    />
  );
};

export default FundersTable;
