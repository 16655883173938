import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import {
  Grid,
  Link,
  Button,
  TextField,
  Typography,
  CircularProgress,
  Snackbar,
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';

import { useMutation } from 'react-query';
import { forgetPasswordFetcher } from './fetchers';

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64,
    },
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
  },
  content: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(15),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(5),
      paddingTop: theme.spacing(15),
    },
  },
  description: {
    marginTop: theme.spacing(2),
  },
  contentBody: {
    display: 'flex',
    flexBasis: 500,
    flexDirection: 'column',
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  resetButton: {
    margin: theme.spacing(2, 0),
  },
  returnToSignIn: {
    display: 'flex',
    alignItems: 'center',
  },
  signIn: {
    marginLeft: theme.spacing(1),
  },
  toast: {
    marginTop: theme.spacing(8),
  },
}));

const ForgetPassword = () => {
  //const { history } = props;
  const classes = useStyles();
  const [forgetPassword, { status }] = useMutation(forgetPasswordFetcher);
  const [submitted, setSubmitted] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formState, setFormState] = useState({
    isValid: false,
    email: '',
  });

  useEffect(() => {
    const validEmail = validate({ email: formState.email }, schema);

    setFormState(formState => ({
      ...formState,
      isValid: validEmail?.email ? false : true,
    }));
  }, [formState.email]);

  const handleChange = event => {
    event.persist();

    if (submitted) setSubmitted(false);

    setFormState(formState => ({
      ...formState,
      email: event.target.value,
    }));
  };

  const handleReset = async event => {
    event.preventDefault();

    setFormSubmitting(() => true);
    await forgetPassword(formState.email, {
      onError: () => {
        setFormState(() => ({
          isValid: true,
          email: '',
        }));
        setFormSubmitting(() => false);
      },
      onSuccess: () => {
        setSubmitted(true);
        setFormState(formState => ({
          ...formState,
          email: '',
        }));
        setFormSubmitting(() => false);
      },
    });
  };

  return (
    <div className={classes.root}>
      <Grid className={classes.content} item xs={12}>
        <div className={classes.contentBody}>
          <form className={classes.form} onSubmit={handleReset}>
            <Typography variant="h2">Forgot Password?</Typography>
            <Typography
              className={classes.description}
              color="textSecondary"
              gutterBottom
            >
              Enter the email associated with your account and we will send an
              email with instructions to reset your password.
            </Typography>
            <Snackbar
              open={submitted}
              className={classes.toast}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              autoHideDuration={6000}
            >
              <Alert severity="success" variant="filled">
                Successfully Sent !
              </Alert>
            </Snackbar>
            <TextField
              className={classes.textField}
              fullWidth
              label="Email address"
              name="email"
              onChange={handleChange}
              type="text"
              value={formState.email || ''}
              variant="outlined"
            />
            <Button
              className={classes.resetButton}
              color="primary"
              disabled={!formState.isValid || status === 'loading'}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              {formSubmitting ? (
                <CircularProgress size={32} />
              ) : (
                'Reset Password'
              )}
            </Button>

            <Typography color="textSecondary" variant="body1">
              <Link
                className={classes.returnToSignIn}
                component={RouterLink}
                to="/sign-in"
                variant="h6"
              >
                <ArrowBack fontSize={'small'} />
                <span className={classes.signIn}>Sign In</span>
              </Link>
            </Typography>
          </form>
        </div>
      </Grid>
    </div>
  );
};

ForgetPassword.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ForgetPassword);
