import React from 'react';
import { Main as MainLayout, Minimal as MinimalLayout } from 'layouts';
import { RouteWithLayout } from 'components';
import { loadCookie } from 'common/cookies';

const { user } = loadCookie();
const ClusterPage = React.lazy(() => import('./Clusters'));
const AddUpdateClusterPage = React.lazy(() =>
  import('./views/AddUpdateCluster')
);
const ClusterDetailsPage = React.lazy(() => import('./views/ClusterDetails')); // prettier-ignore
const ClusterProfilePage = React.lazy(() => import('./views/ClusterProfile'));
const ClusterRoutes = {
  list: (
    <RouteWithLayout
      component={ClusterPage}
      exact
      layout={MainLayout}
      path="/clusters"
      requireActive
      isPrivate
      userAccess={['net', 'fund', 'admin', 'manageCluster']}
      redirect={'/dashboard'}
      key="list-cluster"
    />
  ),
  create: (
    <RouteWithLayout
      component={AddUpdateClusterPage}
      exact
      layout={MainLayout}
      path="/clusters/create"
      isPrivate
      requireActive
      userAccess={['net', 'manageCluster']}
      redirect={'/dashboard'}
      key="create-cluster"
    />
  ),
  edit: (
    <RouteWithLayout
      component={AddUpdateClusterPage}
      exact
      isPrivate
      layout={MainLayout}
      path="/clusters/:id/edit"
      requireActive
      userAccess={['net', 'manageCluster']}
      redirect={'/dashboard'}
      key="edit-cluster"
    />
  ),
  detail: (
    <RouteWithLayout
      component={ClusterDetailsPage}
      exact
      isPrivate
      layout={MainLayout}
      path="/clusters/:id"
      requireActive
      userAccess={['net', 'admin', 'manageCluster']}
      redirect={'/dashboard'}
      key="detail-cluster"
    />
  ),
  profile: (
    <RouteWithLayout
      component={ClusterProfilePage}
      exact
      layout={
        user?.isFundUser ||
        user?.isNetworkUser ||
        user?.isAdminUser ||
        user?.manageCluster
          ? MainLayout
          : MinimalLayout
      }
      path="/profile/cluster/:id"
      isPrivate={false}
      key="profile-cluster"
    />
  ),
};

export default ClusterRoutes;
