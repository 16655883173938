import React from 'react';
import { RouteWithLayout } from 'components';
import { Main as MainLayout, Minimal as MinimalLayout } from 'layouts';
import { loadCookie } from 'common/cookies';

const { user } = loadCookie();
const ProjectsPage = React.lazy(() => import('./Projects'));
const AddUpdateProjectPage = React.lazy(() => import('./views/AddUpdateProject')); // prettier-ignore
const ProjectProfile = React.lazy(() => import('./views/ProjectProfile')) // prettier-ignore

const ProjectRoutes = {
  list: (
    <RouteWithLayout
      component={ProjectsPage}
      exact
      layout={MainLayout}
      path="/projects"
      requireActive={false}
      userAccess={['org', 'net', 'admin', 'manageProject', 'manageCluster']}
      redirect={'/dashboard'}
      key="list-projects"
    />
  ),
  create: (
    <RouteWithLayout
      component={AddUpdateProjectPage}
      exact
      layout={MainLayout}
      path="/projects/create"
      requireActive
      userAccess={['org', 'manageProject', 'admin']}
      redirect={'/dashboard'}
      key="create-projects"
    />
  ),
  edit: (
    <RouteWithLayout
      component={AddUpdateProjectPage}
      exact
      layout={MainLayout}
      path="/projects/:id/edit"
      requireActive
      userAccess={['org', 'manageProject', 'admin']}
      redirect={'/dashboard'}
      key="edit-project"
    />
  ),
  profile: (
    <RouteWithLayout
      component={ProjectProfile}
      exact
      layout={
        user?.isFundUser || user?.isNetworkUser || user?.isAdminUser
          ? MainLayout
          : MinimalLayout
      }
      path="/profile/project/:id"
      isPrivate={false}
      key="profile-project"
    />
  ),
};

export default ProjectRoutes;
