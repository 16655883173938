import React from 'react';
import PropTypes from 'prop-types';

import { ConfirmationModal } from 'components';

const PledgeAssignmentConfirmation = ({ props }) => {
  const {
    pledgeAssignConfirmation,
    setPledgeAssignConfirmation,
    pledge,
    assignFundsStatus,
    projectAllocations,
    requestedAllocations,
    handleConfirm,
  } = props;

  return (
    <ConfirmationModal
      openConfirmationModal={pledgeAssignConfirmation}
      setOpenConfirmationModal={setPledgeAssignConfirmation}
      modalTitle={'Projects Fund Assignment Confirmation'}
      confirmationStatus={assignFundsStatus}
      handleConfirm={handleConfirm}
      modalContent={
        <>
          Confirming that the allocation of pledge on capital type{' '}
          <b>{pledge.capitalType?.name}</b> from funder{' '}
          <b>{pledge.funder?.orgName}</b> of total{' '}
          <b>
            $
            {new Intl.NumberFormat('USA', {
              style: 'decimal',
            }).format(pledge.amount)}
          </b>
          <br />
          The distribution will be done as follows:
          <br />
          {requestedAllocations?.capitalAllocation?.map(
            ({ project: { id: projectId, name: projectName }, fundAmount }) => (
              <b key={`capitalAllocation-${projectId}`}>
                {projectName}: $
                {new Intl.NumberFormat('USA', {
                  style: 'decimal',
                }).format(fundAmount)}
                {projectAllocations?.[projectId]
                  ? ` (${projectAllocations[projectId]?.percentage}%)`
                  : null}
                <br />
              </b>
            )
          )}
        </>
      }
    />
  );
};

PledgeAssignmentConfirmation.propTypes = {
  props: PropTypes.object,
  Transition: PropTypes.object,
  pledgeAssignConfirmation: PropTypes.bool,
  setPledgeAssignConfirmation: PropTypes.func,
  pledge: PropTypes.object,
  assignFundsStatus: PropTypes.string,
  projectAllocations: PropTypes.array,
  requestedAllocations: PropTypes.object,
  handleConfirm: PropTypes.func,
};
export default PledgeAssignmentConfirmation;
