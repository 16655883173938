import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  CardContent,
  Box,
  Grid,
  Container,
  Paper,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
  ProfileHeader,
  ProfileFundProgress,
  ProfileBreakdown,
  ProfileCollections,
} from './components';
import parse from 'html-react-parser';
import { EnhancedTimeline } from 'components';

import { EmptyFallback } from 'components';
import {
  ProfileCard,
  ProfileCardHeader,
} from './components/ProfilePageComponents';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    minHeight: '100%',
  },
  box: {
    paddingTop: theme.spacing(2),
  },
}));

const GeneralProfile = props => {
  const classes = useStyles();

  const { status } = props;
  const {
    collectionType,
    admin,
    demographics,
    areas,
    details,
    pledgeAction,
  } = props.headerDetails;

  const {
    collectionDonationCount,
    fundGoal,
    fundGoalReached,
    collectionID,
  } = props.fundProgress;

  const { capitalNeeds, collections } = props;
  const isAuthorized = props.isAuthorized ?? true;
  const [currentTab, setCurrentTab] = useState(0);
  const [goals, setGoals] = useState(details?.goals || []);
  const isEdit = false;

  const handleChange = (e, value) => {
    setCurrentTab(value);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}>
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  useEffect(() => {
    setGoals(details?.goals);
  }, [details]);

  const overviewJSX = details.overview ? (
    parse(details.overview)
  ) : (
    <Typography>'No overview'</Typography>
  );

  return isAuthorized ? (
    <Box className={classes.root}>
      <Grid container spacing={4} direction="column">
        <Grid container justify="center" alignItems="center" item xs={12}>
          <Container maxWidth="xl">
            <ProfileHeader
              status={status}
              admin={admin}
              demographics={demographics}
              areas={areas}
              details={details}
              collectionType={collectionType}
              pledgeAction={pledgeAction}
            />
          </Container>
        </Grid>
        <Grid container justify="center" alignItems="center" item xs={12}>
          <Container maxWidth="xl">
            <ProfileFundProgress
              collectionDonationCount={collectionDonationCount}
              fundGoal={fundGoal}
              fundGoalReached={fundGoalReached}
              collectionID={collectionID}
              collectionType={collectionType}
              enablePledgeButton={collections?.length > 0}
            />
          </Container>
        </Grid>
        <Grid container item>
          <Container maxWidth="xl">
            <Paper style={{ marginBottom: '.5rem' }}>
              <Tabs value={currentTab} onChange={handleChange}>
                <Tab label="Overview" />
                <Tab label="Funding" />
                <Tab
                  label={collectionType === 'project' ? 'Clusters' : 'Projects'}
                />
                {collectionType === 'project' ? <Tab label="Goals" /> : null}
              </Tabs>
            </Paper>
            <TabPanel value={currentTab} index={0}>
              <ProfileCard>
                <ProfileCardHeader title="Overview" />
                <CardContent>{overviewJSX}</CardContent>
              </ProfileCard>
            </TabPanel>
            <TabPanel value={currentTab} index={1}>
              <ProfileBreakdown capitalNeeds={capitalNeeds} />
            </TabPanel>
            <TabPanel value={currentTab} index={2}>
              <ProfileCollections
                collections={collections}
                collectionType={collectionType}
              />
            </TabPanel>
            {collectionType === 'project' ? (
              <TabPanel value={currentTab} index={3}>
                <EnhancedTimeline
                  goals={goals || []}
                  setGoals={null}
                  isEdit={isEdit}
                />
              </TabPanel>
            ) : null}
          </Container>
        </Grid>
      </Grid>
    </Box>
  ) : (
    <Box className={classes.root}>
      <Grid container justify="center" alignItems="center" item xs={12}>
        <Container maxWidth="xl">
          <EmptyFallback
            heading="This profile is no longer available."
            action="Please contact your administrator."
          />
        </Container>
      </Grid>
    </Box>
  );
};

GeneralProfile.propTypes = {
  status: PropTypes.object,
  headerDetails: PropTypes.exact({
    collectionType: PropTypes.string,
    admin: PropTypes.string,
    demographics: PropTypes.string,
    areas: PropTypes.string,
    details: PropTypes.array,
    pledgeAction: PropTypes.func,
  }),
  fundProgress: PropTypes.exact({
    collectionDonationCount: PropTypes.number,
    fundGoal: PropTypes.number,
    fundGoalReached: PropTypes.number,
    collectionID: PropTypes.string,
  }),
  capitalNeeds: PropTypes.array,
  collections: PropTypes.array,
  isAuthorized: PropTypes.bool,
};

export default GeneralProfile;
