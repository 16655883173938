import React from 'react';
import { RouteWithLayout } from 'components';
import { Main as MainLayout } from 'layouts';
import { Switch, Redirect } from 'react-router-dom';

import DashboardPage from './Dashboard';

const DashboardRoutes = () => {
  return (
    <Switch>
      <RouteWithLayout
        component={DashboardPage}
        exact
        layout={MainLayout}
        path="/dashboard"
        isPrivate
        requireActive={false}
        userAccess={['admin', 'net', 'fund']}
        redirect="/sign-in"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default DashboardRoutes;
