import React from 'react';
import { Main as MainLayout } from 'layouts';
import { RouteWithLayout } from 'components';

const OrganizationsPage = React.lazy(() => import('./Organizations'));

const OrganizationRoutes = {
  list: (
    <RouteWithLayout
      component={OrganizationsPage}
      exact
      layout={MainLayout}
      path="/organizations"
      requireActive
      isPrivate
      userAccess={['admin', 'manageOrganization']}
      redirect={'/dashboard'}
      key="list-organizations"
    />
  ),
};

export default OrganizationRoutes;
