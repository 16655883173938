import {
  Projects,
  ProjectsByOrganization,
  ProjectsByCluster,
  ProjectsByMangeProject,
} from 'api';

// Keys are used to allow us to assign the same key
// for the same type of request so data can be cached
// with react-query
export const keys = {
  fetch: 'FETCH_PROJECTS',
  fetchByCluster: 'FETCH_PROJECTS_BY_CLUSTER',
};

export const all = () => {
  return Projects.all();
};

export const allByOne = id => {
  return Projects.find(id);
};

export const allByOrganization = organizationId => {
  return ProjectsByOrganization.find(organizationId);
};

export const allByCluster = clusterId => {
  return ProjectsByCluster.find(clusterId);
};

export const allByManageProjectUser = () => {
  return ProjectsByMangeProject.all();
};

export const save = project => {
  return Projects.post(project);
};

export const batchSave = projects => {
  return Projects.post(projects, '/batchCreate');
};

export const update = project => {
  return Projects.update(project?.id, project);
};

export const remove = id => {
  return Projects.remove(id);
};
