import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useQuery, useMutation } from 'react-query';
import { NoteAdd } from '@material-ui/icons';

import {
  Grid,
  Typography,
  Tooltip,
  LinearProgress,
  Button,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';

import { clustersFetcher, projectsFetcher } from 'common/fetchers';
import { EnhancedTable, CapitalBreakdownModal } from 'components';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    minWidth: 600,
  },
  cardContent: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
  progress: {
    width: '50%',
    marginTop: theme.spacing(1),
  },
  progressContainer: {
    width: '100%',
  },
  capitalTypeBreakdown: {
    marginTop: theme.spacing(1),
  },
}));

const AddProjectToCluster = props => {
  const classes = useStyles();
  const { id, open, setModalOpen, submissionCallback } = props;
  const [selectedRows, setSelectedRows] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  //const [project, setProject] = useState();
  //const [clusters, setClusters] = useState([]);

  const [addProject] = useMutation(clustersFetcher.addProject);
  const [removeProject] = useMutation(clustersFetcher.removeProject);

  const headCellsClusters = [
    {
      id: 'name',
      numeric: false,
      label: 'Name',
      disablePadding: false,
      // eslint-disable-next-line react/display-name
      render: (row, _index, value) => {
        return (
          <Grid container direction="column">
            <Typography variant="h6" align="left">
              {value.toUpperCase()}
            </Typography>
            <Typography align="left" variant="overline">
              {row?.projects?.length || 'No'}{' '}
              {row?.projects?.length > 1 ? 'projects' : 'project'} assigned
            </Typography>
          </Grid>
        );
      },
    },
    {
      id: 'Goal',
      numeric: false,
      label: 'Goal',
      disablePadding: false,
      // eslint-disable-next-line react/display-name
      render: row => {
        const totalClusterBudget = row?.projects
          ?.map(({ capitalTypesProjects }) => capitalTypesProjects)
          ?.reduce((acc, curr) => [...acc, ...curr], [])
          ?.map(({ budgetGoal }) => Number(budgetGoal))
          ?.reduce((acc, curr) => acc + curr, 0);

        const totalRaisedAmount = row?.projects
          ?.map(({ capitalTypesProjects }) => capitalTypesProjects)
          ?.reduce((acc, curr) => [...acc, ...curr], [])
          ?.map(({ budgetReached }) => Number(budgetReached))
          ?.reduce((acc, curr) => acc + curr, 0);

        let approvedPledges = 0;
        for (let i = 0; i < row?.pledges.length; i++) {
          let { assigned, amount } = row?.pledges[i];
          if (assigned) {
            approvedPledges += Number(amount);
          }
        }

        const progress =
          ((totalRaisedAmount + approvedPledges) / totalClusterBudget) * 100 ||
          0;

        // Capital Type Breakdown for cluster
        const capitalTypesProjects = row?.projects
          ?.map(({ capitalTypesProjects }) => capitalTypesProjects)
          ?.reduce((acc, curr) => [...acc, ...curr], []);

        const capitalTypeFunds = row?.projects
          ?.map(({ funds }) => funds)
          ?.reduce((acc, curr) => [...acc, ...curr], []);

        const budgetCache = {};
        const capitalTypeBreakdown = [];
        capitalTypesProjects.forEach(
          ({ capitalType: { id, name }, budgetReached, budgetGoal }) => {
            if (!Object.prototype.hasOwnProperty.call(budgetCache, id)) {
              capitalTypeBreakdown.push({
                name,
                budgetGoal: Number(budgetGoal),
                budgetReached: Number(budgetReached),
                totalCapitalTypeFunds: 0,
              });
              budgetCache[id] = capitalTypeBreakdown.length - 1;
            } else {
              const index = budgetCache[id];
              capitalTypeBreakdown[index] = {
                ...capitalTypeBreakdown[index],
                budgetReached:
                  capitalTypeBreakdown[index]?.budgetReached +
                  Number(budgetReached),
                budgetGoal:
                  capitalTypeBreakdown[index]?.budgetGoal + Number(budgetGoal),
              };
            }
          }
        );

        const fundsCache = {};
        const fundsBreakdown = [];
        capitalTypeFunds.forEach(({ pledge: { capitalTypeId }, amount }) => {
          if (
            !Object.prototype.hasOwnProperty.call(fundsCache, capitalTypeId)
          ) {
            fundsBreakdown.push({
              capitalTypeId,
              amount: Number(amount),
            });
            fundsCache[capitalTypeId] = capitalTypeBreakdown.length - 1;
          } else {
            const index = fundsCache[capitalTypeId];
            fundsBreakdown[index] = {
              ...fundsBreakdown[index],
              amount: fundsBreakdown[index]?.amount + Number(amount),
            };
          }
        });

        // Aggregate capitalTypeBreakdown
        fundsBreakdown.forEach(({ capitalTypeId, amount }) => {
          const index = budgetCache[capitalTypeId];
          capitalTypeBreakdown[index] = {
            ...capitalTypeBreakdown[index],
            totalCapitalTypeFunds: amount || 0,
          };
        });

        return (
          <Grid container direction="column" justifyContent="center" alignItems="left">
            <Grid item>
              {new Intl.NumberFormat('USA', {
                style: 'currency',
                currency: 'USD',
              }).format(totalClusterBudget)}
            </Grid>
            <Grid item className={classes.progressContainer}>
              <Tooltip
                title={`${new Intl.NumberFormat('USA', {
                  style: 'currency',
                  currency: 'USD',
                }).format(
                  Number(totalRaisedAmount) + Number(approvedPledges)
                )}`}
                aria-label="progress">
                <LinearProgress
                  className={classes.progress}
                  value={progress <= 100 ? progress : 100}
                  variant="determinate"
                />
              </Tooltip>
            </Grid>
            <Grid item className={classes.capitalTypeBreakdown}>
              <CapitalBreakdownModal
                capitalTypeBreakdown={capitalTypeBreakdown}
              />
            </Grid>
          </Grid>
        );
      },
    },
    {
      id: 'pledges',
      numeric: false,
      label: 'Pledges',
      disablePadding: false,
      // eslint-disable-next-line react/display-name
      render: row => {
        const amountPledged = row?.pledges?.reduce(
          (acc, { amount }) => acc + Number(amount),
          0
        );

        return (
          <Grid container direction="column">
            {new Intl.NumberFormat('USA', {
              style: 'currency',
              currency: 'USD',
            }).format(amountPledged)}
          </Grid>
        );
      },
    },
    {
      id: 'approvedPledges',
      numeric: false,
      label: 'Approved Pledges',
      disablePadding: false,
      // eslint-disable-next-line react/display-name
      render: row => {
        const approvedPledges = row?.pledges
          ?.filter(({ assigned }) => assigned)
          .reduce((acc, { amount }) => acc + Number(amount), 0);

        return (
          <Grid container direction="column">
            {new Intl.NumberFormat('USA', {
              style: 'currency',
              currency: 'USD',
            }).format(approvedPledges)}
          </Grid>
        );
      },
    },
  ];

  const handleSubmit = async (selectedClusterIds, unSelectedClusterIds) => {
    setSubmitting(true);
    let success = false;
    let onSuccess = () => (success = true);
    let onError = () => (success = false);

    for (let i = 0; i < selectedClusterIds.length; i++)
      await addProject(
        { clusterId: selectedClusterIds[i], projectId: project.id },
        {
          onSuccess: onSuccess,
          onError: onError,
        }
      );

    for (let i = 0; i < unSelectedClusterIds.length; i++)
      await removeProject(
        { clusterId: unSelectedClusterIds[i], projectId: project.id },
        {
          onSuccess: onSuccess,
          onError: onError,
        }
      );

    if (success) {
      submissionCallback();
    }

    setSubmitting(false);
    setModalOpen(false);
  };

  const updateProjectAttachments = {
    name: 'Update Project Attachments',
    icon: (
      <Button
        color="primary"
        type="submit"
        variant="contained"
        startIcon={<NoteAdd />}>
        Update
      </Button>
    ),
    action: rowIds => {
      const selectedClusterIds = rowIds;
      const unSelectedClusterIds = clusters
        ?.map(({ id }) => id)
        ?.filter(id => !selectedClusterIds.includes(id));
      handleSubmit(selectedClusterIds, unSelectedClusterIds);
    },
    actionStatus: submitting,
  };

  const { data: projectData, status: projectStatus } = useQuery(
    projectsFetcher.keys.fetch + 'Add-To-Cluster' + id,
    async () => await projectsFetcher.allByOne(id),
    { refetchOnMount: true }
  );
  const { data: clusterData } = useQuery(
    clustersFetcher.keys.fetch + 'Add To Cluster' + id,
    async () => await clustersFetcher.findAllByUser()
  );

  const clusters = clusterData?.data?.clusters || [];
  const project = projectData?.data?.project;

  useEffect(() => {
    if (project && clusters?.length > 0) {
      const sr = clusters?.filter(({ id: clusterId }) =>
        project?.clusters?.map(({ id }) => id).includes(clusterId)
      );
      setSelectedRows(sr);
    }
  }, [clusterData, projectData, project, clusters]);

  const handleClose = () => setModalOpen(false);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xl">
      <DialogTitle>Attach/Detach Clusters from {project?.name}</DialogTitle>
      <Divider />
      <DialogContent className={classes.root}>
        <EnhancedTable
          title=""
          defaultOrderBy="name"
          headers={headCellsClusters}
          headerActions={[]}
          loading={projectStatus === 'loading' || clusterData === 'loading'}
          rows={clusters}
          selectedRows={selectedRows}
          selectionActions={[updateProjectAttachments]}
          actions={[]}
          batchSelection
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddProjectToCluster.propTypes = {
  id: PropTypes.string,
  open: PropTypes.bool,
  setModalOpen: PropTypes.func,
  submissionCallback: PropTypes.func,
};

export default AddProjectToCluster;
