import React from 'react';
import { LinearProgress, Tooltip } from '@material-ui/core';
import { loadCookie } from 'common/cookies';

const PledgeCell = props => {
  const { user } = loadCookie();
  const { project } = props;

  // const [
  //   hideFinancialsProject,
  //   hideFinancialsCluster,
  // ] = project.clusters.reduce(
  //   (m, c) => [
  //     m[0] || c.hideFinancialsProjectLead,
  //     m[1] || c.hideFinancialsExtracluster,
  //   ],
  //   [false, false]
  // );

  const hideFinancials = project.clusters.reduce(
    (m, c) => m || c.hideFinancialsProjectLead || c.hideFinancialsExtracluster,
    false
  );

  if (hideFinancials && project.createdBy !== user.id) {
    return (
      <>
        <Tooltip title={'fpo: obfuscated values tooltip message'}>
          <span>--</span>
        </Tooltip>
      </>
    );
  } else {
    const amountPledged = project?.funds?.reduce(
      (prev, curr) => (prev += Number(curr?.amount)),
      0
    );

    return (
      <>
        {new Intl.NumberFormat('USA', {
          style: 'currency',
          currency: 'USD',
        }).format(amountPledged)}
      </>
    );
  }
  // comment /////////////////////////////////////////////////////////////////
  // console.log(user);
  console.log();

  // comment /////////////////////////////////////////////////////////////////
};
export default PledgeCell;
