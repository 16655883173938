import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import { makeStyles } from '@material-ui/styles';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import {
  Card,
  Grid,
  Button,
  Divider,
  TextField,
  CardHeader,
  CardContent,
  CardActions,
  MenuItem,
  Typography,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
} from '@material-ui/core';
import moment from 'moment';
import { Pagination } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import FlagOutlinedIcon from '@material-ui/icons/FlagOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { ControlPoint } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  red: {
    backgroundColor: '#e51717',
    color: 'white',
  },
}));

const EnhancedTimeline = props => {
  const { goals, setGoals, isEdit } = props;

  const statuses = ['TO DO', 'IN PROGRESS', 'DONE'];
  const classes = useStyles();
  const [openGoalModal, setOpenGoalModal] = useState({
    open: false,
    edit: false,
    data: null,
  });
  const [goalPageStart, setGoalPageStart] = useState(0);
  const [goalPageEnd, setGoalPageEnd] = useState(3);
  const [goalSidebarPage, setGoalSidebarPage] = useState(1);
  const [goalSidebarPageCount, setGoalSidebarPageCount] = useState(1);
  const [goalStartDate, handleChangeGoalStartDate] = useState(null);
  const [goalTargetDate, handleChangeGoalTargetDate] = useState(null);
  const [projectGoals, setProjectGoals] = useState(goals || []);
  const [updatedGoal, setUpdatedGoal] = useState({
    title: null,
    startDate: null,
    targetDate: null,
    description: null,
    status: 'TO DO',
  });

  useEffect(() => {
    if (!projectGoals) {
      setProjectGoals(goals || []);
    }

    setGoalSidebarPageCount(
      projectGoals.length > 3 ? Math.ceil(projectGoals.length / 3) : 1
    );
  }, [projectGoals, openGoalModal, goals]);

  const handleGoalSidebarPagniation = (event, value) => {
    setGoalSidebarPage(value);
    setGoalPageStart(value * 3 - 3);
    setGoalPageEnd(value * 3);
  };

  const handleGoalUpdate = edit => {
    try {
      const tempGoals = projectGoals || [];
      if (edit) {
        const objIndex = updatedGoal.index;
        tempGoals[objIndex].title = updatedGoal.title;
        tempGoals[objIndex].startDate = updatedGoal.startDate;
        tempGoals[objIndex].targetDate = updatedGoal.targetDate;
        tempGoals[objIndex].description = updatedGoal.description;
        tempGoals[objIndex].status = updatedGoal.status;
        setGoals(tempGoals);
        setProjectGoals(tempGoals);
      } else {
        tempGoals.push(updatedGoal);
        setGoals(tempGoals);
        setProjectGoals(tempGoals);
      }
    } catch (error) {
      console.log(`error: ${error}`);
    }

    setOpenGoalModal({ open: false, edit: false, data: null });
    handleChangeGoalStartDate(null);
    handleChangeGoalTargetDate(null);
  };

  const handleGoalDelete = () => {
    const temp = projectGoals || [];
    try {
      const objIndex = updatedGoal.index;

      if (objIndex > -1) {
        temp.splice(objIndex, 1);
        setGoals(temp);
        setProjectGoals(temp);
      }
    } catch (error) {
      console.log(`error: ${error}`);
    }

    setOpenGoalModal({ open: false, edit: false, data: null });
    handleChangeGoalStartDate(null);
    handleChangeGoalTargetDate(null);
  };

  const handleGoalChange = event => {
    const { name, value } = event.target;
    setUpdatedGoal(updatedGoal => ({
      ...updatedGoal,
      [name]: value,
    }));
  };

  return (
    <div>
      <Card>
        <CardHeader
          title={
            <Grid container>
              <Grid item xs={11}>
                {isEdit ? (
                  <Typography
                    variant="h4"
                    style={{
                      verticalAlign: 'middle',
                      display: 'inline-flex',
                      marginLeft: '1rem',
                    }}>
                    <FlagOutlinedIcon style={{ marginRight: '10px' }} /> GOALS
                  </Typography>
                ) : (
                  <Typography
                    variant="h4"
                    style={{
                      fontSize: '20px',
                      fontWeight: 'bold',
                    }}>
                    Goals
                  </Typography>
                )}
              </Grid>
              <Grid item xs={1}>
                {isEdit && (
                  <ControlPoint
                    color="primary"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setUpdatedGoal({
                        title: null,
                        startDate: null,
                        targetDate: null,
                        description: null,
                        status: 'TO DO',
                      });
                      setOpenGoalModal({ open: true, edit: false, data: null });
                    }}
                  />
                )}
              </Grid>
            </Grid>
          }
        />
        {isEdit && <Divider />}
        <CardContent style={{ padding: '0px' }}>
          {projectGoals.length > 0 ? (
            <Timeline align="alternate">
              {projectGoals
                ?.slice(goalPageStart, goalPageEnd)
                .map((goal, index) => {
                  return (
                    <TimelineItem key={index + 3 * goalSidebarPage - 3}>
                      <TimelineOppositeContent
                        style={{ marginTop: '0.875rem' }}>
                        <Typography
                          style={{ textAlign: 'center' }}
                          variant="body2"
                          color="textSecondary">
                          {moment(goal.startDate).format('MMM D YYYY')} <br />{' '}
                          to
                        </Typography>
                        <Typography
                          style={{ textAlign: 'center' }}
                          variant="body1"
                          color="textSecondary">
                          {moment(goal.targetDate).format('MMM D YYYY')}
                        </Typography>
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot
                          style={{ padding: isEdit ? '0px' : '5px' }}
                          color="primary">
                          {isEdit && (
                            <IconButton
                              onClick={() => {
                                setOpenGoalModal({
                                  open: true,
                                  edit: true,
                                  data: goal,
                                });
                                setUpdatedGoal({
                                  index: index + 3 * goalSidebarPage - 3,
                                  title: goal.title,
                                  startDate: goal.startDate,
                                  targetDate: goal.targetDate,
                                  description: goal.description,
                                  status: goal.status,
                                });
                                handleChangeGoalStartDate(goal.startDate);
                                handleChangeGoalTargetDate(goal.targetDate);
                              }}>
                              <EditOutlinedIcon
                                style={{
                                  color: 'white',
                                  width: '20px',
                                  height: '20px',
                                }}
                              />
                            </IconButton>
                          )}
                        </TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Paper
                          elevation={2}
                          style={{
                            padding: '6px 16px',
                            textAlign: 'center',
                          }}>
                          <Typography
                            variant="subtitle2"
                            style={{ fontWeight: 700 }}>
                            {goal.title.toUpperCase()}
                          </Typography>
                          <Divider />
                          <Typography
                            variant="subtitle2"
                            style={{ fontWeight: 500 }}>
                            {`STATUS: ${goal.status}`}
                          </Typography>
                          <Divider />
                          <Typography variant="subtitle2">
                            {goal.description}
                          </Typography>
                        </Paper>
                      </TimelineContent>
                    </TimelineItem>
                  );
                })}
            </Timeline>
          ) : (
            <Box style={{ textAlign: 'center' }}>
              <Typography
                style={{ marginBottom: '1rem', marginTop: '1rem' }}
                variant="h5">
                No Goals Assigned to this Project!
              </Typography>
              {isEdit && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setUpdatedGoal({
                      title: null,
                      startDate: null,
                      targetDate: null,
                      description: null,
                      status: 'TO DO',
                    });
                    setOpenGoalModal({ open: true, edit: false, data: null });
                  }}>
                  {' '}
                  Click here to add Goals{' '}
                </Button>
              )}
            </Box>
          )}
        </CardContent>
        <CardActions style={{ display: 'flex', justifyContent: 'center' }}>
          <Pagination
            count={goalSidebarPageCount}
            page={goalSidebarPage}
            onChange={handleGoalSidebarPagniation}
          />
        </CardActions>
      </Card>
      <Dialog open={openGoalModal.open} onClose={null} maxWidth="md">
        <DialogTitle>
          <Typography
            style={{ display: 'inline-block', marginTop: '1rem' }}
            variant="h4">
            {openGoalModal.edit ? 'Edit Project Goal' : 'Add New Project Goal'}
          </Typography>

          <IconButton
            style={{ float: 'right' }}
            onClick={() => {
              setOpenGoalModal({ open: false, edit: false, data: null });
              handleChangeGoalStartDate(null);
              handleChangeGoalTargetDate(null);
              setUpdatedGoal({
                title: null,
                startDate: null,
                targetDate: null,
                description: null,
                status: 'TO DO',
              });
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent style={{ padding: '15px' }}>
          <TextField
            style={{ marginBottom: '1.5rem' }}
            fullWidth
            label="Title"
            name="title"
            required
            variant="outlined"
            defaultValue={openGoalModal.edit ? openGoalModal.data.title : ''}
            onChange={handleGoalChange}
          />
          <TextField
            style={{ marginBottom: '1.5rem' }}
            fullWidth
            label="Status"
            name="status"
            required
            select
            variant="outlined"
            defaultValue={
              openGoalModal.edit
                ? openGoalModal.data.status
                : updatedGoal.status
            }
            onChange={handleGoalChange}>
            {statuses.map((stat, index) => (
              <MenuItem key={index} value={stat}>
                {stat}
              </MenuItem>
            ))}
          </TextField>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              style={{ marginBottom: '1.5rem' }}
              variant="dialog"
              required
              inputVariant="outlined"
              clearable
              fullWidth
              label="Start Date"
              name="startDate"
              format="MMM d, yyyy"
              defaultValue={
                openGoalModal.edit
                  ? moment(openGoalModal?.data?.startDate).utc()
                  : null
              }
              value={
                goalStartDate
                  ? moment(goalStartDate).utc()
                  : openGoalModal.edit
                  ? moment(openGoalModal?.data?.startDate).utc()
                  : null
              }
              onChange={goalStartDate => {
                handleGoalChange({
                  target: {
                    name: 'startDate',
                    value: goalStartDate.toLocaleDateString('en-US'),
                  },
                });
                handleChangeGoalStartDate(goalStartDate);
              }}
            />
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              style={{ marginBottom: '1.5rem' }}
              variant="dialog"
              required
              inputVariant="outlined"
              clearable
              fullWidth
              label="Target Date"
              name="targetDate"
              format="MMM d, yyyy"
              helperText={
                goalTargetDate < goalStartDate
                  ? 'Start date must be prior to target date'
                  : null
              }
              error={goalTargetDate < goalStartDate ? true : false}
              defaultValue={
                openGoalModal.edit
                  ? moment(openGoalModal?.data?.targetDate).utc()
                  : null
              }
              value={
                goalTargetDate
                  ? moment(goalTargetDate).utc()
                  : openGoalModal.edit
                  ? moment(openGoalModal?.data?.targetDate).utc()
                  : null
              }
              onChange={goalTargetDate => {
                handleGoalChange({
                  target: {
                    name: 'targetDate',
                    value: goalTargetDate.toLocaleDateString('en-US'),
                  },
                });
                handleChangeGoalTargetDate(goalTargetDate);
              }}
            />
          </MuiPickersUtilsProvider>
          <TextField
            style={{ marginBottom: '1.5rem' }}
            inputProps={{
              maxLength: 260,
            }}
            fullWidth
            multiline
            minRows={3}
            label="Description"
            name="description"
            required
            variant="outlined"
            defaultValue={
              openGoalModal.edit ? openGoalModal.data.description : ''
            }
            onChange={handleGoalChange}
          />
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            variant="contained"
            disabled={
              isEmpty(updatedGoal.title) ||
              isEmpty(updatedGoal.startDate) ||
              isEmpty(updatedGoal.targetDate) ||
              isEmpty(updatedGoal.description) ||
              isEmpty(updatedGoal.status)
            }
            color="primary"
            onClick={() => {
              handleGoalUpdate(openGoalModal.edit);
            }}>
            {openGoalModal.edit ? 'Edit Goal' : 'Add Goal'}
          </Button>
          {openGoalModal.edit && (
            <Button
              className={classes.red}
              variant="contained"
              onClick={() => {
                handleGoalDelete();
              }}>
              Delete Goal
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

EnhancedTimeline.propTypes = {
  goals: PropTypes.object,
  setGoals: PropTypes.func,
  isEdit: PropTypes.bool,
};

export default EnhancedTimeline;
