import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import {
  BudgetGoal,
  TotalPledges,
  TotalProjects,
  LatestPledges,
  LatestClusters,
  PledgesBudgetChart,
} from './components';
import { loadCookie } from 'common/cookies';
import { networksFetcher, pledgesFetcher } from 'common/fetchers';

const useStyles = makeStyles(() => ({
  root: {},
}));

const Dashboard = () => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = loadCookie();

  const { status, data } = useQuery(
    networksFetcher.keys.dashboardData,
    () => networksFetcher.getDashboardData(user?.networkId),
    { refetchOnWindowFocus: false }
  );
  const dashboardData = data?.data || [];
  const projectCount = dashboardData?.projectData?.length || 0;
  const isLoading = status === 'loading';

  const { data: networkPledgesData } = useQuery(
    pledgesFetcher.keys.fetchByNetwork,
    () => pledgesFetcher.findByNetwork(user?.networkId),
    { refetchOnWindowFocus: false }
  );
  const pledges = networkPledgesData?.data || [];

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item lg={4} sm={6} xl={4} xs={12}>
          <BudgetGoal
            isLoading={isLoading}
            budgetData={dashboardData?.budgets || []}
            clusterCount={dashboardData?.clusterCount || 0}
            clustersFunded={dashboardData?.clustersFundedCount || 0}
            budgetCapitalTypeBreakdown={
              dashboardData?.budgetCapitalTypeBreakdown || []
            }
            pledgeData={dashboardData?.pledges || []}
          />
        </Grid>
        <Grid item lg={4} sm={6} xl={4} xs={12}>
          <TotalPledges
            isLoading={isLoading}
            pledgeData={dashboardData?.pledges || []}
          />
        </Grid>
        <Grid item lg={4} sm={6} xl={4} xs={12}>
          <TotalProjects
            onClick={() => history.push('/projects')}
            style={{ cursor: 'pointer' }}
            projectCount={projectCount}
            isLoading={isLoading}
            projectFundingStatus={dashboardData?.projectData || []}
          />
        </Grid>
        <Grid item xs={12}>
          <PledgesBudgetChart
            pledgeData={dashboardData?.pledges || []}
            projectData={dashboardData?.projectData || []}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <LatestClusters />
        </Grid>
        <Grid item md={6} xs={12}>
          <LatestPledges
            subHeader={'Made to clusters in your Network'}
            pledges={pledges}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
