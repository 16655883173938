/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';

const usePasswordValidation = ({ firstPassword = '', secondPassword = '' }) => {
  const [validLength, setValidLength] = useState(null);
  const [hasNumber, setHasNumber] = useState(null);
  const [upperCase, setUpperCase] = useState(null);
  const [lowerCase, setLowerCase] = useState(null);
  const [specialChar, setSpecialChar] = useState(null);
  const [match, setMatch] = useState(null);

  useEffect(() => {
    // Regex pattern for checking if a string contains a number
    const numberPattern = new RegExp('\\d');
    const upperCasePattern = new RegExp('(?=.*[A-Z])');
    const lowerCasePattern = new RegExp('(?=.*[a-z])');
    const specialCharPattern = new RegExp('(?=.*[-+_!@#$%^&*., ?])');

    setValidLength(firstPassword.length >= 8 ? true : false);
    setHasNumber(numberPattern.test(firstPassword));
    setUpperCase(upperCasePattern.test(firstPassword));
    setLowerCase(lowerCasePattern.test(firstPassword));
    setSpecialChar(specialCharPattern.test(firstPassword));
    setMatch(firstPassword === secondPassword);
  }, [firstPassword, secondPassword]);
  return [validLength, hasNumber, upperCase, lowerCase, match, specialChar];
};

export default usePasswordValidation;
