import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/styles';
import SearchBar from 'material-ui-search-bar';

import {
  Grid,
  Table,
  Paper,
  Chip,
  Button,
  TableRow,
  TablePagination,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  LinearProgress,
  Tooltip,
  IconButton,
} from '@material-ui/core';

import { ChevronRight } from '@material-ui/icons';

import { loadCookie } from 'common/cookies';
import { Toolbar } from 'views/Clusters/components';
import {
  TableLoader,
  EmptyFallback,
  CapitalBreakdownModal,
  SearchFilter,
  MenuAction,
} from 'components';

const useStyles = makeStyles(theme => ({
  row: {
    textAlign: 'center',
  },
  progress: {
    maxWidth: 100,
    marginTop: theme.spacing(1),
  },
  capitalTypeBreakdown: {
    marginTop: theme.spacing(1),
  },
  profileCell: {
    '&:hover': {
      backgroundColor: '#f4f6f8',
    },
  },
  filterButton: {
    margin: theme.spacing(1),
  },
  filterContainer: {
    margin: theme.spacing(2),
    padding: theme.spacing(1),
  },
  activeFiltersContainer: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
  chipList: {
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  tableHead: {
    background: theme.palette.grey[200],
  },
}));

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.grey[50],
    },
  },
}))(TableRow);

const ClustersTable = props => {
  const classes = useStyles();
  const history = useHistory();
  const { clusters, isLoading } = props;

  const { user } = loadCookie();
  const isFunder = user?.isFundUser;
  const isNetworkUser = user?.isNetworkUser;
  const hasClusterRole = user?.hasClusterRole;
  const [rows, setRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [geography, setGeography] = useState([]);
  const [geographyFilter, setGeographyFilter] = useState([]);
  const [activeFilters, setActiveFilters] = useState([]);

  const getGeographyListFromClusters = clusters => {
    let geographiesWithDuplicates = clusters
      .map(project => {
        return project.geography?.trim().split('\n') || [];
      })
      .flat()
      .filter(geo => geo !== 'null');
    let uniqueGeographyArray = geographiesWithDuplicates.filter(
      (v, i, a) => a.indexOf(v) === i
    );
    return uniqueGeographyArray.map(geography => {
      return { name: geography, id: geography };
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    let mounted = false;

    if (!mounted && clusters) {
      setGeography(getGeographyListFromClusters(clusters));
      setRows(() =>
        clusters?.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        })
      );
    }
    return () => {
      mounted = true;
    };
  }, [clusters]);

  useEffect(() => {
    const getFilteredRows = () => {
      const filteredRows = clusters.filter(row => {
        let filteredByGeography =
          geographyFilter.length === 0
            ? true
            : geographyFilter
                .map(geography => geography.name)
                .some(geography => row.geography?.includes(geography));

        return filteredByGeography;
      });
      const getFilter = (filterList, filterType) => {
        return filterList.map(option => {
          return `${filterType}: ${option.name}`;
        });
      };

      setActiveFilters(getFilter(geographyFilter, 'Location'));
      return filteredRows;
    };
    const requestSearch = () => {
      const filteredRows = getFilteredRows().filter(row => {
        return row.name.toLowerCase().includes(searchQuery.toLowerCase());
      });
      setRows(filteredRows);
      setPage(0);
    };
    requestSearch();
  }, [searchQuery, geographyFilter, clusters]);

  const cancelSearch = () => {
    setSearchQuery('');
  };

  const resetFilters = () => {
    setGeographyFilter([]);
    setSearchQuery('');
  };

  const navigateToClusterBreakdown = clusterId => {
    const cluster = clusters.find(cluster => cluster.id === clusterId);

    history.push({
      state: { cluster },
      pathname: `/clusters/${clusterId}`,
    });
  };

  const navigateToUpdateCluster = clusterId => {
    const cluster = clusters.find(cluster => cluster.id === clusterId);

    history.push({
      state: { cluster },
      pathname: `/clusters/${cluster.id}/edit`,
    });
  };

  const navigateToProfile = clusterId => {
    history.push({
      pathname: `/profile/cluster/${clusterId}`,
    });
  };

  const handlePledgeClick = (event, isEvent = true, clusterId) => {
    if (isEvent) {
      event.stopPropagation();
      clusterId = event.target?.parentNode?.getAttribute('data-cluster-id');
    }

    if (clusterId !== null) {
      history.push({
        pathname: `/pledges/create/${clusterId}`,
      });
    }
  };

  if (!isLoading && isEmpty(clusters)) {
    return user.isAdminUser ? (
      <div className={classes.root}>
        <EmptyFallback action={null} heading="No clusters created yet." />
      </div>
    ) : (
      <div className={classes.root}>
        <EmptyFallback
          action={<Toolbar isNetworkUser={user?.isNetworkUser || false} />}
          heading="Create a cluster to get started!"
        />
      </div>
    );
  }

  return (
    <TableContainer>
      <Paper className={classes.filterContainer} elevation={2}>
        <SearchBar
          value={searchQuery}
          onChange={searchVal => setSearchQuery(searchVal)}
          onCancelSearch={() => cancelSearch()}
        />
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <SearchFilter
              label="Locations"
              onApplyFilter={filterBy => setGeographyFilter(filterBy)}
              options={geography}
              selectedFilters={geographyFilter}
              styles={classes.filterButton}
            />
          </Grid>

          <Grid item>
            <Button color="primary" variant="outlined" onClick={resetFilters}>
              Clear Filters
            </Button>
          </Grid>
        </Grid>
        {activeFilters.length !== 0 ? (
          <Paper
            elevation={0}
            variant="outlined"
            className={classes.activeFiltersContainer}>
            <Grid container alignItems="center">
              <Grid item xs={2}>
                <Typography variant="caption" style={{ marginRight: '1em' }}>
                  Active Filters:
                </Typography>
              </Grid>
              <Grid component="ul" item xs={10} className={classes.chipList}>
                <>
                  {activeFilters.map(data => (
                    <li key={data}>
                      <Chip
                        size="small"
                        variant="outlined"
                        color="primary"
                        className={classes.chip}
                        label={data}
                      />
                    </li>
                  ))}
                </>
              </Grid>
            </Grid>
          </Paper>
        ) : null}
      </Paper>
      <Paper
        style={{ overflow: 'hidden' }}
        className={classes.filterContainer}
        elevation={2}>
        <div style={{ overflow: 'auto' }}>
          <Table>
            <TableHead className={classes.tableHead}>
              <TableRow style={{ whiteSpace: 'nowrap' }}>
                <TableCell style={{ width: 'auto', fontWeight: 'bold' }}>
                  Name
                </TableCell>
                <TableCell style={{ width: 'auto', fontWeight: 'bold' }}>
                  Goal
                </TableCell>
                <TableCell style={{ width: 'auto', fontWeight: 'bold' }}>
                  Pledges
                </TableCell>
                <TableCell style={{ width: 'auto', fontWeight: 'bold' }}>
                  Approved Pledges
                </TableCell>
                {!isFunder && !isNetworkUser && !hasClusterRole && (
                  <TableCell style={{ width: '15rem', fontWeight: 'bold' }}>
                    View Cluster Breakdown
                  </TableCell>
                )}
                {(isFunder || isNetworkUser || hasClusterRole) && (
                  <TableCell style={{ width: 'auto', fontWeight: 'bold' }}>
                    Action
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading && <TableLoader colSpan={5} />}
              {!isLoading && isEmpty(clusters) ? (
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">No Cluster Found</Typography>
                  </TableCell>
                </TableRow>
              ) : null}
              {!isLoading && !isEmpty(rows) ? (
                (rowsPerPage > 0
                  ? rows?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : rows
                ).map(cluster => {
                  const clusterLength = cluster?.projects?.length;
                  const hasProjects = clusterLength > 0;

                  const totalClusterBudget = cluster?.projects
                    ?.map(({ capitalTypesProjects }) => capitalTypesProjects)
                    ?.reduce((acc, curr) => [...acc, ...curr], [])
                    ?.map(({ budgetGoal }) => Number(budgetGoal))
                    ?.reduce((acc, curr) => acc + curr, 0);

                  const totalRaisedAmount = cluster?.projects
                    ?.map(({ capitalTypesProjects }) => capitalTypesProjects)
                    ?.reduce((acc, curr) => [...acc, ...curr], [])
                    ?.map(({ budgetReached }) => Number(budgetReached))
                    ?.reduce((acc, curr) => acc + curr, 0);

                  let approvedPledges = 0;
                  let amountPledged = 0;
                  for (let i = 0; i < cluster?.pledges.length; i++) {
                    let { assigned, amount } = cluster?.pledges[i];
                    if (assigned) {
                      approvedPledges += Number(amount);
                    }
                    amountPledged += Number(amount);
                  }

                  const progress =
                    ((totalRaisedAmount + approvedPledges) /
                      totalClusterBudget) *
                      100 || 0;

                  // Capital Type Breakdown for cluster
                  const capitalTypesProjects = cluster?.projects
                    ?.map(({ capitalTypesProjects }) => capitalTypesProjects)
                    ?.reduce((acc, curr) => [...acc, ...curr], []);

                  const capitalTypeFunds = cluster?.projects
                    ?.map(({ funds }) => funds)
                    ?.reduce((acc, curr) => [...acc, ...curr], []);

                  const budgetCache = {};
                  const capitalTypeBreakdown = [];
                  capitalTypesProjects.forEach(
                    ({
                      capitalType: { id, name },
                      budgetReached,
                      budgetGoal,
                    }) => {
                      if (
                        !Object.prototype.hasOwnProperty.call(budgetCache, id)
                      ) {
                        capitalTypeBreakdown.push({
                          name,
                          budgetGoal: Number(budgetGoal),
                          budgetReached: Number(budgetReached),
                          totalCapitalTypeFunds: 0,
                        });
                        budgetCache[id] = capitalTypeBreakdown.length - 1;
                      } else {
                        const index = budgetCache[id];
                        capitalTypeBreakdown[index] = {
                          ...capitalTypeBreakdown[index],
                          budgetReached:
                            capitalTypeBreakdown[index]?.budgetReached +
                            Number(budgetReached),
                          budgetGoal:
                            capitalTypeBreakdown[index]?.budgetGoal +
                            Number(budgetGoal),
                        };
                      }
                    }
                  );

                  const fundsCache = {};
                  const fundsBreakdown = [];
                  capitalTypeFunds.forEach(
                    ({ pledge: { capitalTypeId }, amount }) => {
                      if (
                        !Object.prototype.hasOwnProperty.call(
                          fundsCache,
                          capitalTypeId
                        )
                      ) {
                        fundsBreakdown.push({
                          capitalTypeId,
                          amount: Number(amount),
                        });
                        fundsCache[capitalTypeId] =
                          capitalTypeBreakdown.length - 1;
                      } else {
                        const index = fundsCache[capitalTypeId];
                        fundsBreakdown[index] = {
                          ...fundsBreakdown[index],
                          amount:
                            fundsBreakdown[index]?.amount + Number(amount),
                        };
                      }
                    }
                  );

                  // Aggregate capitalTypeBreakdown
                  fundsBreakdown.forEach(({ capitalTypeId, amount }) => {
                    const index = budgetCache[capitalTypeId];
                    capitalTypeBreakdown[index] = {
                      ...capitalTypeBreakdown[index],
                      totalCapitalTypeFunds: amount || 0,
                    };
                  });

                  return (
                    <StyledTableRow
                      hover
                      className={classes.row}
                      key={cluster?.id}>
                      <TableCell>
                        <Button onClick={() => navigateToProfile(cluster?.id)}>
                          <Grid container direction="column">
                            <Grid item xs={12}>
                              <Typography variant="h6" align="left">
                                {cluster.name}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography align="left" variant="subtitle2">
                                {cluster?.projects?.length || 'No'}{' '}
                                {clusterLength > 1 ? 'projects' : 'project'}{' '}
                                assigned
                              </Typography>
                            </Grid>
                          </Grid>
                        </Button>
                      </TableCell>
                      <TableCell>
                        {new Intl.NumberFormat('USA', {
                          style: 'currency',
                          currency: 'USD',
                        }).format(totalClusterBudget)}
                        <Tooltip
                          title={`${new Intl.NumberFormat('USA', {
                            style: 'currency',
                            currency: 'USD',
                          }).format(
                            Number(totalRaisedAmount) + Number(approvedPledges)
                          )}`}
                          aria-label="progress">
                          <LinearProgress
                            className={classes.progress}
                            value={progress <= 100 ? progress : 100}
                            variant="determinate"
                          />
                        </Tooltip>
                        <div className={classes.capitalTypeBreakdown}>
                          <CapitalBreakdownModal
                            capitalTypeBreakdown={capitalTypeBreakdown}
                          />
                        </div>
                      </TableCell>
                      <TableCell>
                        {new Intl.NumberFormat('USA', {
                          style: 'currency',
                          currency: 'USD',
                        }).format(amountPledged)}
                      </TableCell>
                      <TableCell>
                        {new Intl.NumberFormat('USA', {
                          style: 'currency',
                          currency: 'USD',
                        }).format(approvedPledges)}
                      </TableCell>
                      {!isFunder && !isNetworkUser && !hasClusterRole && (
                        <TableCell>
                          <IconButton
                            id={cluster.id}
                            color="primary"
                            aria-label="View Cluster Breakdown"
                            onClick={() =>
                              navigateToClusterBreakdown(cluster?.id)
                            }>
                            <ChevronRight />
                          </IconButton>
                        </TableCell>
                      )}
                      {isFunder && !user?.hasClusterRole && (
                        <TableCell>
                          <Button
                            id="pledge-button"
                            color="secondary"
                            variant="contained"
                            disabled={!hasProjects}
                            data-cluster-id={cluster.id}
                            onClick={handlePledgeClick}>
                            Pledge
                          </Button>
                        </TableCell>
                      )}
                      {(isNetworkUser || user?.hasClusterRole) && (
                        <TableCell>
                          <MenuAction
                            items={[
                              isFunder
                                ? {
                                    itemName: 'Pledge',
                                    itemActionEvent: () =>
                                      handlePledgeClick(
                                        null,
                                        false,
                                        cluster.id
                                      ),
                                  }
                                : {},
                              hasClusterRole ||
                              cluster.networkId === user?.networkId
                                ? {
                                    itemName: 'Edit',
                                    itemActionEvent: () =>
                                      navigateToUpdateCluster(cluster?.id),
                                  }
                                : {},
                              {
                                itemName: 'View Cluster Breakdown',
                                itemActionEvent: () =>
                                  navigateToClusterBreakdown(cluster?.id),
                              },
                            ]}
                          />
                        </TableCell>
                      )}
                    </StyledTableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      No Cluster Found for active filters
                    </Typography>
                  </TableCell>
                </TableRow>
              )}

              <TableRow>
                <TablePagination
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  rowsPerPageOptions={[10]}
                />
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </Paper>
    </TableContainer>
  );
};

ClustersTable.propTypes = {
  className: PropTypes.string,
  error: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  clusters: PropTypes.array.isRequired,
};

export default ClustersTable;
