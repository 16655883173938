import React, { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    height: 'auto',
    width: 'auto',
  },
  dialog: {
    height: '485px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  space: {
    height: '50%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '70%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  shareableLink: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    borderRadius: '5px',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F3F3F4',
  },
  linkInput: {
    width: '80%',
    paddingLeft: theme.spacing(1),
    border: 'none',
    backgroundColor: '#F3F3F4',
    fontFamily: 'roboto',
    color: '#A5A5B7',
  },
  toon: {
    position: 'fixed',
    height: '275px',
    width: '435px',
    top: '25%',
    left: '53%',
    marginTop: '-137.5px',
    marginLeft: '-267.5px',
  },
}));
const ShareProfileModal = props => {
  const classes = useStyles();
  const { open, handleClose, profileLink } = props;

  const [copied, setCopied] = useState(false);
  const link = useRef();
  const copyToClipboard = () => {
    link.current.select();
    document.execCommand('copy');
    setCopied(true);
  };
  return (
    <Dialog
      className={classes.root}
      open={open}
      onClose={() => {
        handleClose();
        setCopied(false);
      }}>
      <img
        className={classes.toon}
        src="/images/profile_toon.svg"
        alt="profile card"
      />

      <DialogContent className={classes.dialog}>
        <div className={classes.space} />
        <div className={classes.content}>
          <DialogContentText
            style={{
              fontSize: '24px',
              fontWeight: '700',
              textAlign: 'center',
              color: '#000000',
            }}>
            Share this with your social Community
          </DialogContentText>
          <div className={classes.shareableLink}>
            <input
              ref={link}
              className={classes.linkInput}
              type="text"
              value={profileLink}
            />
            <Button
              onClick={copyToClipboard}
              color={copied ? 'secondary' : 'primary'}>
              {copied ? 'Copied' : 'Copy'}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

ShareProfileModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  profileLink: PropTypes.string,
};
export default ShareProfileModal;
