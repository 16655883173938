import { useState, useEffect } from 'react';

const useVisibilityTimeout = (timerValue = 200) => {
  const [isShowing, setVisibility] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisibility(true);
    }, timerValue);
    return () => clearTimeout(timer);
  }, [timerValue]);

  return isShowing;
};

export default useVisibilityTimeout;
