import React from 'react';
import { RouteWithLayout } from 'components';
import { Minimal as MinimalLayout } from 'layouts';
import { Switch, Redirect } from 'react-router-dom';

import SignInPage from './SignIn';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const SignInRoutes = () => {
  return (
    <Switch>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
      >
        <RouteWithLayout
          component={SignInPage}
          exact
          isPrivate={false}
          layout={MinimalLayout}
          path="/sign-in"
        />
      </GoogleReCaptchaProvider>
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default SignInRoutes;
