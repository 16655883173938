import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import { useQuery, useMutation } from 'react-query';

import {
  Grid,
  Button,
  Divider,
  TextField,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
} from '@material-ui/core';

import { orgTypesFetcher, organizationsFetcher } from 'common/fetchers';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(8),
  },
  content: {
    maxWidth: 700,
    marginBottom: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
}));

const AddUpdateOrganization = props => {
  const classes = useStyles();
  const [error, setError] = useState('');
  const [createOrganization, { status: createStatus }] = useMutation(
    organizationsFetcher.save
  );
  const [updateOrganization, { status: updateStatus }] = useMutation(
    organizationsFetcher.update
  );
  const [formState, setFormState] = useState({});
  const [formSubmitting, setFormSubmitting] = useState(false);

  const { data: organizationTypesData } = useQuery(
    orgTypesFetcher.keys.fetch,
    orgTypesFetcher.all,
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );

  const organization = props?.organization;
  useEffect(() => {
    if (organization !== null) {
      setFormState({
        orgName: organization?.orgName || '',
        website: organization?.website || '',
        contactName: organization?.orgProfile?.pointOfContact || '',
        email: organization?.orgProfile?.contactEmail || '',
        type: organization?.orgType?.id || 0,
      });
    }
  }, [organization]);

  const organizationTypes = organizationTypesData?.data || [];
  useEffect(() => {
    if (organizationTypes.length && !organization) {
      setFormState(formState => ({
        ...formState,
        type: organizationTypes[0].id,
      }));
    }
  }, [organizationTypes, organization]);

  const clearForm = () => {
    setFormState(formState => ({
      ...formState,
      orgName: '',
      website: '',
      contactName: '',
      email: '',
      type: organizationTypes[0].id,
    }));
  };

  const closeModal = () => {
    clearForm();
    props.close(false);
    setFormSubmitting(false);
    props.selectedOrg(null);
  };

  const handleChange = event => {
    event.persist();

    setError('');
    setFormState(formState => ({
      ...formState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = async event => {
    event.preventDefault();
    setFormSubmitting(true);

    formState.orgProfile = {
      pointOfContact: formState.contactName,
      contactEmail: formState.email,
    };

    if (!organization) {
      await createOrganization(formState, {
        onError: error => {
          setError(error?.response?.data?.message);
        },
        onSuccess: async () => {
          await props.refresh();
          props.setSuccessOpen(true);
          props.close(false);
          clearForm();
        },
      });
    } else {
      // Handle updating organization information
      formState.orgProfileId = organization?.orgProfileId;
      await updateOrganization(
        { id: organization.id, ...formState },
        {
          onError: error => {
            setError(error?.response?.data?.message);
          },
          onSuccess: async () => {
            await props.refresh();
            props.selectedOrg(null);
            props.setSuccessOpen(true);
            props.close(false);
            clearForm();
          },
        }
      );
    }

    setFormSubmitting(false);
  };

  return (
    <Dialog open={props.open} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        {organization ? 'Update Organization' : 'Add new organization'}
      </DialogTitle>
      <DialogContent>
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            {error && (
              <Grid item xs={12}>
                <Alert severity="error" variant="outlined">
                  {error}
                </Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Name"
                margin="dense"
                name="orgName"
                required
                variant="outlined"
                onChange={handleChange}
                value={formState.orgName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Website"
                margin="dense"
                name="website"
                variant="outlined"
                onChange={handleChange}
                value={formState.website}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                SelectProps={{ native: true }}
                fullWidth
                label="Organization Type"
                margin="dense"
                name="type"
                required
                select
                variant="outlined"
                onChange={handleChange}
                value={formState.type}>
                {organizationTypes.map(orgType => (
                  <option key={orgType.id} value={orgType.id}>
                    {orgType.name}
                  </option>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Contact Name"
                margin="dense"
                name="contactName"
                required
                variant="outlined"
                onChange={handleChange}
                value={formState.contactName}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                type="email"
                fullWidth
                label="Email"
                margin="dense"
                name="email"
                required
                variant="outlined"
                onChange={handleChange}
                value={formState.email}
              />
            </Grid>
          </Grid>
          <Divider />
          <CardActions className={classes.actions}>
            <Button onClick={closeModal} color="primary">
              Close
            </Button>
            <Button
              color="primary"
              type="submit"
              variant="contained"
              disabled={
                isEmpty(formState.contactName) ||
                createStatus === 'loading' ||
                updateStatus === 'loading' ||
                isEmpty(formState.email) ||
                isEmpty(formState.orgName) ||
                isEmpty(formState.type)
              }>
              {formSubmitting ? (
                <CircularProgress size={32} />
              ) : organization ? (
                'Update'
              ) : (
                'Create'
              )}
            </Button>
          </CardActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

AddUpdateOrganization.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  setSuccessOpen: PropTypes.func,
  selectedOrg: PropTypes.func,
  refresh: PropTypes.func,
  organization: PropTypes.object,
};

export default AddUpdateOrganization;
