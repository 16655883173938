import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { formatDistance } from 'date-fns';
import { makeStyles, withStyles } from '@material-ui/styles';
import SearchBar from 'material-ui-search-bar';

import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TablePagination,
  TableContainer,
  TableHead,
  Paper,
  Typography,
  Dialog,
  DialogTitle,
  Button,
  IconButton,
  Tooltip,
  DialogContent,
  DialogContentText,
  DialogActions,
  Divider,
  CircularProgress,
} from '@material-ui/core';

import { Help } from '@material-ui/icons';

import {
  TableLoader,
  EmptyFallback,
  MenuAction,
  PledgeAssignmentConfirmation,
  ConfirmationModal,
} from 'components';

const useStyles = makeStyles(theme => ({
  pledgeInstruction: {
    backgroundColor: 'F4F6F8',
    marginTop: theme.spacing(2),
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'left',
    flexDirection: 'column',
  },
  pledgeInstructionText: {
    marginTop: theme.spacing(2),
    textAlign: 'left',
    marginRight: 'auto',
    color: '#626060',
    fontStyle: 'italic',
    fontWeight: 'bold',
  },
  instructionContent: {
    backgroundColor: '#F4F6F8',
    width: '80%',
    margin: '1.6rem',
    height: 'auto',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'flex-start',
    alignSelf: 'center',
  },
  row: {
    textAlign: 'center',
  },
  instruction: {
    color: '#3F51B5',
    fontWeight: '50',
    fontSize: '16px',
    padding: '0.3rem',
  },
  tableHead: {
    background: theme.palette.grey[200],
  },
  filterContainer: {
    margin: theme.spacing(2),
    padding: theme.spacing(1),
  },
}));

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.grey[50],
    },
  },
}))(TableRow);

const PledgesTable = props => {
  const classes = useStyles();
  const { pledges, isLoading, isAdmin, handlePledgeUndo } = props;
  const [assignPledgeModalOpen, setAssignPledgeModalOpen] = useState(false);
  const [pledgeInstructionModal, setPledgeInstructionModal] = useState(false);
  const [undoPledgeModal, setUndoPledgeModal] = useState(false);
  const [rows, setRows] = useState([]);
  const [selectedPledge, setSelectedPledge] = useState(null);
  const [searched, setSearched] = useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    let mounted = false;

    if (!mounted && pledges) {
      setRows(() =>
        pledges.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        })
      );
    }
    return () => {
      mounted = true;
    };
  }, [pledges]);

  const requestSearch = searchedVal => {
    const filteredRows = pledges.filter(row => {
      return (
        row.cluster?.name.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row.funder?.orgName.toLowerCase().includes(searchedVal.toLowerCase())
      );
    });
    setRows(filteredRows);
    setPage(0);
  };
  const cancelSearch = () => {
    setSearched('');
    requestSearch(searched);
  };

  const [
    pledgeClusterAssignConfirmation,
    setClusterPledgeAssignConfirmation,
  ] = useState(false);
  const history = useHistory();

  if (!isLoading && isEmpty(pledges)) {
    return <EmptyFallback heading="No pledges given yet." />;
  }

  const handleRequest = async (event, pledgeId, edit) => {
    const request = props?.handleRequest;
    const response = await request(event, pledgeId, edit);

    if (response) setClusterPledgeAssignConfirmation(() => true);
    else setAssignPledgeModalOpen(() => false);
  };

  const handleConfirm = async event => {
    const request = props?.handleConfirm;
    await request(event, selectedPledge.id, selectedPledge?.assigned);
    setClusterPledgeAssignConfirmation(() => false);
    setAssignPledgeModalOpen(() => false);
  };

  return (
    <TableContainer>
      <Paper className={classes.filterContainer} elevation={2}>
        <SearchBar
          value={searched}
          onChange={searchVal => requestSearch(searchVal)}
          onCancelSearch={() => cancelSearch()}
        />
      </Paper>

      <Paper
        style={{ overflow: 'hidden' }}
        className={classes.filterContainer}
        elevation={2}>
        <div style={{ overflow: 'auto' }}>
          <Table>
            <TableHead className={classes.tableHead}>
              <TableRow style={{ whiteSpace: 'nowrap' }}>
                <TableCell style={{ width: '15rem', fontWeight: 'bold' }}>
                  Cluster
                </TableCell>
                <TableCell style={{ width: '12rem', fontWeight: 'bold' }}>
                  Organization
                </TableCell>
                <TableCell style={{ width: '12rem', fontWeight: 'bold' }}>
                  Funder
                </TableCell>
                <TableCell style={{ width: '12rem', fontWeight: 'bold' }}>
                  Amount
                </TableCell>
                <TableCell style={{ width: '12rem', fontWeight: 'bold' }}>
                  Capital Type
                </TableCell>
                <TableCell style={{ width: '12rem', fontWeight: 'bold' }}>
                  Assigned
                </TableCell>
                <TableCell style={{ width: '12rem', fontWeight: 'bold' }}>
                  Assigned To
                </TableCell>
                <TableCell style={{ width: '12rem', fontWeight: 'bold' }}>
                  Instructions
                </TableCell>
                {isAdmin && (
                  <TableCell style={{ width: 'auto', fontWeight: 'bold' }}>
                    Actions
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading && <TableLoader colSpan={5} />}
              {!isLoading && isEmpty(pledges) ? (
                <StyledTableRow>
                  <TableCell>
                    <Typography variant="h6">No Pledges Found</Typography>
                  </TableCell>
                </StyledTableRow>
              ) : null}
              {!isLoading &&
                (rowsPerPage > 0
                  ? rows?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : rows
                ).map((pledge, index) => (
                  <StyledTableRow height={80} hover key={pledge?.id || index}>
                    <TableCell
                      style={{ color: pledge?.assigned ? '#9298b1' : 'black' }}>
                      {pledge.cluster?.name}
                      <Typography
                        style={{
                          color: pledge?.assigned ? '#9298b1' : 'black',
                        }}
                        variant="subtitle2">
                        Made{' '}
                        {formatDistance(
                          new Date(pledge?.createdAt),
                          new Date(),
                          {
                            addSuffix: true,
                          }
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{ color: pledge?.assigned ? '#9298b1' : 'black' }}>
                      {pledge.funder?.orgName ?? pledge.guest?.organizationName}
                    </TableCell>
                    <TableCell
                      style={{ color: pledge?.assigned ? '#9298b1' : 'black' }}>
                      {pledge.guest == null
                        ? `${pledge.user?.firstName} ${pledge.user?.lastName}`
                        : `Guest - ${pledge.guest?.firstName} ${pledge.guest?.lastName}`}
                    </TableCell>
                    <TableCell
                      style={{ color: pledge?.assigned ? '#9298b1' : 'black' }}>
                      $
                      {new Intl.NumberFormat('USA', {
                        style: 'decimal',
                      }).format(pledge.amount)}
                    </TableCell>
                    <TableCell
                      style={{ color: pledge?.assigned ? '#9298b1' : 'black' }}>
                      {pledge.capitalType?.name.toUpperCase()}
                    </TableCell>
                    <TableCell
                      style={{ color: pledge?.assigned ? '#9298b1' : 'black' }}>
                      {pledge.assigned ? 'Yes' : 'No'}
                    </TableCell>
                    <TableCell
                      style={{ color: pledge?.assigned ? '#9298b1' : 'black' }}>
                      {pledge?.assigned_to || 'N/A'}
                    </TableCell>
                    <TableCell
                      style={{ color: pledge?.assigned ? '#9298b1' : 'black' }}>
                      {
                        <Tooltip
                          title="No Instructions Were Given"
                          disableHoverListener={
                            pledge?.pledgeInstruction === null ? false : true
                          }>
                          <span>
                            <IconButton
                              disabled={
                                pledge.pledgeInstruction === null ? true : false
                              }
                              color="primary"
                              aria-label="Pledge Instructions"
                              onClick={() => {
                                setSelectedPledge(() => pledge);

                                setPledgeInstructionModal(() => true);
                              }}>
                              <Help
                                style={{
                                  color:
                                    pledge.pledgeInstruction === null
                                      ? '#B5B3B3'
                                      : '#FF1D89',
                                }}
                              />
                            </IconButton>
                          </span>
                        </Tooltip>
                      }
                    </TableCell>
                    {isAdmin && (
                      <TableCell
                        style={{
                          color: pledge?.assigned ? '#9298b1' : '#263263',
                        }}>
                        <MenuAction
                          items={
                            pledge?.assigned
                              ? [
                                  // {
                                  //   itemName: 'Edit Pledge Allocations',
                                  //   itemActionEvent: () => {
                                  //     setAssignPledgeModalOpen(true);
                                  //     setSelectedPledge(pledge);
                                  //   },
                                  // },
                                  {
                                    itemName: 'Undo Pledge Allocations',
                                    itemActionEvent: () => {
                                      setSelectedPledge(pledge);
                                      setUndoPledgeModal(true);
                                    },
                                  },
                                ]
                              : [
                                  {
                                    itemName: 'Assign Pledge',
                                    itemActionEvent: () => {
                                      setAssignPledgeModalOpen(true);
                                      setSelectedPledge(pledge);
                                    },
                                  },
                                ]
                          }
                        />
                      </TableCell>
                    )}
                  </StyledTableRow>
                ))}
              <tr>
                <TablePagination
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  rowsperpageoptions={[10]}
                />
              </tr>
            </TableBody>
            {isAdmin && (
              <Dialog
                open={assignPledgeModalOpen}
                aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Assign Pledge</DialogTitle>
                <DialogContent
                  style={{ minWidth: '400px', textAlign: 'center' }}>
                  {!pledgeClusterAssignConfirmation ? null : (
                    <PledgeAssignmentConfirmation
                      props={{
                        pledgeAssignConfirmation: pledgeClusterAssignConfirmation,
                        setPledgeAssignConfirmation: setClusterPledgeAssignConfirmation,
                        pledge: selectedPledge,
                        assignFundsStatus: props?.assignFundsStatus,
                        requestedAllocations: props?.requestedAllocations,
                        handleConfirm,
                      }}
                    />
                  )}
                  <Tooltip
                    placement="top"
                    title="Assigns the funds equally to all projects in the cluster"
                    aria-label="assign-to-cluster">
                    <Button
                      onClick={event =>
                        handleRequest(
                          event,
                          selectedPledge.id,
                          selectedPledge.assigned
                        )
                      }
                      style={{ marginBottom: '20px' }}
                      variant="contained"
                      color="primary"
                      disabled={
                        props?.requestCapitalAllocationStatus === 'loading'
                      }>
                      {props?.requestCapitalAllocationStatus === 'loading' ? (
                        <CircularProgress size={32} />
                      ) : (
                        'Assign To Cluster'
                      )}
                    </Button>
                  </Tooltip>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}>
                    <Divider style={{ width: '40%' }} />{' '}
                    <Typography
                      variant="subtitle2"
                      style={{ display: 'inline' }}>
                      OR
                    </Typography>
                    <Divider style={{ width: '40%' }} />
                  </div>
                  <Tooltip
                    title="Assign the funds to projects manually"
                    aria-label="assign-to-projects">
                    <Button
                      style={{ marginTop: '20px' }}
                      variant="contained"
                      onClick={() => {
                        history.push({
                          state: {
                            edit: selectedPledge?.assigned,
                          },
                          pathname: `/pledges/assign/${selectedPledge.id}`,
                        });
                      }}>
                      Assign To Projects
                    </Button>
                  </Tooltip>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      setAssignPledgeModalOpen(false);
                      setSelectedPledge(null);
                    }}
                    color="default">
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            )}
            <ConfirmationModal
              openConfirmationModal={undoPledgeModal}
              setOpenConfirmationModal={setUndoPledgeModal}
              modalTitle={'Confirmation'}
              modalContent={
                <>
                  Undoing this pledge will result in the removal of all funds
                  associated with this pledge.
                </>
              }
              handleConfirm={() => {
                handlePledgeUndo(selectedPledge?.id);
                setUndoPledgeModal(false);
              }}
            />
            <Dialog
              open={pledgeInstructionModal}
              fullWidth
              onClose={() => {
                setPledgeInstructionModal(() => false);
              }}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description">
              <DialogTitle>Pledge Instructions</DialogTitle>
              <DialogContent className={classes.instructionContent}>
                <DialogContentText className={classes.instruction}>
                  `{selectedPledge?.pledgeInstruction}` <br />
                  <br />
                  <b style={{ textAlign: 'left', justifySelf: 'flex-start' }}>
                    {' '}
                    - {selectedPledge?.funder?.orgName}
                  </b>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setPledgeInstructionModal(false);
                    setSelectedPledge(null);
                  }}
                  color="default">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </Table>
        </div>
      </Paper>
    </TableContainer>
  );
};

PledgesTable.propTypes = {
  error: PropTypes.object,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  pledges: PropTypes.array.isRequired,
  isAdmin: PropTypes.bool,
  handlePledgeUndo: PropTypes.func,
  handleRequest: PropTypes.func,
  handleConfirm: PropTypes.func,
  assignFundsStatus: PropTypes.string,
  requestedAllocations: PropTypes.object,
  requestCapitalAllocationStatus: PropTypes.string,
};

PledgesTable.defaultProps = {
  isAdmin: false,
};

export default PledgesTable;
