import React from 'react';
import { useQuery } from 'react-query';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { formatDistance } from 'date-fns';
import {
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  LinearProgress,
  Typography,
  Tooltip,
} from '@material-ui/core';
import { clustersFetcher } from 'common/fetchers';

import { EmptyFallback } from 'components';
import { loadCookie } from 'common/cookies';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
    minWidth: 400,
  },

  row: {
    cursor: 'pointer',
  },
  actions: {
    justifyContent: 'flex-end',
  },
  progress: {
    maxWidth: 80,
    marginTop: theme.spacing(1),
  },
}));

const LatestClusters = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const history = useHistory();

  const { user } = loadCookie();
  const isFunder = user?.isFundUser;
  const isNetwork = user?.isNetworkUser;

  const { data } = useQuery(
    clustersFetcher.keys.fetch,
    clustersFetcher.findAllByUser
  );

  let clusters;
  if (isNetwork) {
    clusters =
      data?.data?.clusters.filter(
        ({ networkId }) => networkId === user?.networkId
      ) || [];
  } else {
    clusters = data?.data.clusters || [];
  }

  const handleNavigationToClusters = event => {
    event.preventDefault();
    history.push('/clusters');
  };

  const handleNavigateToAddClusters = event => {
    event.preventDefault();
    history.push('/clusters/create');
  };

  const handleRowClick = event => {
    const clusterId = event.target?.parentNode?.id;
    const cluster = clusters.find(cluster => cluster._id === clusterId);

    history.push({
      state: { cluster },
      pathname: `/clusters/${clusterId}`,
    });
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        action={
          !isFunder && (
            <Button
              color="primary"
              size="small"
              variant="outlined"
              onClick={handleNavigateToAddClusters}
            >
              Add New
            </Button>
          )
        }
        title="Latest Clusters"
        subheader="Created in the system"
      />
      <Divider />
      {clusters?.length === 0 ? (
        <EmptyFallback heading="No clusters created yet." />
      ) : (
        <>
          <CardContent className={classes.content}>
            <PerfectScrollbar>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Area of work</TableCell>
                    <TableCell>Goal</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {clusters.slice(0, 3).map((cluster, index) => {
                    const clusterLength = cluster?.projects?.length;
                    const hasProjects = clusterLength > 0;
                    const approvedPledges = cluster?.pledges?.reduce(
                      (prev, curr) =>
                        curr?.assigned === true
                          ? (prev += Number(curr?.amount))
                          : prev,
                      0
                    );
                    const totalClusterBudget = hasProjects
                      ? cluster?.projects
                        ?.map(
                          ({ capitalTypesProjects }) => capitalTypesProjects
                        )
                        ?.reduce((acc, curr) => [...acc, ...curr], [])
                        ?.map(({ budgetGoal }) => Number(budgetGoal))
                        ?.reduce((acc, curr) => acc + curr, 0)
                      : 0;

                    const totalRaisedAmount = hasProjects
                      ? cluster?.projects
                        ?.map(
                          ({ capitalTypesProjects }) => capitalTypesProjects
                        )
                        ?.reduce((acc, curr) => [...acc, ...curr], [])
                        ?.map(({ budgetReached }) => Number(budgetReached))
                        ?.reduce((acc, curr) => acc + curr, 0)
                      : 0;

                    const progress =
                      totalClusterBudget > 0
                        ? ((Number(totalRaisedAmount) +
                            Number(approvedPledges)) /
                            Number(totalClusterBudget)) *
                          100
                        : 0;

                    return (
                      <TableRow
                        className={classes.row}
                        hover
                        id={cluster._id}
                        key={cluster?._id || index}
                        onClick={handleRowClick}
                      >
                        <TableCell>
                          {cluster.name}
                          <Typography variant="subtitle2">
                            Made{' '}
                            {formatDistance(
                              new Date(cluster?.createdAt),
                              new Date(),
                              {
                                addSuffix: true,
                              }
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell>{cluster?.primaryArea?.name}</TableCell>
                        <TableCell>
                          {new Intl.NumberFormat('USA', {
                            style: 'currency',
                            currency: 'USD',
                          }).format(totalClusterBudget)}
                          <Tooltip
                            title={`${new Intl.NumberFormat('USA', {
                              style: 'currency',
                              currency: 'USD',
                            }).format(
                              Number(totalRaisedAmount) +
                                Number(approvedPledges)
                            )}`}
                            aria-label="progress"
                          >
                            <LinearProgress
                              className={classes.progress}
                              value={progress >= 100 ? 100 : progress}
                              variant="determinate"
                            />
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </PerfectScrollbar>
          </CardContent>
          <Divider />
          <CardActions className={classes.actions}>
            <Button
              color="primary"
              onClick={handleNavigationToClusters}
              size="small"
              variant="text"
            >
              View all <ArrowRightIcon />
            </Button>
          </CardActions>
        </>
      )}
    </Card>
  );
};

LatestClusters.propTypes = {
  className: PropTypes.string,
};

export default LatestClusters;
