import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import { PledgeCapitalTypeBreakdownModal } from 'components';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
  content: {
    alignItems: 'center',
    display: 'flex',
  },
  title: {
    fontWeight: 700,
  },
  avatar: {
    backgroundColor: theme.palette.pink,
    height: 56,
    width: 56,
  },
  icon: {
    height: 32,
    width: 32,
  },
  pledges: {
    marginTop: '10%',
    marginBottom: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifySelf: 'flex-end',
    justifyContent: 'space-between',
  },
  pledgesValue: {
    fontWeight: 500,
  },
}));

const TotalPledges = props => {
  const { className, isFunder, pledgeData, ...rest } = props;

  const classes = useStyles();
  const pledgeDataList = [];

  const cache = {};
  pledgeData.forEach(({ name, amount }) => {
    if (!Object.prototype.hasOwnProperty.call(cache, name)) {
      pledgeDataList.push({ label: name, value: Number(amount) });
      cache[name] = pledgeDataList.length - 1;
    } else {
      const index = cache[name];
      pledgeDataList[index] = {
        ...pledgeDataList[index],
        value: pledgeDataList[index]?.value + Number(amount),
      };
    }
  });

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <Grid container justify="space-between">
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              id={
                isFunder
                  ? 'total-pledges-funders-title'
                  : 'total-pledges-clusters-title'
              }
              variant="body2"
            >
              {isFunder
                ? 'TOTAL PLEDGES MADE BY ALL FUNDERS'
                : 'TOTAL PLEDGES FOR MY CLUSTERS'}
            </Typography>
            <Typography
              id={
                isFunder
                  ? 'total-pledges-funders-value'
                  : 'total-pledges-clusters-value'
              }
              variant="h3"
            >
              ${' '}
              {new Intl.NumberFormat().format(
                pledgeData?.reduce(
                  (prev, curr) => (prev += Number(curr.amount)),
                  0
                ) || 0
              )}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <LoyaltyIcon className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>
        <div className={classes.pledges}>
          <Typography variant="body2">
            <span className={classes.pledgesValue}>
              {pledgeData?.length || 0}
            </span>{' '}
            {pledgeData?.length === 1 ? 'pledge ' : 'pledges '}
            {isFunder ? 'made' : 'received'}
          </Typography>
          <PledgeCapitalTypeBreakdownModal
            pledgeCapitalTypeBreakdown={pledgeDataList}
          />
        </div>
      </CardContent>
    </Card>
  );
};

TotalPledges.propTypes = {
  className: PropTypes.string,
  isFunder: PropTypes.bool,
  pledgeData: PropTypes.array.isRequired,
};

TotalPledges.defaultProps = {
  isFunder: false,
};

export default TotalPledges;
