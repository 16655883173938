import { Roles } from 'api';

// Keys are used to allow us to assign the same key
// for the same type of request so data can be cached
// with react-query
export const keys = {
  fetch: 'FETCH_ROLES',
};

export const allFlexibleRoles = () => {
  return Roles.find('flexibleRoles');
};

export const update = ({ userId, roles }) => {
  return Roles.update(userId, { roles });
};
