import { Users } from 'api';

// Keys are used to allow us to assign the same key
// for the same type of request so data can be cached
// with react-query
export const keys = {
  fetch: 'FETCH_USERS',
};

export const all = () => {
  return Users.all();
};

export const save = user => {
  return Users.post(user);
};

export const findOne = email => {
  return Users.find(email);
};

export const update = user => {
  return Users.update(user.id, user);
};

export const remove = id => {
  return Users.remove(id);
};

export const activate = id => {
  return Users.update(id, { active: true });
};

export const deactivate = id => {
  return Users.update(id, { active: false });
};
