import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { usePasswordValidation } from 'common/hooks';
import {
  Grid,
  Button,
  TextField,
  Snackbar,
  Typography,
  FormHelperText
} from '@material-ui/core';

//import { loadCookie } from 'common/cookies';
import { useMutation } from 'react-query';
import { resetPasswordWithTokenFetcher } from './fetchers';

const schema = {
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128,
    },
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
  },
  content: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(15),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(5),
      paddingTop: theme.spacing(15),
    },
  },
  description: {
    marginTop: theme.spacing(2),
  },
  contentBody: {
    display: 'flex',
    flexBasis: 500,
    flexDirection: 'column',
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },
}));

const UpdatePasswordPage = props => {
  const { history } = props;
  const classes = useStyles();
  const [error, setError] = useState('');
  const [resetPasswordWithToken, { status }] = useMutation(
    resetPasswordWithTokenFetcher
  );
  //const [successOpen, setSuccessOpen] = useState(false);
  const token = new URLSearchParams(window.location.search).get('token');

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      newPassword: '',
      confirmPassword: '',
    },
    touched: {},
    errors: {},
  });

  const [
    validLength,
    hasNumber,
    upperCase,
    lowerCase,
    match,
    specialChar,
  ] = usePasswordValidation({
    firstPassword: formState.values.newPassword,
    secondPassword: formState.values.confirmPassword,
  });

  useEffect(() => {
    const errors = validate(formState.values, {
      newPassword: schema.password,
      confirmPassword: schema.password,
    });

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleReset = async event => {
    event.preventDefault();
    await resetPasswordWithToken(
      {
        token,
        passwords: {
          newPassword: formState.values.newPassword,
          confirmPassword: formState.values.confirmPassword,
        },
      },
      {
        onError: error => {
          const updatePassword = error?.response?.data;

          // Redirects all errors except for when newPassword and
          // confirmPassword do not match
          if (updatePassword.message !== 'Passwords do not match')
            history.push({ state: { updatePassword }, pathname: '/sign-in' });

          setError(updatePassword.message);
          setFormState(() => ({
            isValid: true,
            errors: {},
            touched: {},
            values: {
              newPassword: '',
              confirmPassword: '',
            },
          }));
        },
        onSuccess: ({ data: updatePassword }) => {
          history.push({ state: { updatePassword }, pathname: '/sign-in' });
        },
      }
    );
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const passwordValid =
    match && hasNumber && lowerCase && upperCase && specialChar & validLength;

  const checkPassword = () => {
    const error = [];

    !validLength && error.push('Password should contain at least 8 characters');
    !upperCase && error.push('Password should contain an uppercase character');
    !lowerCase && error.push('Password should contain a lowercase character');
    !hasNumber && error.push('Password should contain a digit');
    !specialChar && error.push('Password should contain a special character -+_!@#$%^&*., ?;');

    return error;
  }

  return (
    <div className={classes.root}>
      <Snackbar open={error !== ''} autoHideDuration={6000}>
        <Alert severity="error" variant="filled">
          {error}
        </Alert>
      </Snackbar>
      <Grid className={classes.content} item xs={12}>
        <div className={classes.contentBody}>
          <form className={classes.form} onSubmit={handleReset}>
            <Typography variant="h2">Create new password</Typography>
            <Typography
              className={classes.description}
              color="textSecondary"
              gutterBottom
            >
              It is suggested that your new password be different from previous
              passwords.
            </Typography>

            <TextField
              className={classes.textField}
              error={hasError('newPassword')}
              fullWidth
              helperText={
                hasError('newPassword') ? formState.errors.newPassword[0] : null
              }
              label="New Password"
              name="newPassword"
              onChange={handleChange}
              type="password"
              value={formState.values.newPassword || ''}
              variant="outlined"
            />

            {
              formState.values.newPassword.length !== 0 ? (
                checkPassword()?.map((error, index) => {
                  return (
                    <FormHelperText key={index} style={{ marginLeft: '14px' }} error>
                      {error}
                    </FormHelperText>
                  )
                })
              ) : (
                null
              )
            }

            <TextField
              className={classes.textField}
              error={hasError('confirmPassword')}
              fullWidth
              helperText={
                hasError('confirmPassword')
                  ? formState.errors.confirmPassword[0]
                  : null
              }
              label="Confirm Password"
              name="confirmPassword"
              onChange={handleChange}
              type="password"
              value={formState.values.confirmPassword || ''}
              variant="outlined"
            />
            {match || (
              <FormHelperText style={{ marginLeft: '14px' }} error>Passwords don&apos; t match</FormHelperText>
            )}

            <Button
              className={classes.signInButton}
              color="primary"
              disabled={
                !formState.isValid ||
                !passwordValid ||
                status.loading === 'loading'
              }
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              {status === 'loading' ? 'Loading...' : 'Reset Password'}
            </Button>
          </form>
        </div>
      </Grid>
    </div>
  );
};

UpdatePasswordPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(UpdatePasswordPage);
