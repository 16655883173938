import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { formatDistance } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import { makeStyles, withStyles } from '@material-ui/styles';
import { loadCookie } from 'common/cookies';
import SearchBar from 'material-ui-search-bar';

import {
  Button,
  Grid,
  Paper,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  Typography,
  LinearProgress,
  Tooltip,
  TextField,
  InputAdornment,
  Checkbox,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import {
  areasFetcher,
  demographicsFetcher,
  projectsFetcher,
} from 'common/fetchers';

import { Toolbar } from 'views/Projects/components';
import {
  TableLoader,
  EmptyFallback,
  CapitalBreakdownModal,
  SearchFilter,
  MenuAction,
} from 'components';
import {
  AddProjectToCluster,
  AmountRaisedCell,
  BudgetCell,
  PledgeCell,
} from './components';

const useStyles = makeStyles(theme => ({
  root: {},
  actions: {
    justifyContent: 'flex-end',
  },
  clusterProfileLink: {
    cursor: 'pointer',
    animation: 'none',
    '&:hover': {
      fontWeight: 'bold',
      color: '#FF1D89',
    },
  },
  filterButton: {
    margin: theme.spacing(1),
  },
  filterContainer: {
    margin: theme.spacing(2),
    padding: theme.spacing(1),
  },
  activeFiltersContainer: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
  chipList: {
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  tableHead: {
    background: theme.palette.grey[200],
  },
}));

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.grey[50],
    },
  },
}))(TableRow);

const ProjectsTable = props => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = loadCookie();
  const {
    projects,
    isLoading,
    isPledgePage,
    isClusterCreateUpdatePage,
    handleProjectAllocationChange,
    projectAllocations,
    selectedProjects,
    handleProjectSelect,
    callback,
  } = props;

  const [rows, setRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [geography, setGeography] = useState([]);
  const [areaOfWorkFilter, setAreaOfWorkFilter] = useState([]);
  const [geographyFilter, setGeographyFilter] = useState([]);
  const [demographicFilter, setDemographicFilter] = useState([]);
  const [activeFilters, setActiveFilters] = useState([]);
  const [selectedProject, setSelectedProject] = useState({});
  const [openClusterAddModal, setOpenClusterAddModal] = useState(false);
  const [deleteProjectModalOpen, setDeleteProjectModalOpen] = useState(false);
  const [projectToDelete, setProjectToDelete] = useState(null);

  const [deleteProjectError, setDeleteProjectError] = useState('');
  const [deleteProject, { isLoading: deleteSaving }] = useMutation(
    projectsFetcher.remove
  );

  const { data: areasOfWorkData } = useQuery(
    areasFetcher.keys.fetch,
    areasFetcher.all
  );
  const areas = areasOfWorkData?.data?.areas || [];

  const { data: demographicsData } = useQuery(
    demographicsFetcher.keys.fetch,
    demographicsFetcher.all
  );
  const demographics = demographicsData?.data?.demographics || [];

  const getGeographyListFromProjects = projects => {
    let geographiesWithDuplicates = projects
      .map(project => {
        return project.geography?.trim().split('\n') || [];
      })
      .flat()
      .filter(geo => geo !== 'null');
    let uniqueGeographyArray = geographiesWithDuplicates.filter(
      (v, i, a) => a.indexOf(v) === i
    );
    return uniqueGeographyArray.map(geography => {
      return { name: geography, id: geography };
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    let mounted = false;
    if (!mounted && projects && !isLoading) {
      setGeography(getGeographyListFromProjects(projects));
      setRows(() =>
        projects.sort((a, b) => {
          return new Date(b?.createdAt) - new Date(a?.createdAt);
        })
      );
    }
    return () => {
      mounted = true;
    };
  }, [projects, isLoading]);

  useEffect(() => {
    // return;
    const getFilteredRows = () => {
      const filteredRows = projects.filter(row => {
        let filteredByArea =
          areaOfWorkFilter.length === 0
            ? true
            : row?.primaryAreas
              ?.map(area => area.name)
              .some(area =>
                areaOfWorkFilter.map(filter => filter.name).includes(area)
              );
        let filteredByDemographic =
          demographicFilter.length === 0
            ? true
            : row?.demographics
              ?.map(demo => demo.name)
              .some(demo =>
                demographicFilter.map(filter => filter.name).includes(demo)
              );
        let filteredByGeography =
          geographyFilter.length === 0
            ? true
            : geographyFilter
              .map(geography => geography.name)
              .some(geography => row.geography?.includes(geography));

        return filteredByArea && filteredByDemographic && filteredByGeography;
      });
      const getFilter = (filterList, filterType) => {
        return filterList.map(option => {
          return `${filterType}: ${option.name}`;
        });
      };

      setActiveFilters(
        getFilter(areaOfWorkFilter, 'Area Of Work')
          .concat(getFilter(demographicFilter, 'Demographic'))
          .concat(getFilter(geographyFilter, 'Location'))
      );
      return filteredRows;
    };

    const requestSearch = () => {
      const filteredRows = getFilteredRows().filter(row => {
        return (
          row.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          row.organization?.orgName
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        );
      });
      setRows(filteredRows);
      setPage(0);
    };
    if (projects && !isLoading) {
      requestSearch();
    }
  }, [
    areaOfWorkFilter,
    geographyFilter,
    demographicFilter,
    searchQuery,
    projects,
    isLoading,
  ]);

  // Sets selected project to be an empty object after modal closes
  useEffect(() => {
    if (!openClusterAddModal) setSelectedProject({});
  }, [openClusterAddModal]);

  const cancelSearch = () => {
    setSearchQuery('');
  };

  const navigateToProfile = projectId => {
    history.push({
      pathname: `/profile/project/${projectId}`,
    });
  };

  const resetFilters = () => {
    setAreaOfWorkFilter([]);
    setDemographicFilter([]);
    setGeographyFilter([]);
    setSearchQuery('');
  };

  const handleEditProject = projectId => {
    const project = projects.find(project => project.id === projectId);

    if (projectId !== null) {
      setSelectedProject(projectId);
      history.push({
        state: { project },
        pathname: `/projects/${projectId}/edit`,
      });
    }
  };

  const handleAddProject = projectId => {
    if (projectId !== null) {
      const project = projects.find(project => project.id === projectId);
      setSelectedProject(project);
      setOpenClusterAddModal(true);
    }
  };

  if (!isLoading && isEmpty(projects)) {
    return !user.isOrganizationUser ? (
      <div className={classes.root}>
        <EmptyFallback action={null} heading="No projects created yet." />
      </div>
    ) : (
      <div className={classes.root}>
        <EmptyFallback
          action={<Toolbar />}
          heading="Create a project to get started!"
        />
      </div>
    );
  }

  const handleDeleteProject = async event => {
    event.preventDefault();

    await deleteProject(projectToDelete.id, {
      onError: error => {
        setDeleteProjectError(error?.response?.data?.error?.message);
      },
      onSuccess: () => {
        setDeleteProjectError('');
        callback();
        setDeleteProjectModalOpen(false);
        setProjectToDelete(null);
      },
    });
  };

  return (
    <div>
      <TableContainer id="projects-table">
        <Paper className={classes.filterContainer} elevation={2}>
          <SearchBar
            value={searchQuery}
            onChange={searchVal => setSearchQuery(searchVal)}
            onCancelSearch={() => cancelSearch()}
          />
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <SearchFilter
                label="Area of Work"
                onApplyFilter={filterBy => {
                  setAreaOfWorkFilter(filterBy);
                }}
                options={areas}
                selectedFilters={areaOfWorkFilter}
                styles={classes.filterButton}
              />
              <SearchFilter
                label="Demographics"
                onApplyFilter={filterBy => setDemographicFilter(filterBy)}
                options={demographics}
                selectedFilters={demographicFilter}
                styles={classes.filterButton}
              />
              <SearchFilter
                label="Locations"
                onApplyFilter={filterBy => setGeographyFilter(filterBy)}
                options={geography}
                selectedFilters={geographyFilter}
                styles={classes.filterButton}
              />
            </Grid>
            <Grid item>
              <Button color="primary" variant="outlined" onClick={resetFilters}>
                Clear Filters
              </Button>
            </Grid>
          </Grid>
          {activeFilters.length !== 0 ? (
            <Paper
              elevation={0}
              variant="outlined"
              className={classes.activeFiltersContainer}
            >
              <Grid container alignItems="center">
                <Grid item xs={2}>
                  <Typography variant="caption" style={{ marginRight: '1em' }}>
                    Active Filters:
                  </Typography>
                </Grid>
                <Grid component="ul" item xs={10} className={classes.chipList}>
                  <>
                    {activeFilters.map(data => (
                      <li key={data}>
                        <Chip
                          size="small"
                          color="primary"
                          variant="outlined"
                          className={classes.chip}
                          label={data}
                        />
                      </li>
                    ))}
                  </>
                </Grid>
              </Grid>
            </Paper>
          ) : null}
        </Paper>
        <Paper
          style={{ overflow: 'hidden' }}
          className={classes.filterContainer}
          elevation={2}>
          <div style={{ overflow: 'auto' }}>
            <Table style={{ margin: '0px', width: '100%' }}>
              {/* // TABLE HEAD /////////////////////////////////////////////// */}
              <TableHead className={classes.tableHead}>
                <TableRow style={{ whiteSpace: 'nowrap' }}>
                  {isClusterCreateUpdatePage && (
                    <TableCell style={{ fontWeight: 'bold' }}>Select</TableCell>
                  )}
                  <TableCell style={{ width: '15rem', fontWeight: 'bold' }}>
                    Project
                  </TableCell>
                  <TableCell style={{ minWidth: '12rem', fontWeight: 'bold' }}>
                    Cluster
                  </TableCell>
                  <TableCell style={{ minWidth: '12rem', fontWeight: 'bold' }}>
                    Budget
                  </TableCell>
                  <TableCell style={{ width: '10rem', fontWeight: 'bold' }}>
                    Amount Raised
                  </TableCell>
                  <TableCell style={{ width: '10rem', fontWeight: 'bold' }}>
                    Pledged
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    Areas of Work
                  </TableCell>
                  {(user.isOrganizationUser ||
                    user.isNetworkUser ||
                    user.hasClusterRole ||
                    user.isAdminUser ||
                    user.hasProjectRole) &&
                    !isClusterCreateUpdatePage &&
                    !isPledgePage && (
                    <TableCell style={{ fontWeight: 'bold' }}>
                        Action
                    </TableCell>
                  )}
                  {user.isAdminUser && isPledgePage && (
                    <>
                      <TableCell style={{ width: 'auto', fontWeight: 'bold' }}>
                        Percentage
                      </TableCell>
                      <TableCell style={{ width: 'auto', fontWeight: 'bold' }}>
                        Value
                      </TableCell>
                    </>
                  )}
                </TableRow>
              </TableHead>
              {/* // TABLE BODY /////////////////////////////////////////////// */}
              <TableBody>
                {isLoading && <TableLoader colSpan={5} />}
                {!isLoading && isEmpty(projects) ? (
                  <TableRow>
                    <TableCell>
                      <Typography variant="h6">No Project Found</Typography>
                    </TableCell>
                  </TableRow>
                ) : null}
                {!isLoading && !isEmpty(rows) ? (
                  (rowsPerPage > 0
                    ? rows?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : rows
                  ).map((project, projectIndex) => {
                    return (
                      <StyledTableRow
                        className={classes.row}
                        height={57}
                        hover
                        id={project.id}
                        key={project.id}>
                        {isClusterCreateUpdatePage && (
                          <TableCell>
                            <Checkbox
                              checked={selectedProjects?.includes(project.id)}
                              value={project.id}
                              onChange={handleProjectSelect}
                            />
                          </TableCell>
                        )}

                        {/* // PROJECT COLUMN /////////////////////////////////////////////// */}
                        <TableCell>
                          <Button
                            onClick={() => navigateToProfile(project?.id)}>
                            <Grid container direction="column">
                              <Grid item xs={12}>
                                <Typography variant="h6" align="left">
                                  {project.name}
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography
                                  style={{ fontSize: '10px' }}
                                  align="left"
                                >
                                  Created{' '}
                                  {formatDistance(
                                    new Date(project?.createdAt),
                                    new Date(),
                                    {
                                      addSuffix: true,
                                    }
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Button>
                        </TableCell>
                        {/* // CLUSTER COLUMN /////////////////////////////////////////////// */}
                        <TableCell>
                          <div
                            style={{ maxHeight: '135px', overflowY: 'auto' }}>
                            {project.clusters && project.clusters.length > 0
                              ? project.clusters.map((cluster, index) => (
                                  <a
                                    key={`${project.id}-${cluster.id}`}
                                    href={`/profile/cluster/${cluster.id}`}>
                                    <span
                                      onClick={() =>
                                        history.push(
                                          `/profile/cluster/${cluster.id}`
                                        )
                                      }
                                      className={classes.clusterProfileLink}>
                                      {cluster.name}
                                    </span>
                                    {index === project.clusters.length - 1
                                      ? ''
                                      : ', '}
                                  </a>
                                ))
                              : 'N/A'}
                          </div>
                        </TableCell>
                        {/* // BUDGET COLUMN /////////////////////////////////////////////// */}
                        <TableCell>
                          <BudgetCell project={project} />
                        </TableCell>
                        {/* // AMOUNT RAISED COLUMN /////////////////////////////////////////////// */}
                        <TableCell>
                          <AmountRaisedCell project={project} />
                        </TableCell>
                        {/* // PLEDGED COLUMN /////////////////////////////////////////////// */}
                        <TableCell>
                          <PledgeCell project={project} />
                        </TableCell>
                        {/* // AREAS OF WORK COLUMN /////////////////////////////////////////////// */}
                        <TableCell>
                          <div
                            style={{
                              minWidth: '233px',
                              maxHeight: '135px',
                              overflowY: 'auto',
                            }}>
                            <Grid
                              component="ul"
                              item
                              xs={10}
                              className={classes.chipList}>
                              {project?.primaryAreas?.map(({ name }) => (
                                <span key={`${project.id}-${name}`}>
                                  <li key={name}>
                                    <Chip
                                      variant="outlined"
                                      color="primary"
                                      size="small"
                                      className={classes.chip}
                                      label={name}
                                    />
                                  </li>
                                </span>
                              ))}
                            </Grid>
                          </div>
                        </TableCell>
                        {/* // ACTION COLUMN /////////////////////////////////////////////// */}
                        {(user.isAdminUser ||
                          user.isNetworkUser ||
                          user.isOrganizationUser ||
                          user.hasProjectRole ||
                          user.hasClusterRole) &&
                          !isPledgePage &&
                          !isClusterCreateUpdatePage && (
                          <TableCell>
                            <MenuAction
                              items={[
                                  user?.isAdminUser || user?.hasProjectRole
                                    ? {
                                      itemName: 'Edit',
                                      itemActionEvent: () =>
                                        handleEditProject(project.id),
                                    }
                                    : {},
                                  user?.isAdminUser || user?.isNetworkUser || user?.hasClusterRole
                                    ? {
                                      itemName: 'Add to Cluster',
                                      itemActionEvent: () =>
                                        handleAddProject(project.id),
                                    }
                                    : {},
                                  {
                                    itemName: 'Delete Project',
                                    itemActionEvent: () => {
                                      setProjectToDelete(project);
                                      setDeleteProjectModalOpen(true);
                                    },
                                  },
                                ]}
                              />
                            </TableCell>
                          )}
                        {/* // ??? COLUMN(S) /////////////////////////////////////////////// */}
                        {user.isAdminUser && isPledgePage && (
                          <>
                            <TableCell>
                              <TextField
                                onChange={e =>
                                  handleProjectAllocationChange(e, project.id)
                                }
                                style={{ width: '85%' }}
                                id={`percentage-assignment-${projectIndex}`}
                                type="number"
                                defaultValue="0.00"
                                InputProps={{
                                  inputProps: { min: 0, max: 100 },
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      %
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </TableCell>
                            <TableCell>
                              {new Intl.NumberFormat('USA', {
                                style: 'currency',
                                currency: 'USD',
                              }).format(
                                projectAllocations[project.id]?.value || 0
                              )}
                            </TableCell>
                          </>
                        )}
                      </StyledTableRow>
                    );
                  })
                ) : (
                  <StyledTableRow>
                    <TableCell>
                      <Typography variant="h6">
                        No Projects Found for active filters
                      </Typography>
                    </TableCell>
                  </StyledTableRow>
                )}
                <TableRow>
                  <TablePagination
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    rowsPerPageOptions={[10]}
                  />
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </Paper>
      </TableContainer>
      {selectedProject?.id ? (
        <AddProjectToCluster
          submissionCallback={callback}
          open={openClusterAddModal}
          setModalOpen={setOpenClusterAddModal}
          id={selectedProject?.id}
        />
      ) : null}
      <Dialog open={deleteProjectModalOpen} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Delete Project</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {deleteProjectError && (
              <Grid item xs={12}>
                <Alert severity="error" variant="outlined">
                  {deleteProjectError}
                </Alert>
              </Grid>
            )}
            Confirm{' '}
            <span className={classes.warningText}>permanently delete</span>{' '}
            Project{' '}
            <span style={{ fontWeight: 600 }}>{projectToDelete?.name}</span>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDeleteProject}
            color="primary"
            variant="contained"
            disabled={deleteSaving}
          >
            Confirm
          </Button>
          <Button
            onClick={() => {
              setDeleteProjectError('');
              setDeleteProjectModalOpen(false);
            }}
            color="primary"
            disabled={deleteSaving}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

ProjectsTable.propTypes = {
  className: PropTypes.string,
  error: PropTypes.object,
  projects: PropTypes.array,
  isLoading: PropTypes.bool,
  isPledgePage: PropTypes.bool,
  handleProjectAllocationChange: PropTypes.func,
  projectAllocations: PropTypes.object,
  isClusterCreateUpdatePage: PropTypes.bool,
  selectedProjects: PropTypes.array,
  handleProjectSelect: PropTypes.func,
  callback: PropTypes.func,
};

ProjectsTable.defaultProps = {
  isLoading: false,
};

export default ProjectsTable;
