import React from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Chip,
  Typography,
} from '@material-ui/core';

import { withStyles } from '@material-ui/styles';

export const ProfileCard = withStyles({
  root: {
    marginBottom: '.5rem',
    padding: 0,
  },
})(Card);

export const ProfileCardHeader = withStyles({
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
})(CardHeader);

export const InterestAreaChip = withStyles(theme => ({
  label: {
    color: theme.palette.primary.dark,
    fontWeight: 'bold',
  },
  colorPrimary: {
    backgroundColor: theme.palette.primary.light,
  },
  colorSecondary: {
    backgroundColor: '#fdc3d7',
  },
}))(Chip);

export const AreasAndDemos = props => (
  <>
    <ProfileCardHeader title={props.title} />
    <PerfectScrollbar>
      <CardContent>
        <Grid container spacing={2} alignItems="flex-start">
          {props.items.length > 0 ? (
            props.items.map((item, key) => (
              <Grid item key={`Demographic-Location-Profile-${key}`}>
                <InterestAreaChip
                  style={{ borderRadius: '8px', color: '#3f51b5' }}
                  color={props.color}
                  label={item}
                />
              </Grid>
            ))
          ) : (
            <Typography class="placeholderText" variant="h5" style={{ margin: 'auto', paddingBottom: '2rem' }}>
              No {props.title} Assigned
            </Typography>
          )}
        </Grid>
      </CardContent>
    </PerfectScrollbar>
  </>
);
AreasAndDemos.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
  color: PropTypes.string,
};

export const ContactInfo = props => (
  <Grid item direction="row">
    <Grid
      container
      direction="row"
      alignItems="center"
      spacing={1}
      style={{ color: '1em' }}
    >
      <Grid item>{props.icon}</Grid>
      <Grid item>{props.content}</Grid>
    </Grid>
  </Grid>
);

ContactInfo.propTypes = {
  icon: PropTypes.element,
  content: PropTypes.element,
};

export const LocationChip = withStyles(theme => ({
  colorPrimary: {
    backgroundColor: theme.palette.primary.light,
  },
  colorSecondary: {
    backgroundColor: '#fdc3d7',
  },
}))(Chip);
