/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import {
  Toolbar,
  Typography,
  IconButton,
  Tooltip,
  CircularProgress,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));
const EnhancedTableToolbar = props => {
  const classes = useStyles();
  const {
    numSelected,
    setSelected,
    title,
    selectionActions,
    selectedRows,
    headerActions,
  } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}>
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div">
          {title || ''}
        </Typography>
      )}

      {numSelected > 0
        ? selectionActions.map((action, index) => {
            return (
              <Tooltip key={`selection-action-${index}`} title={action.name}>
                {action?.actionStatus ? (
                  <CircularProgress size={32} />
                ) : (
                  <IconButton
                    onClick={() => {
                      action.action(selectedRows, setSelected);
                    }}
                    aria-label={action.name}>
                    {action.icon}
                  </IconButton>
                )}
              </Tooltip>
            );
          })
        : headerActions.map((action, index) => {
            return (
              <Tooltip key={`header-action-${index}`} title={action.name}>
                {action?.actionStatus ? (
                  <CircularProgress size={32} />
                ) : (
                  <IconButton
                    onClick={() => action.action(selectedRows)}
                    aria-label={action.name}>
                    {action.icon}
                  </IconButton>
                )}
              </Tooltip>
            );
          })}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  selectionActions: PropTypes.array,
  headerActions: PropTypes.array,
  selectedRows: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired,
};
export default EnhancedTableToolbar;
