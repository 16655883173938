import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { ButtonBase, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: 'auto',
  },
  row: {
    height: '50px',
    width: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1),
  },
}));

const Toolbar = props => {
  const classes = useStyles();
  const { className, title, icon, action, ...rest } = props;

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <ButtonBase color="" onClick={action} variant="contained">
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            direction="row"
          >
            {icon}
            <Typography
              style={{ marginLeft: '0.2rem' }}
              variant="h5"
              color="primary"
            >
              {title}
            </Typography>
          </Grid>
        </ButtonBase>
      </div>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  action: PropTypes.func,
  title: PropTypes.string,
  icon: PropTypes.element,
};

export default Toolbar;
