import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import Cropper from 'react-cropper';
import {
  Button,
  Dialog,
  Card,
  CardContent,
  CardActions,
  DialogContent,
  DialogActions,
  Typography,
  Slider,
  Grid,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CropIcon from '@material-ui/icons/Crop';
import ImageIcon from '@material-ui/icons/Image';

const useStyles = makeStyles(() => ({
  root: {
    border: '2px dashed #ccc',
    '&.highlight': {
      borderColor: 'purple',
      backgroundColor: '#e9f3fe',
    },
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  previewContainer: {
    height: 450,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  previewImage: {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
  },
}));

// TODO Add if ratio = 1 https://github.com/fengyuanchen/cropper/issues/545

const ImageUploadModal = props => {
  const { setFile, imgURL } = props;
  const { aspectRatio } = props;
  const classes = useStyles();

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDropAccepted: () => setEditImageModel(true),
    noKeyboard: true,
    accept: 'image/jpeg, image/png',
    maxFiles: 1,
  });
  const [editImageModal, setEditImageModel] = useState(false);
  const [originalImage, setOriginalImage] = useState(2);
  const [isImageLoading, setIsImageLoading] = useState(true);
  const [cropper, setCropper] = useState();
  const [image, setImage] = useState(null);
  const [zoomValue, setZoomValue] = useState(1);

  const handleZoom = (event, newValue) => {
    setZoomValue(newValue);
    cropper.zoomTo(newValue.toFixed(4));
  };

  // Cropper Methods
  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      setImage(cropper.getCroppedCanvas()?.toDataURL());
      setFile(cropper.getCroppedCanvas()?.toDataURL());
      setEditImageModel(false);
    }
  };

  const previewFile = file => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setOriginalImage(reader.result);
      setFile(reader.result);
    };
  };

  useEffect(() => {
    if (acceptedFiles.length !== 0) {
      previewFile(acceptedFiles[0]);
    } else {
      if (imgURL === 'string') {
        setImage(null);
        setOriginalImage(null);
        setFile(null);
      } else {
        setImage(imgURL);
        setOriginalImage(imgURL);
        setFile(imgURL);
      }
    }
    // eslint-disable-next-line
  }, [acceptedFiles, imgURL]);

  return (
    <Card className={classes.root}>
      <CardActions>
        {image !== null && typeof image !== 'undefined' && (
          <Button
            onClick={() => {
              setImage(() => null);
              acceptedFiles.pop(0);
            }}
            style={{
              zIndex: 9,
              top: 10,
              position: 'relative',
            }}
            size="small"
            color="primary"
            startIcon={<CloseIcon />}>
            Delete
          </Button>
        )}
      </CardActions>
      <CardContent
        {...getRootProps({ className: 'dropzone' })}
        className={classes.flexContainer}>
        <input {...getInputProps()} />
        <label className={classes.previewContainer}>
          {image === null || typeof image === 'undefined' ? (
            <div className={classes.flexContainer}>
              <ImageIcon
                style={{ width: '80px', height: 'auto' }}
                color="primary"
              />
              <Typography variant="h6">
                Drop your image here, or{' '}
                <span style={{ color: '#FF1D89' }}>browse</span>
              </Typography>
              <Typography variant="subtitle2">
                Supports JPG, JPEG, PNG
              </Typography>
            </div>
          ) : (
            <div>
              <img
                src={image}
                className={classes.previewImage}
                alt="uploaded preview"
              />
            </div>
          )}
        </label>
        {image === null ||
          (typeof image === 'undefined' && (
            <div className={classes.flexContainer}>
              <ImageIcon
                style={{ width: '80px', height: 'auto' }}
                color="primary"
              />
              <Typography variant="h6">
                Drop your image here, or{' '}
                <span style={{ color: '#FF1D89' }}>browse</span>
              </Typography>
              <Typography variant="subtitle2">
                Supports JPG, JPEG, PNG
              </Typography>
            </div>
          ))}
      </CardContent>
      <CardActions>
        <Button
          disabled={image === null || typeof image === 'undefined'}
          onClick={() => {
            setEditImageModel(true);
          }}
          color="primary"
          startIcon={<CropIcon />}>
          Crop
        </Button>
      </CardActions>
      <Dialog open={editImageModal}>
        <DialogContent>
          <Cropper
            style={{ marginBottom: '1rem' }}
            dragMode="move"
            preview=".img-preview"
            src={originalImage}
            zoomTo={zoomValue}
            zoomable
            movable
            zoomOnWheel={false}
            zoomOnTouch={false}
            controls
            highlight
            initialAspectRatio={aspectRatio}
            aspectRatio={aspectRatio}
            viewMode={1}
            responsive
            restore={false}
            guides
            cropBoxResizable={false}
            cropBoxMovable
            onLoad={() => {
              alert('image loaded');
              setIsImageLoading(false);
            }}
            background={false}
            checkOrientation={false}
            toggleDragModeOnDblclick={false}
            onInitialized={instance => {
              setCropper(instance);
            }}
          />
          {isImageLoading ? (
            <Typography>Loading Image..</Typography>
          ) : (
            <>
              <Grid container xs={12} direction="row" justify="space-between">
                <Typography id="continuous-slider">Zoom</Typography>
                <Typography>
                  {Intl.NumberFormat('en-US', {
                    style: 'percent',
                  }).format(zoomValue)}
                </Typography>
              </Grid>
              <Slider
                disabled={isImageLoading}
                value={zoomValue}
                min={0}
                step={0.01}
                max={3}
                onChange={handleZoom}
                aria-labelledby="continuous-slider"
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={getCropData}
            color="primary"
            startIcon={<CropIcon />}>
            Crop
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

ImageUploadModal.propTypes = {
  setFile: PropTypes.func,
  imgURL: PropTypes.string,
  aspectRatio: PropTypes.number,
};
export default ImageUploadModal;
