import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const MenuAction = ({ items }) => {
  // Handles opening action menu for Network user
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMenuItemClick = itemActionEvent => {
    itemActionEvent();
    setAnchorEl(null);
    setOpen(false);
  };

  return (
    <>
      <IconButton id="menu_action_btn" onClick={handleClick}>
        <MoreVertIcon style={{ transform: 'rotate(90deg)' }} />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {items.map(({ itemName, itemActionEvent }) => {
          return (
            itemName &&
            itemActionEvent && (
              <MenuItem
                id={`menu-item-${itemName}`}
                style={{ minWidth: 100 }}
                key={itemName}
                onClick={() => handleMenuItemClick(itemActionEvent)}
              >
                <Typography align="center">{itemName}</Typography>
              </MenuItem>
            )
          );
        })}
      </Menu>
    </>
  );
};

MenuAction.propTypes = {
  items: PropTypes.array,
};

export default MenuAction;
