import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { isEmpty } from 'lodash';
import { formatDistance } from 'date-fns';
import { makeStyles } from '@material-ui/styles';
import { Edit, Delete } from '@material-ui/icons';
import SearchBar from 'material-ui-search-bar';
import { PageTopBar } from 'components';

import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableContainer,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
  Card,
  Snackbar,
  CardContent,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import PerfectScrollbar from 'react-perfect-scrollbar';
import AddUpdateOrganization from './components/AddUpdateOrganization';

import { TableLoader, EmptyFallback, GenericToolbar } from 'components';
import { organizationsFetcher } from 'common/fetchers';

const useStyles = makeStyles(theme => ({
  root: {},
  actions: {
    justifyContent: 'flex-end',
  },
  progress: {
    maxWidth: 100,
    marginTop: theme.spacing(1),
  },
}));

const OrganizationsTable = () => {
  const classes = useStyles();
  const [OrgModalOpen, setOrgModal] = useState(false);
  const [deleteOrganizationModalOpen, setDeletOrganizationModal] = useState(
    false
  );
  const [successOpen, setSuccessOpen] = useState(false);
  const [organization, setSelectedOrg] = useState(null);

  const {
    status,
    data: organizationData,
    refetch: refetchOrganizations,
  } = useQuery(organizationsFetcher.keys.fetch, organizationsFetcher.all, {
    staleTime: 60000,
    refetchOnWindowFocus: false,
  });

  const organizations = organizationData?.data || [];
  const isLoading = status === 'loading';
  const [rows, setRows] = useState([]);
  const [searched, setSearched] = useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };
  useEffect(() => {
    let mounted = false;

    if (!mounted && organizations) {
      setRows(() =>
        organizations.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        })
      );
    }
    return () => {
      mounted = true;
    };
  }, [organizations]);
  const requestSearch = searchedVal => {
    const filteredRows = organizations.filter(row => {
      return (
        row.orgName.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row.orgProfile?.contactEmail
          .toLowerCase()
          .includes(searchedVal.toLowerCase()) ||
        row.orgProfile?.pointOfContact
          .toLowerCase()
          .includes(searchedVal.toLowerCase())
      );
    });
    setRows(filteredRows);
    setPage(0);
  };
  const cancelSearch = () => {
    setSearched('');
    requestSearch(searched);
  };

  return (
    <>
      <PageTopBar
        PageTitle="Organizations"
        Widgets={[
          <GenericToolbar
            key="add-organization"
            title="Add Organization"
            action={() => setOrgModal(true)}
          />,
        ]}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
        }}
      />
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            {!isLoading && isEmpty(organizations) ? (
              <div className={classes.root}>
                <EmptyFallback
                  action={
                    <GenericToolbar
                      title="Add Organization"
                      action={() => setOrgModal(true)}
                    />
                  }
                  heading="No organizations exist"
                />
              </div>
            ) : (
              <>
                <TableContainer>
                  <SearchBar
                    value={searched}
                    onChange={searchVal => requestSearch(searchVal)}
                    onCancelSearch={() => cancelSearch()}
                  />
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Website</TableCell>
                        <TableCell>Contact Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isLoading && <TableLoader colSpan={5} />}
                      {!isLoading && isEmpty(organizations) ? (
                        <TableRow>
                          <TableCell>
                            <Typography variant="h6">
                              No Organization Found
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ) : null}
                      {!isLoading &&
                        organizations &&
                        (rowsPerPage > 0
                          ? rows?.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          : rows
                        ).map(org => {
                          return (
                            <TableRow
                              className={classes.row}
                              height={57}
                              hover
                              id={org.id}
                              key={org.id}
                            >
                              <TableCell>
                                <div>{`${org.orgName}`}</div>
                                <Typography variant="subtitle2">
                                  Created{' '}
                                  {formatDistance(
                                    new Date(org.createdAt),
                                    new Date(),
                                    {
                                      addSuffix: true,
                                    }
                                  )}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <a taget="_blank" href={org.website}>
                                  {org.website}
                                </a>
                              </TableCell>
                              <TableCell>
                                {org?.orgProfile?.pointOfContact || ''}
                              </TableCell>
                              <TableCell>
                                {org?.orgProfile?.contactEmail || ''}
                              </TableCell>
                              <TableCell>{org?.orgType?.name || ''}</TableCell>
                              <TableCell>
                                <IconButton
                                  aria-label="edit"
                                  data-user-id={org.id}
                                  onClick={() => {
                                    setSelectedOrg(org);
                                    setOrgModal(true);
                                  }}
                                >
                                  <Edit />
                                </IconButton>
                                <IconButton
                                  aria-label="delete"
                                  data-user-id={org.id}
                                  onClick={() => {
                                    // setSelectedOrg(org);
                                    setDeletOrganizationModal(true);
                                  }}
                                >
                                  <Delete />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                    <TablePagination
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      count={rows.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      rowsPerPageOptions={[5]}
                    />
                  </Table>
                </TableContainer>
                <Dialog
                  open={deleteOrganizationModalOpen}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title">
                    Delete organization
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Not yet Implemented. Contact Admin to delete an
                      organization.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => setDeletOrganizationModal(false)}
                      color="primary"
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
                <Snackbar
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                  open={successOpen}
                  autoHideDuration={6000}
                  onClose={() => setSuccessOpen(false)}
                >
                  <Alert severity="success">Organization Saved</Alert>
                </Snackbar>
              </>
            )}
          </PerfectScrollbar>
        </CardContent>
      </Card>
      <AddUpdateOrganization
        open={OrgModalOpen}
        close={setOrgModal}
        selectedOrg={setSelectedOrg}
        organization={organization}
        setSuccessOpen={setSuccessOpen}
        refresh={() => refetchOrganizations({ force: true })}
      />
    </>
  );
};

export default OrganizationsTable;
