import { Organization } from 'api';

// Keys are used to allow us to assign the same key
// for the same type of request so data can be cached
// with react-query
export const keys = {
  fetch: 'FETCH_ORGANIZATIONS',
  dashboardData: 'FETCH_ORG_DASHBOARD',
};

export const all = () => {
  return Organization.all();
};

export const save = organization => {
  return Organization.post(organization);
};

export const update = organization => {
  return Organization.update(organization.id, organization);
};

export const getDashboardData = organizationId => {
  return Organization.dashboardData(organizationId);
};
