import React, { useState } from 'react';
import readXlsxFile from 'read-excel-file';
import convertToJson from 'read-excel-file/schema';
import Papa from 'papaparse';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import { projectsFetcher } from 'common/fetchers';
import {
  Button,
  Dialog,
  Grid,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  CircularProgress,
  Link,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { isEmpty } from 'validate.js';

const useStyles = makeStyles(() => ({
  root: {
    height: 'auto',
    width: 'auto',
  },
  dialog: {
    height: '485px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  button: {
    marginTop: '1em',
    marginBottom: '.5em',
  },
  alert: {
    marginTop: '1em',
  },
}));

const BatchCreateProjectsModal = props => {
  const history = useHistory();
  const [parsedSpreadsheet, setParsedSpreadsheet] = useState(null);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [filename, setFilename] = useState('');
  const [projectsCreated, setProjectsCreated] = useState([]);
  const [projectsNotCreated, setProjectsNotCreated] = useState([]);
  const [batchCreateProjects] = useMutation(projectsFetcher.batchSave);

  const clearState = () => {
    setError('');
    setFilename('');
    setProjectsCreated([]);
    setProjectsNotCreated([]);
    setFormSubmitting(false);
    setParsedSpreadsheet(null);
  };

  const onClose = () => {
    clearState();
    handleClose();
  };

  const handleGoToProjects = () => {
    history.push('/projects');
  };
  const handleCreateProjects = async event => {
    event.preventDefault();
    setFormSubmitting(() => true);

    await batchCreateProjects(parsedSpreadsheet, {
      onError: error => {
        setError(
          error?.response?.data?.message ??
          'There was a problem creating your projects.\nPlease try again later.'
        );
        setFormSubmitting(() => false);
      },
      onSuccess: ({ data }) => {
        const successArray = [];
        const failureArray = [];
        data.map((result, index) => {
          return result.success
            ? successArray.push(result.name)
            : failureArray.push({ ...result, index });
        });

        setProjectsCreated(successArray);
        setProjectsNotCreated(failureArray);
        setParsedSpreadsheet(null);
        setFilename('');
        setError('');
        setFormSubmitting(() => false);
      },
    });
  };

  const handleUploadFile = async event => {
    event.persist();
    setError('');
    setFormSubmitting(() => true);
    setProjectsCreated([]);
    setProjectsNotCreated([]);

    const mapDemographics = row => {
      const demographicNames = row['demographics'];
      const demographicIds = demographics
        .map(demographic => {
          if (demographicNames.includes(demographic.name)) {
            return demographic.id;
          } else {
            return null;
          }
        })
        .filter(id => id != null);
      return demographicIds;
    };

    const mapPrimaryAreasOfWork = row => {
      const areasOfWorkNames = row['area_of_work'];
      const areaIds = areasOfWork
        .map(area => {
          if (areasOfWorkNames.includes(area.name)) {
            return area.id;
          } else {
            return null;
          }
        })
        .filter(id => id != null);
      return areaIds;
    };

    const mapProjectStatus = row => {
      const projectStatus = row['developmentTag'].toUpperCase();
      const projectStatusId = projectStatuses.find(
        developmentTag => developmentTag.name === projectStatus
      ).id;

      return projectStatusId;
    };

    const mapCapitalStructures = row => {
      var capitalStructure = {};
      for (const key in capitalTypes) {
        if (Object.hasOwnProperty.call(capitalTypes, key)) {
          const capitalType = capitalTypes[key];
          const capId = key;
          const capName = capitalType.name;
          if (row[capName]) {
            capitalStructure[capId] = {
              budgetReached: row[capName]['budgetReached'],
              budgetGoal: row[capName]['budgetGoal'],
            };
          }
        }
      }
      return capitalStructure;
    };

    const transformColumns = data => {
      const splitCols = {
        demographics: [],
        geography: [],
        area_of_work: [],
      };

      // Array fields are stored in individual columns with the same name
      // get the index of each column used to store an array value and store in a dictionary
      data[0].reduce((array, currVal, index) => {
        if (currVal === 'Area of work') {
          return splitCols['area_of_work'].push(index);
        }
        if (currVal === 'Demographics') {
          return splitCols['demographics'].push(index);
        }
        if (currVal === 'Geographic Target') {
          return splitCols['geography'].push(index);
        }
        return currVal;
      }, []);

      // Starting from the first data row iterate through the split cols & their indexes to create an array object
      // Set the first column as the array value
      data.slice(1).forEach(row => {
        Object.values(splitCols).forEach(col => {
          let joinedArray = col
            .filter(index => row[index] !== null)
            .map(itemIndex => row[itemIndex]);
          row[col[0]] = joinedArray;
        });
      });
      data.shift();
      return data;
    };

    const cleanRows = rows => {
      try {
        const cleanedRows = rows.rows.map(row => {
          // Map the string values to their object IDs
          row['capitalStructure'] = mapCapitalStructures(row);
          row['primaryAreasId'] = mapPrimaryAreasOfWork(row);
          row['demographicsId'] = mapDemographics(row);
          row['developmentTag'] = mapProjectStatus(row);

          // Clean up the row, removing unnecessary fields.
          /* eslint-disable no-unused-vars */
          const {
            Debt,
            Equity,
            Grant,
            area_of_work,
            demographics,
            ...cleanedRow
          } = row;
          /* eslint-enable no-unused-vars */
          return cleanedRow;
        });
        return cleanedRows;
      } catch (error) {
        throw new Error(
          'The provided file could not be parsed.\nPlease contact an administrator or check that it matches the provided template.'
        );
      }
    };
    const file = event.target.files[0];

    // Handle users opening file dialog and not selecting a file
    if (file === null) {
      setFormSubmitting(() => false);
      return;
    }
    setFilename(file.name);
    if (file.type === 'text/csv') {
      Papa.parse(file, {
        header: false,
        dynamicTyping: true,
        complete: function (parsedData) {
          try {
            const tranformedColumns = transformColumns(parsedData.data);
            const json = convertToJson(tranformedColumns, csvSchema);
            const cleanedRows = cleanRows(json);
            setFormSubmitting(() => false);
            setParsedSpreadsheet(cleanedRows);
          } catch (error) {
            setFormSubmitting(() => false);
            setError(error.message);
          }
        },
      });
    } else {
      try {
        let rows = await readXlsxFile(file, {
          map: xlsSchema,
          transformData(data) {
            return transformColumns(data);
          },
        });
        let cleanedRows = cleanRows(rows);
        setFormSubmitting(() => false);
        setParsedSpreadsheet(cleanedRows);
      } catch (error) {
        setError(error?.message ?? 'There was a problem parsing your file.');
        setFormSubmitting(() => false);
      }
    }
  };

  const csvSchema = {
    Name: { prop: 'name', type: String },
    Description: { prop: 'description', type: String },
    'Area of work': { prop: 'area_of_work', type: String },
    Demographics: { prop: 'demographics', type: String },
    'Organization Name': { prop: 'orgName', type: String },
    'Cluster Name': { prop: 'clusterName', type: String },
    'Geographic Target': { prop: 'geography', type: String },
    'Banner Image URL': { prop: 'bannerImage', type: String },
    'Contact Email': { prop: 'email', type: String },
    'Project Website': { prop: 'website', type: String },
    'Project Donation Page': { prop: 'donationPage', type: String },
    'Project Overview': { prop: 'overview', type: String },
    'Profile Image URL': { prop: 'profileImage', type: String },
    'Project Status': { prop: 'developmentTag', type: String },
    Equity: {
      prop: 'Equity',
      type: {
        'Equity Raised': { prop: 'budgetReached', type: Number },
        'Equity Goal': { prop: 'budgetGoal', type: Number },
      },
    },
    Debt: {
      prop: 'Debt',
      type: {
        'Debt Raised': { prop: 'budgetReached', type: Number },
        'Debt Goal': { prop: 'budgetGoal', type: Number },
      },
    },
    Grant: {
      prop: 'Grant',
      type: {
        'Grant Raised': { prop: 'budgetReached', type: Number },
        'Grant Goal': { prop: 'budgetGoal', type: Number },
      },
    },
  };

  // We use a different schema to parse XLS files.
  const xlsSchema = {
    Name: 'name',
    Description: 'description',
    'Area of work': 'area_of_work',
    Demographics: 'demographics',
    'Organization Name': 'orgName',
    'Cluster Name': 'clusterName',
    'Geographic Target': 'geography',
    'Banner Image URL': 'bannerImage',
    'Contact Email': 'email',
    'Project Website': 'website',
    'Project Donation Page': 'donationPage',
    'Project Overview': 'overview',
    'Profile Image URL': 'profileImage',
    'Project Status': 'developmentTag',
    Equity: {
      Equity: {
        'Equity Raised': 'budgetReached',
        'Equity Goal': 'budgetGoal',
      },
    },
    Debt: {
      Debt: {
        'Debt Raised': 'budgetReached',
        'Debt Goal': 'budgetGoal',
      },
    },
    Grant: {
      Grant: {
        'Grant Raised': 'budgetReached',
        'Grant Goal': 'budgetGoal',
      },
    },
  };

  const classes = useStyles();
  const {
    open,
    handleClose,
    areasOfWork,
    demographics,
    capitalTypes,
    projectStatuses,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <DialogTitle>Batch Create Projects</DialogTitle>

      <DialogContent>
        <DialogContentText>
          <ol>
            <li>
              <Link
                href="/batch_project_template.xlsx"
                download="Blueprint Project Template.xlsx"
              >
                Click here to download the project creation spreadsheet.
              </Link>
            </li>
            <li>
              Upload the completed spreadsheet below to create multiple
              Projects.
            </li>
          </ol>
        </DialogContentText>
        <Grid container direction="column" justify="center" alignItems="center">
          <input
            type="file"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            style={{ display: 'none' }}
            id="raised-button-file"
            onChange={handleUploadFile}
          />
          <label htmlFor="raised-button-file">
            <Button
              variant="contained"
              component="span"
              color="primary"
              className={classes.button}
            >
              {formSubmitting ? (
                <CircularProgress size={32} color="secondary" />
              ) : (
                'Upload Spreadsheet'
              )}
            </Button>
          </label>
          {filename === '' ? null : (
            <DialogContentText>{filename}</DialogContentText>
          )}
          {error === '' ? null : <DialogContentText>{error}</DialogContentText>}
        </Grid>

        {(projectsCreated.length > 0 || projectsNotCreated.length > 0) && (
          <Alert
            severity="success"
            variant="standard"
            className={classes.alert}
          >
            {projectsCreated.length} of{' '}
            {projectsCreated.length + projectsNotCreated.length}
            &nbsp;Projects successfully added.
          </Alert>
        )}

        {projectsNotCreated.length > 0 && (
          <Alert
            severity="warning"
            variant="standard"
            className={classes.alert}
          >
            The following Projects could not be added:
            <br />
            <ul>
              {projectsNotCreated.map(project => (
                <li key={project.index}>
                  <b>
                    {project.name ?? 'No project name given'}{' '}
                    {`(Spreadsheet Row ${project.index + 2})`}{' '}
                  </b>
                  <br />
                  {project.ERROR}
                </li>
              ))}
            </ul>
          </Alert>
        )}

        <DialogActions>
          <Button
            onClick={handleCreateProjects}
            color="primary"
            disabled={isEmpty(parsedSpreadsheet)}
          >
            Create Projects
          </Button>
          <Button onClick={handleGoToProjects} color="secondary">
            Go To Project List
          </Button>
          <Button onClick={onClose} color="default">
            Close
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

BatchCreateProjectsModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  areasOfWork: PropTypes.array,
  demographics: PropTypes.array,
  capitalTypes: PropTypes.object,
  projectStatuses: PropTypes.array,
};
export default BatchCreateProjectsModal;
