import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import BallotIcon from '@material-ui/icons/Ballot';
import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
  content: {
    alignItems: 'center',
    display: 'flex',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  title: {
    fontWeight: 700,
  },
  avatar: {
    backgroundColor: theme.palette.info.main,
    height: 56,
    width: 56,
  },
  icon: {
    height: 32,
    width: 32,
  },
  funded: {
    marginTop: '10%',
    marginBottom: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifySelf: 'flex-end',
    height: '100%',
  },
  fundedValue: {
    fontWeight: 500,
  },
}));

const TotalProjects = props => {
  const {
    className,
    // eslint-disable-next-line no-unused-vars
    isOrganizationView,
    projectCount,
    projectFundingStatus,
    ...rest
  } = props;

  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.container}>
        <Grid container justify="space-between">
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              id="total-projects-title"
              gutterBottom
              variant="body2"
            >
              TOTAL PROJECTS FOR MY CLUSTERS
            </Typography>
            <Typography id="total-projects-value" variant="h3">
              {projectCount}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <BallotIcon className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>
        <div className={classes.funded}>
          <Typography variant="body2">
            <span className={classes.fundedValue}>
              {projectFundingStatus?.reduce(
                (prev, curr) =>
                  curr.projectStatus === 'FUNDED' ? (prev += 1) : prev,
                0
              )}
            </span>{' '}
            projects funded
          </Typography>
          <Typography variant="caption" />
        </div>
      </CardContent>
    </Card>
  );
};

TotalProjects.propTypes = {
  className: PropTypes.string,
  isOrganizationView: PropTypes.bool,
  isLoading: PropTypes.bool,
  projectCount: PropTypes.number.isRequired,
  projectFundingStatus: PropTypes.array.isRequired,
};

export default TotalProjects;
