import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 50,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    display: 'inline-flex',
    justifyContent: 'center',
    textOverflow: 'ellipsis',
  },
  sm: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  md: {
    height: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  lg: {
    height: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  neutral: {
    backgroundColor: theme.palette.neutral,
  },
  primary: {
    color: theme.palette.white,
    backgroundColor: theme.palette.primary.main,
  },
  info: {
    color: theme.palette.white,
    backgroundColor: theme.palette.info.main,
  },
  warning: {
    color: theme.palette.white,
    backgroundColor: theme.palette.warning.main,
  },
  pink: {
    backgroundColor: theme.palette.pink,
  },
  danger: {
    color: theme.palette.white,
    backgroundColor: theme.palette.error.main,
  },
  success: {
    color: theme.palette.white,
    backgroundColor: theme.palette.success.main,
  },
}));

const Label = props => {
  const classes = useStyles();
  const { className, children, size, color, ...rest } = props;

  return (
    <div
      {...rest}
      className={clsx(
        {
          [classes.root]: true,
          [classes[size]]: size,
          [classes[color]]: color,
        },
        className
      )}
    >
      {children}
    </div>
  );
};

Label.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'neutral',
    'primary',
    'info',
    'success',
    'warning',
    'danger',
    'pink',
  ]),
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
};

Label.defaultProps = {
  size: 'md',
  color: 'default',
};

export default Label;
