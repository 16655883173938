import React, { useCallback, useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import useStatePersist from '../../../../common/hooks/useStatePersist';
import { Ballot } from '@material-ui/icons';
import { Drawer, Fab, IconButton, Box } from '@material-ui/core';
import {
  Loyalty,
  Dashboard,
  LocalAtm,
  Menu,
  Category,
} from '@material-ui/icons';
import CalendarViewDayIcon from '@material-ui/icons/CalendarViewDay';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { loadCookie } from 'common/cookies';
import { SidebarNav, MobileMenuPopup } from './components';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  drawer: {
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  toggleButton: {
    opacity: '65%',
    width: '1.25rem',
    height: '1.25rem',
    backgroundColor: 'white',
    boxShadow: '0 1px rgba(63,63,68,0.05)',
    border: '2px solid #3f51b5',
    borderRadius: '50%',
    position: 'absolute',
    left: `${drawerWidth - 255}px`,
    bottom: '-50rem',
    zIndex: '9',
    '&:hover': {
      backgroundColor: 'white',
      opacity: '100%',
    },
  },
  drawerOpen: {
    background: '#3F51B5',
    transition: 'all 0.2s ease-out',
    width: drawerWidth,
    zIndex: '2',
  },
  drawerClose: {
    background: '#3F51B5',
    overflow: 'hidden',
    width: theme.spacing(9) + 1,
    zIndex: '2',
  },
  icon: {
    color: theme.palette.white,
    width: '32px',
    height: '32px',
    display: 'flex',
    alignItems: 'center',
  },
  arrow: {
    color: theme.palette.primary,
    width: '24px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  menuFAB: {
    margin: 0,
    position: 'sticky',
    top: '95%',
    left: '100%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1,
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
}));

const Sidebar = props => {
  const { open, handleCollapse, variant, onClose, className, ...rest } = props;
  const { user } = loadCookie();
  const classes = useStyles();
  const [mobileView, setMobileView] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const [collapse, setCollapse] = useStatePersist('false', 'collapse');
  const rotate = collapse ? 'rotate(180deg)' : 'rotate(0)';

  useEffect(() => {
    handleCollapse(!collapse);
  });

  const toggleCollapse = () => {
    setCollapse(value => !value);
  };

  const isOrganizationUser = user?.isOrganizationUser;
  const isFundUser = user?.isFundUser;
  const isAdminUser = user?.isAdminUser;
  const isNetUser = user?.isNetworkUser;
  const hasProjectRole = user?.hasProjectRole;
  const hasClusterRole = user?.hasClusterRole;
  const hasViewFunderRole = user?.hasViewFunderRole;

  let pages = [];

  // Non-Flexible Roles page
  if (isAdminUser) {
    pages = [
      {
        title: 'Dashboard',
        href: '/dashboard',
        icon: <Dashboard />,
      },
      {
        title: 'Projects',
        href: '/projects',
        icon: <Ballot />,
      },
      {
        title: 'Clusters',
        href: '/clusters',
        icon: <CalendarViewDayIcon />,
      },
      {
        title: 'Pledges',
        href: '/pledges',
        icon: <Loyalty />,
      },
      {
        title: 'Funders',
        href: '/funders',
        icon: <LocalAtm />,
      },
      {
        title: 'Manage Categories',
        href: '/categories',
        icon: <Category />,
      },
    ];
  } else if (isOrganizationUser) {
    pages = [
      {
        title: 'Projects',
        href: '/projects',
        icon: <Ballot />,
      },
    ];
  } else if (isFundUser) {
    pages = [
      {
        title: 'Dashboard',
        href: '/dashboard',
        icon: <Dashboard />,
      },
      {
        title: 'Clusters',
        href: '/clusters',
        icon: <CalendarViewDayIcon />,
      },
      {
        title: 'Pledges',
        href: '/pledges',
        icon: <Loyalty />,
      },
    ];
  } else {
    pages = [
      {
        title: 'Dashboard',
        href: '/dashboard',
        icon: <Dashboard />,
      },
      {
        title: 'Clusters',
        href: '/clusters',
        icon: <CalendarViewDayIcon />,
      },
      {
        title: 'Projects',
        href: '/projects',
        icon: <Ballot />,
      },
      {
        title: 'Pledges',
        href: '/pledges',
        icon: <Loyalty />,
      },
    ];
  }

  if (
    (hasProjectRole || hasClusterRole) &&
    !isNetUser &&
    !isOrganizationUser &&
    !isAdminUser
  )
    pages.push({
      title: 'Projects',
      href: '/projects',
      icon: <Ballot />,
    });
  if (hasClusterRole && !isFundUser && !isNetUser && !isAdminUser)
    pages.push({
      title: 'Clusters',
      href: '/clusters',
      icon: <CalendarViewDayIcon />,
    });
  if (hasViewFunderRole && !isAdminUser)
    pages.push({
      title: 'Funders',
      href: '/funders',
      icon: <LocalAtm />,
    });

  const closeMobileMenuPopup = useCallback(() => setMobileView(false), []);

  return (
    <>
      <Box
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
        style={{ position: 'fixed' }}>
        <Drawer
          anchor="left"
          classes={{
            paper: clsx({
              [classes.drawerOpen]: collapse,
              [classes.drawerClose]: !collapse,
            }),
          }}
          onClose={onClose}
          open={open}
          variant={variant}>
          <div {...rest} className={clsx(classes.root, className)}>
            <SidebarNav
              className={classes.nav}
              collapse={collapse}
              pages={pages}
            />
          </div>
        </Drawer>

        <IconButton
          style={{
            transition: '0.5s',
            opacity: `${isShown ? 1 : 0}`,
          }}
          className={classes.toggleButton}
          onClick={toggleCollapse}
          variant="text">
          <ChevronRightIcon
            color="primary"
            className={classes.arrow}
            style={{ transform: rotate, transition: 'all 0.2s linear' }}
          />
        </IconButton>
      </Box>
      <MobileMenuPopup
        pages={pages}
        open={mobileView}
        handleClose={closeMobileMenuPopup}
      />
      <Fab
        onClick={() => setMobileView(true)}
        color="primary"
        className={classes.menuFAB}>
        <Menu />
      </Fab>
    </>
  );
};

Sidebar.propTypes = {
  handleCollapse: PropTypes.func,
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

export default Sidebar;
