import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
// import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
  },
  avatar: {
    width: 60,
    height: 60,
    backgroundColor: theme.palette.primary.main,
  },
  name: {
    marginTop: theme.spacing(1),
  },
}));
const BlueprintLogo = props => {
  const classes = useStyles();
  const { className, ...rest } = props;

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <img alt="logo" src="/images/logos/logo.svg" />
      {/* <Typography variant="subtitle2" style={{ color: 'white', cursor:'default'}} >Blueprint</Typography> */}
    </div>
  );
};

BlueprintLogo.propTypes = {
  className: PropTypes.string,
};

export default BlueprintLogo;
