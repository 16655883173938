import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  parseISO,
  subMonths,
  format,
  isSameMonth,
  isBefore,
  endOfMonth,
  isAfter,
} from 'date-fns';
import { Bar } from 'react-chartjs-2';
import { data, options } from './chart';
import { StatusBullet } from 'components';
import { makeStyles } from '@material-ui/styles';
import { Card, CardHeader, CardContent, Divider } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  root: {},
  chartContainer: {
    height: 400,
    position: 'relative',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  statusBudget: {
    marginRight: theme.spacing(1),
  },
  statusPledge: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

const getPast12Months = () => {
  const currentDate = new Date();
  let graphLabels = [];
  for (let i = 12; i >= 1; i--) {
    graphLabels.push(format(subMonths(currentDate, i), 'MMM').toUpperCase());
  }
  return graphLabels;
};

const definePledgeData = pledgeData => {
  const currentDate = new Date();
  let pledgesByMonth = [];
  for (let i = 12; i >= 1; i--) {
    const givenDate = subMonths(currentDate, i);
    //for the given date, we calculate the total pledges donated in that month
    pledgesByMonth.push(
      pledgeData.reduce((prev, curr) => {
        const pledgeDate = parseISO(
          curr?.createdAt?.split('T')[0] || curr?.createdAt
        );
        if (isSameMonth(givenDate, pledgeDate)) {
          return prev + Number(curr.amount);
        } else {
          return prev;
        }
      }, 0)
    );
  }
  return pledgesByMonth;
};

const defineBudgetData = projectData => {
  const currentDate = new Date();
  let budgetByMonth = [];
  for (let i = 12; i >= 1; i--) {
    const givenDate = endOfMonth(subMonths(currentDate, i));
    //for the given date, we calculate the total pledges donated in that month
    budgetByMonth.push(
      projectData.reduce((prev, curr) => {
        const projectCreationDate = parseISO(
          curr?.createdAt?.split('T')[0] || curr?.createdAt
        );
        const fundedDate = parseISO(
          curr?.fundedDate?.split('T')[0] || curr?.fundedDate || ''
        );
        if (
          isSameMonth(givenDate, projectCreationDate) ||
          (curr.fundedDate && isSameMonth(givenDate, fundedDate)) ||
          (isBefore(projectCreationDate, givenDate) && !curr.fundedDate) ||
          (curr.fundedDate &&
            isAfter(fundedDate, givenDate) &&
            isAfter(givenDate, projectCreationDate))
        ) {
          return prev + Number(curr.budgetGoal);
        } else {
          return prev;
        }
      }, 0)
    );
  }
  return budgetByMonth;
};

const PledgesBudgetChart = props => {
  const { className, projectData, isLoading, pledgeData, ...rest } = props;

  const classes = useStyles();

  data.datasets[0].data = defineBudgetData(projectData || []);
  data.datasets[1].data = definePledgeData(pledgeData || []);
  data.labels = getPast12Months();
  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        title={
          <div className={classes.statusContainer}>
            <StatusBullet
              className={classes.statusBudget}
              color="primary"
              size="sm"
            />
            Budget
            <StatusBullet
              className={classes.statusPledge}
              color="pink"
              size="sm"
            />
            Pledges
          </div>
        }
      />
      <Divider />
      <CardContent>
        {isLoading ? (
          <Skeleton />
        ) : (
          <div className={classes.chartContainer}>
            <Bar data={data} options={options} />
          </div>
        )}
      </CardContent>
    </Card>
  );
};

PledgesBudgetChart.propTypes = {
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  projectData: PropTypes.array.isRequired,
  pledgeData: PropTypes.array.isRequired,
};

export default PledgesBudgetChart;
