import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Doughnut } from 'react-chartjs-2';
import {
  Avatar,
  Grid,
  CardContent,
  LinearProgress,
  Typography,
  Divider,
  Box,
  colors,
  useTheme,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/styles';
import {
  AccountBalance,
  AccountBalanceWallet,
  CreditCard,
} from '@material-ui/icons/';
import EventIcon from '@material-ui/icons/Event';
import { abbreivateValue } from 'helpers';
import { ProfileCard, ProfileCardHeader } from './ProfilePageComponents';
import moment from 'moment';
import ProfileContext from 'components/GeneralProfile/ProfileContext';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '100%',
  },
  box: {
    height: '300px',
  },
  doughnut: {
    height: '100%',
    width: 'auto',
    position: 'absolute',
    top: 0,
    bottom: 0,
  },
}));

const FundingProgress = withStyles(theme => ({
  determinate: {
    height: theme.spacing(2),
  },
}))(LinearProgress);

const sortCapitalNeeds = capitalNeeds => {
  const sortOrder = ['Grant', 'Debt', 'Equity'];
  const sortedNeeds = [];
  sortOrder.map(currentItem => {
    const index = capitalNeeds.find(need => need.name === currentItem);
    if (index) {
      return sortedNeeds.push(index);
    }
    return capitalNeeds;
  });
  return sortedNeeds.concat(
    capitalNeeds.filter(need => !sortOrder.includes(need.name))
  );
};

const ProfileBreakdown = ({ capitalNeeds }) => {
  const { hideFinancials } = React.useContext(ProfileContext);
  const classes = useStyles();
  const theme = useTheme();
  const [sortedCapitalNeeds, setSortedCapitalNeeds] = useState([]);
  const [
    capitalNeedsBackgroundColors,
    setCapitalNeedsBackgroundColor,
  ] = useState([]);

  useEffect(() => {
    const sortedCapitalNeeds = sortCapitalNeeds(capitalNeeds);
    setSortedCapitalNeeds(sortedCapitalNeeds);

    setCapitalNeedsBackgroundColor(() => {
      const backgroundColors = [];
      sortedCapitalNeeds.forEach(({ name: capitalNeedName }) => {
        if (capitalNeedName === 'Grant') {
          backgroundColors.push(theme.palette.primary.main);
        } else if (capitalNeedName === 'Debt') {
          backgroundColors.push(theme.palette.pink);
        } else if (capitalNeedName === 'Equity') {
          backgroundColors.push(theme.palette.secondary.main);
        }
      });

      return backgroundColors;
    });
  }, [capitalNeeds, theme]);

  const capitalIcons = {
    equity: {
      icon: <AccountBalance style={{ color: 'white' }} />,
      color: theme.palette.secondary.main,
    },
    debt: {
      icon: <AccountBalanceWallet style={{ color: 'white' }} />,
      color: theme.palette.pink,
    },
    grant: {
      icon: <CreditCard style={{ color: 'white' }} />,
      color: theme.palette.primary.main,
    },
  };
  const doughnutData = {
    datasets: [
      {
        data: sortedCapitalNeeds?.map(({ totalBudgetGoal }) => totalBudgetGoal),
        backgroundColor: capitalNeedsBackgroundColors,
        borderWidth: 8,
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white,
        datalabels: {
          anchor: 'end',
        },
      },
    ],
    labels: sortedCapitalNeeds?.map(({ name }) => name),
  };

  const doughnutOptions = {
    animation: true,
    legend: {
      display: true,
      position: 'left',
    },
    layout: { padding: 20 },
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      datalabels: {
        backgroundColor: function(context) {
          return context.dataset.backgroundColor;
        },
        borderColor: 'white',
        borderRadius: 25,
        borderWidth: 2,
        color: 'white',
        display: function(context) {
          var dataset = context.dataset;
          var count = dataset.data.length;
          var value = dataset.data[context.dataIndex];
          return value > count * 1.5;
        },
        formatter: (value, ctx) => {
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          dataArr.map(data => {
            sum += data;
            return sum;
          });
          let percentage = Math.round((value * 100) / sum) + '%';
          return percentage;
        },
        padding: 6,
        weight: '600',
      },
    },
  };

  const renderCapitalNeed = capitalNeed => {
    const {
      capitalTypeId,
      name,
      totalBudgetGoal,
      totalBudgetReached,
      targetDate,
    } = capitalNeed;
    const { icon, color } = capitalIcons[name.toLowerCase()];
    //
    let value;
    if (hideFinancials) {
      if (!totalBudgetGoal || totalBudgetGoal === 0) {
        value = 'N/A';
      } else {
        value = Math.round(
          (totalBudgetReached / totalBudgetGoal || 0) * 100 * 100
        );
        value /= 100;
        value += '%';
      }
    } else {
      value = `${abbreivateValue(totalBudgetReached)} of $${abbreivateValue(
        totalBudgetGoal
      )}`;
    }
    //
    return (
      <Grid
        key={'item-' + capitalTypeId}
        item
        container
        direction="column"
        justify="flex-start"
        spacing={1}
        xs={12}
        md={4}>
        <Grid container justify="flex-start" alignItems="flex-start" item>
          <Avatar style={{ backgroundColor: color }}>{icon}</Avatar>
          <Grid
            item
            direction="column"
            alignItems="flex-start"
            justify="center"
            style={{ marginLeft: 10 }}
            xs={6}>
            <Grid item xs={12}>
              <Typography
                style={{
                  color: color,
                }}
                variant="subtitle1">
                {name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                style={{ fontSize: '16px', color: color }}
                variant="h6">
                {value}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs alignItems="flex-start" justify="flex-end">
            <Typography
              style={{
                float: 'right',
                color: color,
                verticalAlign: 'middle',
                display: 'inline-flex',
              }}>
              {targetDate && <EventIcon style={{ marginRight: '10px' }} />}
              {targetDate ? moment(targetDate).format('ll') : null}
            </Typography>
          </Grid>
        </Grid>
        <Grid justify="flex-start" alignItems="center" item>
          <FundingProgress
            value={
              totalBudgetGoal > 0
                ? (totalBudgetReached / totalBudgetGoal) * 100 > 100
                  ? 100
                  : (totalBudgetReached / totalBudgetGoal) * 100
                : 0
            }
            valueBuffer={100}
            variant="determinate"
          />
        </Grid>
      </Grid>
    );
  };
  return (
    <ProfileCard className={classes.root}>
      <ProfileCardHeader title="Funding" />
      <Divider />
      <PerfectScrollbar>
        <CardContent>
          <Grid
            container
            justify="center"
            alignItems="center"
            direction="column"
            xs={12}>
            <Grid
              container
              direction="row"
              spacing={3}
              justify="flex-start"
              alignItems="flex-start"
              item>
              {sortedCapitalNeeds.length > 0
                ? sortedCapitalNeeds.map(need => renderCapitalNeed(need))
                : null}
            </Grid>
            <Grid
              container
              justify="flex-start"
              alignItems="flex-start"
              direction="column"
              style={{ marginTop: theme.spacing(4) }}
              item>
              <Box className={classes.box}>
                Capital Breakdown
                <Doughnut
                  className={classes.doughnut}
                  options={doughnutOptions}
                  data={doughnutData}
                />
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </PerfectScrollbar>
    </ProfileCard>
  );
};

ProfileBreakdown.propTypes = {
  capitalNeeds: PropTypes.array,
};

export default ProfileBreakdown;
