import { Areas, AreasMeta } from 'api';

// Keys are used to allow us to assign the same key
// for the same type of request so data can be cached
// with react-query
export const keys = {
  fetch: 'FETCH_AREAS',
  fetchMeta: 'FETCH_AREAS_META',
};

export const all = () => {
  return Areas.all();
};

export const save = area => {
  return Areas.post(area);
};

export const update = area => {
  return Areas.update(area?.id, area);
};

export const remove = areas => {
  return Areas.post(areas, '/batchDelete');
};

export const allByArea = id => {
  return AreasMeta.find(id);
};
