/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { RouteWithLayout } from 'components';
import { Minimal as MinimalLayout } from 'layouts';
import { Switch, Redirect } from 'react-router-dom';
//import { useMutation } from 'react-query';
import { tokenExistsFetcher } from './fetchers';

import UpdatePasswordPage from './UpdatePassword';

const UpdatePasswordRoutes = ({ location }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [token, setToken] = useState('');

  const tokenExists = async () => {
    // Retrieving token url paramter
    const token = new URLSearchParams(location.search).get('token');

    if (!token) return false;

    // Querying blueprint api for token existence
    const {
      data: { tokenExists: exists },
    } = await tokenExistsFetcher(token);
    return { exists, token };
  };

  useEffect(() => {
    tokenExists().then(
      ({ exists, token }) => {
        if (exists) setToken(token);
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);
      }
    );
  });

  const renderRoute = () => {
    return token ? (
      <RouteWithLayout
        component={UpdatePasswordPage}
        token={token}
        exact
        isPrivate={false}
        layout={MinimalLayout}
        path="/update-password"
      />
    ) : (
      <Redirect to="/sign-in" />
    );
  };

  return <Switch>{!isLoading ? renderRoute() : null}</Switch>;
};

export default UpdatePasswordRoutes;
