import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Paper,
  Chip,
  Typography,
  Box,
} from '@material-ui/core';

import {
  EnhancedTableHead,
  EnhancedTableToolbar,
  EnhancedRow,
} from './components';
import { TableLoader, SearchFilter } from 'components';
import SearchBar from 'material-ui-search-bar';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: '100%',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  filterContainer: {
    margin: theme.spacing(2),
    padding: theme.spacing(1),
  },
  activeFiltersContainer: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
}));

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
};

const EnhancedTable = props => {
  const classes = useStyles();
  const {
    headers,
    rows,
    dense,
    title,
    primaryLabel,
    loading,
    collapse,
    CollapseContent,
    actions,
    admin,
    defaultOrderBy,
    selectionActions,
    headerActions,
    saveFunc,
    deleteFunc,
    editFunc,
    batchSelection,
    searchFields,
    searchFilters,
    getFilteredRows,
    activeFilters,
    resetFilters,
    selectedRows,
  } = props;

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState(defaultOrderBy);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    setSelected(selectedRows ? selectedRows.map(d => d.id) : []);
  }, [selectedRows]);

  useEffect(() => {
    setData(rows);
  }, [rows]);

  useEffect(() => {
    requestSearch();
    // eslint-disable-next-line
  }, [searchQuery, activeFilters]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const requestSearch = () => {
    const filteredRows = getFilteredRows
      ? getFilteredRows(rows)?.filter(row => {
          const bool = searchFields?.map(value => {
            return row[value]
              ? row[value]?.toLowerCase().includes(searchQuery.toLowerCase())
              : false;
          });
          return bool.includes(true);
        })
      : rows.filter(row => {
          const bool = searchFields?.map(value => {
            return row[value]
              ? row[value]?.toLowerCase().includes(searchQuery.toLowerCase())
              : false;
          });
          return bool?.includes(true) || [];
        });

    setData(filteredRows);
    setPage(0);
  };

  const cancelSearch = () => {
    setSearchQuery('');
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = data.map(n => n);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, row) => {
    const rowId = row.id;
    const selectedIndex = selected.indexOf(rowId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, rowId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = row => {
    return selected?.indexOf(row.id) !== -1;
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows?.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          className={classes.toolbar}
          selectionActions={selectionActions || []}
          headerActions={headerActions || []}
          title={title}
          numSelected={selected?.length}
          selectedRows={selected}
          setSelected={setSelected}
        />
        {(searchFields || searchFilters) && (
          <Box className={classes.filterContainer}>
            {searchFields && (
              <SearchBar
                value={searchQuery}
                onChange={searchVal => setSearchQuery(searchVal)}
                onCancelSearch={() => cancelSearch()}
                style={{ marginBottom: 10 }}
              />
            )}

            {searchFilters && (
              <Grid
                container
                alignItems="center"
                justifyContent="space-between">
                {searchFilters?.map(
                  (
                    { label, onApplyFilter, options, selectedFilters, styles },
                    index
                  ) => {
                    return (
                      <Grid key={`Search-Filter-${label}-${index}`} item>
                        <SearchFilter
                          label={label}
                          onApplyFilter={onApplyFilter}
                          options={options}
                          selectedFilters={selectedFilters}
                          styles={styles}
                        />
                      </Grid>
                    );
                  }
                )}
                <Grid item>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => resetFilters(setSearchQuery)}>
                    Clear Filters
                  </Button>
                </Grid>
              </Grid>
            )}
            {searchFilters ? (
              activeFilters.length !== 0 ? (
                <Box className={classes.activeFiltersContainer}>
                  <Grid container alignItems="center">
                    <Grid item xs={2}>
                      <Typography
                        variant="caption"
                        style={{ marginRight: '1em' }}>
                        Active Filters:
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      spacing={1}
                      item
                      xs={10}
                      className={classes.chipList}>
                      <>
                        {activeFilters.map(data => (
                          <Grid item key={data}>
                            <Chip
                              size="small"
                              variant="outlined"
                              color="primary"
                              className={classes.chip}
                              label={data}
                            />
                          </Grid>
                        ))}
                      </>
                    </Grid>
                  </Grid>
                </Box>
              ) : null
            ) : null}
          </Box>
        )}
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table">
            <EnhancedTableHead
              classes={classes}
              headCells={headers}
              numSelected={selected?.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={data?.length}
              batchSelection={batchSelection || false}
            />
            <TableBody>
              {loading && <TableLoader colSpan={headers?.length} />}
              {!loading &&
                stableSort(data, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <EnhancedRow
                        key={`${title}-row-${index}`}
                        labelId={labelId}
                        row={row}
                        primaryLabel={primaryLabel}
                        actions={actions}
                        admin={admin}
                        isItemSelected={isItemSelected}
                        handleClick={handleClick}
                        headers={headers}
                        collapse={collapse || false}
                        CollapseContent={CollapseContent || null}
                        saveFunc={saveFunc}
                        deleteFunc={deleteFunc}
                        editFunc={editFunc}
                        batchSelection={batchSelection || false}
                        title={title}
                      />
                    );
                  })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 8 : 10) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {data.length > rowsPerPage && (
          <TablePagination
            rowsPerPageOptions={[5]}
            component="div"
            count={data?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </div>
  );
};

EnhancedTable.propTypes = {
  rows: PropTypes.array.isRequired,
  selectedRows: PropTypes.array,
  headers: PropTypes.array.isRequired,
  title: PropTypes.string,
  defaultOrderBy: PropTypes.string.isRequired,
  primaryLabel: PropTypes.string,
  dense: PropTypes.bool,
  loading: PropTypes.bool,
  admin: PropTypes.bool,
  actions: PropTypes.array,
  collapse: PropTypes.bool,
  CollapseContent: PropTypes.func,
  saveFunc: PropTypes.func,
  editFunc: PropTypes.func,
  deleteFunc: PropTypes.func,
  batchSelection: PropTypes.bool,
  selectionActions: PropTypes.array,
  headerActions: PropTypes.array,
  searchFields: PropTypes.arrayOf(PropTypes.string),
  searchFilters: PropTypes.arrayOf(PropTypes.object),
  getFilteredRows: PropTypes.func,
  activeFilters: PropTypes.array,
  resetFilters: PropTypes.func,
};
export default EnhancedTable;
