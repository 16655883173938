import { Demographics, DemographicsMeta } from 'api';

// Keys are used to allow us to assign the same key
// for the same type of request so data can be cached
// with react-query
export const keys = {
  fetch: 'FETCH_DEMOGRAPHICS',
  update: 'UPDATE_DEMOGRAPHICS',
  delete: 'DELETE_DEMOGRAPHICS',
  create: 'ADD_DEMOGRAPHICS',
};

export const all = () => {
  return Demographics.all();
};

export const save = demographics => {
  return Demographics.post(demographics);
};

export const remove = demographics => {
  return Demographics.post(demographics, '/batchDelete');
};

export const update = demographic => {
  return Demographics.update(demographic?.id, demographic);
};

export const allByDemography = id => {
  return DemographicsMeta.find(id);
};
