import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Typography, Grid } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    height: '35vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const EmptyFallback = ({ action, heading }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <Typography variant="h2">{heading}</Typography>
      {action}
    </Grid>
  );
};

EmptyFallback.propTypes = {
  action: PropTypes.any,
  heading: PropTypes.string,
};

export default EmptyFallback;
