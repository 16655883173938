import { useMutation } from 'react-query';
import { pledgesFetcher } from 'common/fetchers';

const useAssignFunds = () => {
  const [
    requestCapitalAllocation,
    { status: requestCapitalAllocationStatus },
  ] = useMutation(pledgesFetcher.requestCapitalAllocation);

  const [assignFunds, { status: assignFundsStatus }] = useMutation(
    pledgesFetcher.assign
  );

  const handle = callback => {
    return async (event, request, setError) => {
      event.preventDefault();
      try {
        return await callback(request.body, request.action);
      } catch (err) {
        setError(err.message);
      }
    };
  };

  // Request body should have: id(pledgeId), assignmentType, optinally
  // projectAssignments
  const handleRequestCapitalAllocation = handle(requestCapitalAllocation);

  // Request body should only have id (pledgeId)
  const handleConfirmation = handle(assignFunds);

  return {
    handleRequestCapitalAllocation,
    handleConfirmation,
    requestCapitalAllocationStatus,
    assignFundsStatus,
  };
};

export default useAssignFunds;
