import React from 'react';
import { Main as MainLayout } from 'layouts';
import { RouteWithLayout } from 'components';

const CategoriesPage = React.lazy(() => import('./Categories'));

const CategoriesRoutes = {
  list: (
    <RouteWithLayout
      component={CategoriesPage}
      exact
      layout={MainLayout}
      path="/categories"
      requireActive
      isPrivate
      userAccess={['admin']}
      redirect={'/dashboard'}
      key="list-categories"
    />
  ),
};

export default CategoriesRoutes;
