import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import ReactQuill from 'react-quill';
import EditorToolbar, { modules, formats } from './HtmlEditorToolbar';
import 'react-quill/dist/quill.snow.css';
import '../../assets/scss/index.scss';

const HtmlEditor = ({ content, updateEditorContent }) => {
  const [editorState, setEditorState] = useState(null);
  const isContentSet = useRef(false);

  const handleChange = value => {
    setEditorState(value);
    updateEditorContent(value);
  };

  // Setting initial content state
  useEffect(() => {
    if (!isContentSet.current && content !== undefined && content !== '') {
      setEditorState(content);
      isContentSet.current = true;
    }
  }, [content]);

  return (
    <div>
      <EditorToolbar />
      <ReactQuill
        theme="snow"
        value={editorState}
        onChange={handleChange}
        placeholder={'Write something awesome...'}
        modules={modules}
        formats={formats}
      />
    </div>
  );
};

HtmlEditor.propTypes = {
  content: PropTypes.string,
  updateEditorContent: PropTypes.func,
};
export default HtmlEditor;
