import { Networks } from 'api';

// Keys are used to allow us to assign the same key
// for the same type of request so data can be cached
// with react-query
export const keys = {
  fetch: 'FETCH_NETWORKS',
  dashboardData: 'FETCH_NETWORK_DASHBOARD',
};

export const all = () => {
  return Networks.all();
};

export const save = network => {
  return Networks.post(network);
};

export const update = network => {
  return Networks.update(network.id, network);
};

export const getDashboardData = id => {
  return Networks.dashboardData(id);
};

export const activate = id => {
  return Networks.update(id, { active: true });
};

export const deactivate = id => {
  return Networks.update(id, { active: false });
};
