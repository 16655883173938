import { Airtable } from 'api';

export const airtable2Blueprint = () => {
  return Airtable.airtable2Blueprint();
};

export const getCron = () => {
  return Airtable.getCron();
}

export const postCron = (cronState) => {
  return Airtable.postCron(cronState);
}
