import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { PledgesTable, EmptyFallback } from 'components';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import {
  Card,
  Button,
  Divider,
  CardHeader,
  CardContent,
  CardActions,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
    minWidth: 400,
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  status: {
    marginRight: theme.spacing(1),
  },
  actions: {
    justifyContent: 'flex-end',
  },
}));

const LatestPledges = props => {
  const classes = useStyles();
  const history = useHistory();
  const { className, pledges, subHeader, ...rest } = props;

  const handleNavigationToPledges = event => {
    event.preventDefault();
    history.push('/pledges');
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title="Latest Pledges" subheader={subHeader} />
      <Divider />
      {pledges?.length === 0 ? (
        <EmptyFallback heading="No pledges given yet." />
      ) : (
        <>
          <CardContent className={classes.content}>
            <PerfectScrollbar>
              <PledgesTable pledges={pledges.slice(0, 3)} />
            </PerfectScrollbar>
          </CardContent>
          <Divider />
          <CardActions className={classes.actions}>
            <Button
              color="primary"
              onClick={handleNavigationToPledges}
              size="small"
              variant="text"
            >
              View all <ArrowRightIcon />
            </Button>
          </CardActions>
        </>
      )}
    </Card>
  );
};

LatestPledges.propTypes = {
  className: PropTypes.string,
  pledges: PropTypes.array,
  subHeader: PropTypes.string,
};

export default LatestPledges;
