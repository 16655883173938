import React, { useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import Chart from 'chart.js';

const useStyles = makeStyles(() => ({
  container: {},
  chart: {
    width: 'auto',
    height: 'auto',
  },
}));

/* const dynamicColors = (h, val) => {
  var color;
  if (window.localStorage.getItem(val)) {
    color = window.localStorage.getItem(val);
    return color;
  }
  let f = (n, k = (n + h * 12) % 12) =>
    0.5 - 0.5 * Math.max(Math.min(k - 3, 9 - k, 1), -1);
  let rgb2hex = (r, g, b) =>
    '#' +
    [r, g, b]
      .map(x =>
        Math.round(x * 255)
          .toString(16)
          .padStart(2, 0)
      )
      .join('');

  color = rgb2hex(f(0), f(8), f(4));

  window.localStorage.setItem(val, color);

  return color;
}; */

const PieChart = props => {
  const classes = useStyles();
  const { datalist } = props;
  const ctx = useRef();

  useEffect(() => {
    let mounted = true;

    if (mounted && typeof datalist !== 'undefined') {
      var labels = [];
      var values = [];
      // var colors = [];

      for (var i = 0; i < datalist.length; i++) {
        labels.push(datalist[i].label);
        values.push(datalist[i].value);
        // colors.push(dynamicColors(i / datalist.length, labels[i]));
      }

      // Default Chart Values
      Chart.defaults.global.defaultFontColor = '#000000';
      Chart.defaults.global.defaultFontStyle = 'bold';
      Chart.defaults.pie.tooltips.callbacks.label = (item, items) => {
        var index = item.index;

        var label = `${items.labels[index]}: $${items.datasets[0].data[index]}`;
        return label;
      };

      new Chart(ctx.current, {
        type: 'pie',
        data: {
          labels: labels,
          datasets: [
            {
              data: values,
              backgroundColor: ['#EC407A', '#3f51b5', '#1e88e5'],
              borderColor: ['#EC407A', '#3f51b5', '#1e88e5'],
              borderWidth: 1,
            },
          ],
        },
        options: {
          plugins: {
            datalabels: {
              formatter: (value, ctx) => {
                let sum = 0;
                let dataArr = ctx.chart.data.datasets[0].data;
                dataArr.map(data => {
                  sum += data;
                  return sum;
                });
                let percentage = ((value * 100) / sum).toFixed(2) + '%';
                return percentage;
              },
              color: '#fff',
            },
          },
        },
      });
    }

    return () => {
      mounted = false;
    };
  }, [datalist]);

  return (
    <div className={classes.container}>
      <canvas ref={ctx} className={classes.chart} />
    </div>
  );
};

PieChart.propTypes = {
  datalist: PropTypes.array,
};
export default PieChart;
