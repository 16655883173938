import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { LinearProgress, Tooltip } from '@material-ui/core';
import { CapitalBreakdownModal } from 'components';
import { loadCookie } from 'common/cookies';

const useStyles = makeStyles(theme => ({
  capitalTypeBreakdown: {
    marginTop: theme.spacing(1),
  },
}));

const BudgetCell = props => {
  const classes = useStyles();
  const { user } = loadCookie();
  const { project } = props;

  const hideFinancials = project.clusters.reduce(
    (m, c) => m || c.hideFinancialsProjectLead || c.hideFinancialsExtracluster,
    false
  );

  const totalBudgetGoal = project?.capitalTypesProjects
    ?.map(({ budgetGoal }) => Number(budgetGoal))
    ?.reduce((acc, curr) => acc + curr, 0);

  const totalBudgetReached = project?.capitalTypesProjects
    ?.map(({ budgetReached }) => Number(budgetReached))
    ?.reduce((acc, curr) => acc + curr, 0);

  const amountPledged = project?.funds?.reduce(
    (prev, curr) => (prev += Number(curr?.amount)),
    0
  );

  const progress =
    ((totalBudgetReached + amountPledged) / totalBudgetGoal) * 100;

  const capitalTypeBreakdown = project?.capitalTypesProjects?.map(
    capitalTypeEntry => {
      const {
        capitalTypeId,
        budgetReached,
        budgetGoal,
        capitalType: { name },
      } = capitalTypeEntry;

      const totalCapitalTypeFunds = project?.funds
        .filter(({ pledge }) => pledge.capitalTypeId === capitalTypeId)
        .reduce((acc, curr) => acc + Number(curr?.amount), 0);

      const breakdown = {
        name,
        budgetGoal,
        budgetReached,
        totalCapitalTypeFunds,
      };
      return breakdown;
    }
  );

  // const value =
  //   hideFinancials && project.createdBy !== user.id ? (
  //     <Tooltip title={'fpo: obfuscated values tooltip message'}>
  //       <span>
  //         {!totalBudgetGoal || totalBudgetGoal === 0
  //           ? 'N/A'
  //           : (totalBudgetReached / totalBudgetGoal || 0) * 100 + '%'}{' '}
  //       </span>
  //     </Tooltip>
  //   ) : (
  //     new Intl.NumberFormat('USA', {
  //       style: 'currency',
  //       currency: 'USD',
  //     }).format(totalBudgetGoal || 0)
  //   );
  let value;
  if (hideFinancials && project.createdBy !== user.id) {
    let innerValue;
    if (!totalBudgetGoal || totalBudgetGoal === 0) {
      innerValue = 'N/A';
    } else {
      innerValue = Math.round(
        (totalBudgetReached / totalBudgetGoal || 0) * 100 * 100
      );
      innerValue /= 100;
      innerValue += '%';
    }
    value = (
      <Tooltip title={'fpo: obfuscated values tooltip message'}>
        <span>{innerValue}</span>
      </Tooltip>
    );
  } else {
    value = new Intl.NumberFormat('USA', {
      style: 'currency',
      currency: 'USD',
    }).format(totalBudgetGoal || 0);
  }

  const tooltipValue =
    hideFinancials && project.createdBy !== user.id
      ? 'fpo: obfuscated values tooltip message'
      : new Intl.NumberFormat('USA', {
          style: 'currency',
          currency: 'USD',
        }).format(totalBudgetGoal || 0);

  return (
    <>
      {value}
      <Tooltip title={tooltipValue} aria-label="progress">
        <LinearProgress
          className={classes.progress}
          value={progress <= 100 ? progress : 100}
          variant="determinate"
        />
      </Tooltip>
      {hideFinancials && project.createdBy !== user.id ? null : (
        <div className={classes.capitalTypeBreakdown}>
          <CapitalBreakdownModal capitalTypeBreakdown={capitalTypeBreakdown} />
        </div>
      )}
    </>
  );
};

export default BudgetCell;
