import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Typography, Avatar } from '@material-ui/core';

import { loadCookie } from 'common/cookies';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
  },
  avatar: {
    width: 60,
    height: 60,
    backgroundColor: theme.palette.primary.main,
  },
  name: {},
}));

const Profile = props => {
  const { className, ...rest } = props;
  const { user } = loadCookie();
  const classes = useStyles();

  const roles = [];
  let entityName = '';

  if (user?.isFundUser) {
    roles.push('Funder');
    entityName = user?.funder?.orgName;
  }

  if (user?.isAdminUser) {
    roles.push('Administrator');
  }

  if (user?.isNetworkUser) {
    roles.push('Network');
    entityName = user?.network?.orgName;
  }

  if (user?.isOrganizationUser) {
    roles.push('Organization');
    entityName = user?.organization?.orgName;
  }

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Avatar
        style={{
          backgroundColor: '#3F51B5',
          width: '60px',
          height: '60px',
          marginBottom: '0.3rem',
        }}
      >{`${user?.firstName.charAt(0)}${user?.lastName.charAt(0)}`}</Avatar>
      <Typography className={classes.name} variant="h4">
        {user?.firstName} {user?.lastName}
      </Typography>
      <Typography variant="body2" style={{ fontWeight: 900 }}>
        {entityName}
      </Typography>
      <Typography id="user-type" variant="body2">
        {roles?.join(', ')}
      </Typography>
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string,
};

export default Profile;
