import axios from './axios';
import { loadCookie } from 'common/cookies';

// We apply to headers to each instance
// in order to prevent use of a stale header
// TODO: Figure out how to do his more efficiently
export default function Resource(uri, hasDashboard = false) {
  this.uri = uri;

  this.all = (params = {}) => {
    const cookie = loadCookie();
    return axios.get(this.uri, {
      params: params,
      headers: { Authorization: 'Bearer ' + cookie?.token },
    });
  };

  this.find = id => {
    const cookie = loadCookie();
    return axios.get(`${this.uri}/${id}`, {
      headers: { Authorization: 'Bearer ' + cookie?.token },
    });
  };

  this.post = (data, path) => {
    const cookie = loadCookie();
    let _uri = '';
    if (path) {
      _uri = uri.concat(`${path}`);
    } else {
      _uri = this.uri;
    }
    return axios.post(_uri, data, {
      headers: { Authorization: 'Bearer ' + cookie?.token },
    });
  };

  this.update = (id, data, endURI) => {
    const cookie = loadCookie();
    let uri = `${this.uri}/${id}`;

    if (endURI) {
      uri = uri.concat(`${endURI}`);
    }
    return axios.put(uri, data, {
      headers: { Authorization: 'Bearer ' + cookie?.token },
    });
  };

  this.patch = (id, data) => {
    const cookie = loadCookie();
    return axios.patch(`${this.uri}/${id}`, data, {
      headers: { Authorization: 'Bearer ' + cookie?.token },
    });
  };

  this.remove = (id, data) => {
    const cookie = loadCookie();
    return axios.delete(`${this.uri}/${id}`, {
      headers: { Authorization: 'Bearer ' + cookie?.token },
    });
  };

  if (hasDashboard) {
    this.dashboardData = (id = null) => {
      const cookie = loadCookie();
      let updatedUri = '';
      if (id) {
        updatedUri = `${this.uri}/dashboard/${id}`;
      } else {
        updatedUri = `${this.uri}/dashboard`;
      }

      return axios.get(updatedUri, {
        headers: { Authorization: 'Bearer ' + cookie?.token },
      });
    };
  }
}
