import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';
import { PageTopBar } from 'components';
import { loadCookie } from 'common/cookies';
import AdminDashboard from './AdminDashboard';
import NetworkDashboard from './NetworkDashboard';
import FundDashboard from './FundDashboard';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
}));

const Dashboard = () => {
  const classes = useStyles();

  const { user } = loadCookie();
  const isFunder = user?.isFundUser;
  const isAdmin = user?.isAdminUser;
  const isNetworkUser = user?.isNetworkUser;
  const isActive = user?.active;

  return (
    <div className={classes.root}>
      <PageTopBar PageTitle="Dashboard" Widgets={[]} />
      {isActive ? (
        isAdmin ? (
          <AdminDashboard />
        ) : isNetworkUser ? (
          <NetworkDashboard />
        ) : isFunder ? (
          <FundDashboard />
        ) : (
          <Grid
            container
            justify="center"
            spacing={4}
            style={{
              minHeight: '500px',
              flexDirection: 'column',
              alignContent: 'center',
            }}
          >
            <ErrorOutline style={{ fontSize: '48px', margin: '0 auto' }} />
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="h2"
            >
              Contact the Administrator to activate your user role.
            </Typography>
          </Grid>
        )
      ) : (
        <Grid
          container
          justify="center"
          spacing={4}
          style={{
            minHeight: '500px',
            flexDirection: 'column',
            alignContent: 'center',
          }}
        >
          <ErrorOutline style={{ fontSize: '48px', margin: '0 auto' }} />
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
            variant="h2"
          >
            Contact the Administrator to activate your account.
          </Typography>
        </Grid>
      )}
    </div>
  );
};

export default Dashboard;
