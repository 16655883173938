import React from 'react';
import { useHistory } from 'react-router-dom';
import { GenericToolbar } from 'components';
import { ControlPoint } from '@material-ui/icons';

const Toolbar = () => {
  const history = useHistory();

  const handleNavigateToAddProject = event => {
    event.preventDefault();
    history.push('/projects/create');
  };

  return (
    <GenericToolbar
      title="Add New Project"
      action={handleNavigateToAddProject}
      icon={<ControlPoint color="primary" />}
    />
  );
};

export default Toolbar;
