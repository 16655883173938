import axios from 'axios';
import { useState } from 'react';

const useCloudinaryUpload = () => {
  const [isLoading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  let data;
  const [error, setError] = useState({});

  const url = `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_NAME}/image/upload`;

  const getData = () => {
    return data;
  };

  const upload = async (preset, file, name) => {
    const fd = new FormData();
    fd.append('file', file);
    fd.append('upload_preset', preset);

    if (name !== null && typeof name !== 'undefined') {
      fd.append('public_id', name);
    }

    setLoading(true);
    await axios({
      url: url,
      method: 'POST',
      header: {
        'Content-Type': 'application/x-www-form-urelencoded',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin',
        'Access-Control-Allow-Credentials': true,
      },
      data: fd,
      onUploadProgress: progressEvent => {
        setProgress(() =>
          parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          )
        );
      },
    })
      .then(result => {
        data = result.data;
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  };

  return [upload, isLoading, progress, getData, error];
};

export default useCloudinaryUpload;
