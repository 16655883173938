import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import {
  CircularProgress,
  Grid,
  Link,
  Button,
  TextField,
  Typography,
} from '@material-ui/core';

import { useMutation } from 'react-query';
import { loginFetcher } from './fetchers';
import { saveCookie, loadCookie } from 'common/cookies';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64,
    },
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128,
    },
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
  },
  content: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(15),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(5),
      paddingTop: theme.spacing(15),
    },
  },
  contentBody: {
    display: 'flex',
    flexBasis: 500,
    flexDirection: 'column',
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },
  utility: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const SignIn = props => {
  const { history } = props;
  const classes = useStyles();
  const [error, setError] = useState('');
  const [loginUser, { status }] = useMutation(loginFetcher);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const { executeRecaptcha } = useGoogleReCaptcha();

  // When new users registers, the name of the
  // user is passed as state in order to display
  // a welcome message
  const name = props?.location?.state?.name;

  const fromForcedLogout = props?.location?.state?.fromForcedLogout ?? false;

  // When users have updated their password through the forget password workflow
  const updatePassword = props?.location?.state?.updatePassword;

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSignIn = async event => {
    event.preventDefault();
    formState.values.recaptcha = await executeRecaptcha('login_page');
    setFormSubmitting(() => true);
    await loginUser(formState.values, {
      onError: error => {
        setError(error?.data?.error?.message);
        setFormState(formState => ({
          isValid: true,
          errors: {},
          touched: {},
          values: {
            ...formState.values,
            password: '',
          },
        }));
        setFormSubmitting(() => false);
        setError('User not found, incorrect email or password.');
      },
      onSuccess: ({ data }) => {
        saveCookie(data.accessToken);
        const { user } = loadCookie();
        if (
          props.location.state?.redirect &&
          props.location.state?.redirectLink
        ) {
          props.location.state.redirect = false;
          history.push(props.location.state?.redirectLink);
        } else {
          user.isOrganizationUser
            ? history.push('/projects')
            : history.push('/dashboard');
          setFormSubmitting(() => true);
          setError('');
        }
      },
    });
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const passwordResetPrompt = () => {
    const severity = updatePassword.updated ? 'success' : 'error';
    return (
      <Alert severity={severity} variant="standard">
        {updatePassword.message}
      </Alert>
    );
  };

  return (
    <div className={classes.root}>
      <Grid className={classes.content} item xs={12}>
        <div className={classes.contentBody}>
          {fromForcedLogout === true ? (
            <Alert severity="info">
              Your Blueprint session has expired. Please login again to
              continue.
            </Alert>
          ) : null}
          <form className={classes.form} onSubmit={handleSignIn}>
            <Typography variant="h2">Welcome to Blueprint</Typography>
            <Typography color="textSecondary" gutterBottom>
              Sign in to get started!
            </Typography>
            {!error && name && (
              <Alert severity="success" variant="standard">
                Welcome {name}! Sign in to get started
              </Alert>
            )}
            {!error && updatePassword ? passwordResetPrompt() : null}
            {error && (
              <Alert id="flash-error" severity="error" variant="outlined">
                {error}
              </Alert>
            )}
            <TextField
              className={classes.textField}
              error={hasError('email')}
              fullWidth
              helperText={hasError('email') ? formState.errors.email[0] : null}
              label="Email address"
              name="email"
              onChange={handleChange}
              type="text"
              value={formState.values.email || ''}
              variant="outlined"
            />
            <TextField
              className={classes.textField}
              error={hasError('password')}
              fullWidth
              helperText={
                hasError('password') ? formState.errors.password[0] : null
              }
              label="Password"
              name="password"
              onChange={handleChange}
              type="password"
              value={formState.values.password || ''}
              variant="outlined"
            />
            <div>
              <Button
                className={classes.signInButton}
                color="primary"
                disabled={
                  !formState.isValid ||
                  status.loading === 'loading' ||
                  formSubmitting
                }
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                {formSubmitting ? (
                  <CircularProgress size={32} />
                ) : (
                  'Sign in now'
                )}
              </Button>
            </div>
            <div className={classes.utility}>
              <Typography variant="body1" color="textSecondary">
                Don&apos;t have an account?&nbsp;
                <Link component={RouterLink} to="/sign-up" variant="h6">
                  Sign up
                </Link>
              </Typography>
              <Typography variant="body1">
                <Link component={RouterLink} to="/forget-password" variant="h6">
                  Forgot Password?
                </Link>
              </Typography>
            </div>
          </form>
        </div>
      </Grid>
    </div>
  );
};

SignIn.propTypes = {
  history: PropTypes.object,
  location: PropTypes.shape({
    state: PropTypes.shape({
      name: PropTypes.string,
      updatePassword: PropTypes.string,
      fromForcedLogout: PropTypes.bool,
      redirect: PropTypes.bool,
      redirectLink: PropTypes.string,
    }),
  }),
};

export default withRouter(SignIn);
