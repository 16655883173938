import React, { useState } from 'react';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';
import { ShareProfileModal } from './Components';

const StyledButton = withStyles(() => ({
  root: {
    color: 'white',
    background: '#FF1D89',
    opacity: 0.54,
    borderRadius: '50px',
    '&:hover': {
      opacity: 1,
      background: '#FF1D89',
    },
  },
}))(Button);

const ShareProfileButton = props => {
  const { profileLink, className } = props;

  const [open, setOpen] = useState(false);

  return (
    <span>
      <StyledButton
        className={className}
        onClick={() => setOpen(true)}
        variant="contained"
        endIcon={<ShareIcon />}
      >
        SHARE
      </StyledButton>
      <ShareProfileModal
        open={open}
        handleClose={() => setOpen(() => false)}
        profileLink={profileLink}
      />
    </span>
  );
};

ShareProfileButton.propTypes = {
  profileLink: PropTypes.string,
  className: PropTypes.string,
};

export default ShareProfileButton;
