import React from 'react';
import { RouteWithLayout } from 'components';
import { Minimal as MinimalLayout } from 'layouts';

const NotFoundPage = React.lazy(() => import('./NotFound'));

const NotFoundRoute = () => {
  return (
    <>
      <RouteWithLayout
        component={NotFoundPage}
        exact
        isPrivate={false}
        layout={MinimalLayout}
        path="/not-found"
      />
    </>
  );
};

export default NotFoundRoute;
