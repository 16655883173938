import React from 'react';
import { Main as MainLayout } from 'layouts';
import { RouteWithLayout } from 'components';

const PledgesPage = React.lazy(() => import('./Pledges'));
const CreatePledgePage = React.lazy(() => import('./views/CreatePledge')); // prettier-ignore
const AssignPledgePage = React.lazy(() => import('./views/AssignPledge')); // prettier-ignore
const PledgesRoutes = {
  list: (
    <RouteWithLayout
      component={PledgesPage}
      exact
      layout={MainLayout}
      path="/pledges"
      isPrivate
      requireActive
      userAccess={['net', 'fund', 'admin']}
      redirect={'/dashboard'}
      key="list-pledges"
    />
  ),
  create: (
    <RouteWithLayout
      component={CreatePledgePage}
      exact
      layout={MainLayout}
      path="/pledges/create/:id"
      requireActive
      isPrivate
      userAccess={['fund']}
      redirect={'/dashboard'}
      key="create-pledges"
    />
  ),
  assign: (
    <RouteWithLayout
      component={AssignPledgePage}
      exact
      layout={MainLayout}
      path="/pledges/assign/:id"
      isPrivate
      requireActive
      userAccess={['admin']}
      redirect={'/dashboard'}
      key="assign-pledges"
    />
  ),
};

export default PledgesRoutes;
