import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { formatDistance } from 'date-fns';
import { makeStyles } from '@material-ui/styles';
import { loadCookie } from 'common/cookies';

import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  LinearProgress,
  Tooltip,
} from '@material-ui/core';

import { Toolbar } from 'views/Projects/components';
import { TableLoader, EmptyFallback, CapitalBreakdownModal } from 'components';

const useStyles = makeStyles(theme => ({
  root: {},
  actions: {
    justifyContent: 'flex-end',
  },
  capitalTypeBreakdown: {
    marginTop: theme.spacing(1),
  },
}));

const ProjectList = props => {
  const classes = useStyles();
  const { user } = loadCookie();
  const { projects, isLoading } = props;

  if (!isLoading && isEmpty(projects)) {
    return !user.isOrganizationUser ? (
      <div className={classes.root}>
        <EmptyFallback action={null} heading="No projects created yet." />
      </div>
    ) : (
      <div className={classes.root}>
        <EmptyFallback
          action={<Toolbar />}
          heading="Create a project to get started!"
        />
      </div>
    );
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Project</TableCell>
          <TableCell>Organization</TableCell>
          <TableCell>Description</TableCell>
          <TableCell>Website</TableCell>
          <TableCell>Donation Page</TableCell>
          <TableCell>Amount Raised</TableCell>
          <TableCell>Pledged</TableCell>
          <TableCell>Budget</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {isLoading && <TableLoader colSpan={5} />}
        {!isLoading &&
          projects &&
          projects?.map(project => {
            const totalBudgetGoal = project?.capitalTypesProjects
              .map(({ budgetGoal }) => Number(budgetGoal))
              .reduce((acc, curr) => acc + curr, 0);

            const totalBudgetReached = project?.capitalTypesProjects
              .map(({ budgetReached }) => Number(budgetReached))
              .reduce((acc, curr) => acc + curr, 0);

            const amountPledged = project?.funds?.reduce(
              (prev, curr) => (prev += Number(curr?.amount)),
              0
            );

            const progress =
              ((totalBudgetReached + amountPledged) / totalBudgetGoal) * 100;

            const capitalTypeBreakdown = project?.capitalTypesProjects.map(
              capitalTypeEntry => {
                const {
                  capitalTypeId,
                  budgetReached,
                  budgetGoal,
                  capitalType: { name },
                } = capitalTypeEntry;

                const totalCapitalTypeFunds = project?.funds
                  .filter(
                    ({ pledge }) => pledge.capitalTypeId === capitalTypeId
                  )
                  .reduce((acc, curr) => acc + Number(curr?.amount), 0);

                const breakdown = {
                  name,
                  budgetGoal,
                  budgetReached,
                  totalCapitalTypeFunds,
                };
                return breakdown;
              }
            );

            return (
              <TableRow
                className={classes.row}
                height={57}
                hover
                id={project.id}
                key={project.id}
              >
                <TableCell>
                  <div>{project.name}</div>
                  <Typography variant="subtitle2">
                    Created{' '}
                    {formatDistance(new Date(project.createdAt), new Date(), {
                      addSuffix: true,
                    })}
                  </Typography>
                </TableCell>
                <TableCell>{project.organization?.orgName}</TableCell>
                <TableCell>{project?.description}</TableCell>
                <TableCell>{project?.website}</TableCell>
                <TableCell>{project?.donationPage}</TableCell>
                <TableCell>
                  {new Intl.NumberFormat('USA', {
                    style: 'currency',
                    currency: 'USD',
                  }).format(totalBudgetReached)}
                </TableCell>
                <TableCell>
                  {new Intl.NumberFormat('USA', {
                    style: 'currency',
                    currency: 'USD',
                  }).format(amountPledged)}
                </TableCell>
                <TableCell>
                  {new Intl.NumberFormat('USA', {
                    style: 'currency',
                    currency: 'USD',
                  }).format(totalBudgetGoal)}
                  <Tooltip
                    title={`${new Intl.NumberFormat('USA', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(totalBudgetReached + amountPledged)}`}
                    aria-label="progress"
                  >
                    <LinearProgress
                      className={classes.progress}
                      value={progress <= 100 ? progress : 100}
                      variant="determinate"
                    />
                  </Tooltip>
                  <div className={classes.capitalTypeBreakdown}>
                    <CapitalBreakdownModal
                      capitalTypeBreakdown={capitalTypeBreakdown}
                    />
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
      </TableBody>
    </Table>
  );
};

ProjectList.propTypes = {
  className: PropTypes.string,
  error: PropTypes.object,
  projects: PropTypes.array,
  isLoading: PropTypes.bool,
};

ProjectList.defaultProps = {
  isLoading: false,
};

export default ProjectList;
