import axios from './axios';
import { loadCookie } from 'common/cookies';

export default function AirtableResource() {
  this.uri = '/api/airtable'

  this.airtable2Blueprint = async () => {
    const cookie = loadCookie();
    return axios.get(`${this.uri}/airtable-blueprint`, {
      headers: { Authorization: 'Bearer ' + cookie?.token }
    }).catch((err) => {
      console.log("airtable2Blueprint Error: ", err)
    })
  }

  this.getCron = async () => {
    const cookie = loadCookie();
    return axios.get(`${this.uri}/airtable-cron`, {
      headers: { Authorization: 'Bearer ' + cookie?.token }
    }).catch((err) => {
      console.log("airtable cron get error: ", err)
    })
  }

  this.postCron = async (cronState) => {
    const cookie = loadCookie();
    return axios.post(`${this.uri}/airtable-cron`, cronState, {
      headers: { Authorization: 'Bearer ' + cookie?.token },
    });
  }

}
