import React, { forwardRef, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Profile } from '../index';
import { deleteCookie, loadCookie } from 'common/cookies';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  List,
  ListItem,
  ListItemIcon,
  Button,
  Fab,
  ListItemText,
  Slide,
  useMediaQuery,
} from '@material-ui/core';

import { Close, AccountBox } from '@material-ui/icons';

const ListItemLink = props => {
  return <ListItem button component="a" {...props} />;
};

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});
const useStyles = makeStyles(theme => ({
  menuFAB: {
    margin: 0,
    position: 'sticky',
    top: '95%',
    left: '100%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

const MobileMenuPopup = props => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = loadCookie();
  const { pages, open, handleClose } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'), {
    defaultMatches: true,
  });

  useEffect(() => {
    if (isMobile) {
      handleClose();
    }
  }, [isMobile, handleClose]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}>
      <DialogTitle align="right">
        <Button
          onClick={event => {
            event.preventDefault();
            if (user?.isFundUser) {
              history.push('/settings');
            }
          }}
          color="primary">
          Account Settings
        </Button>
      </DialogTitle>
      <DialogContent>
        <Profile />
        <List component="nav">
          {pages?.map((ele, index) => {
            return (
              <ListItemLink
                key={'Nav-link-mobile-' + index}
                id={'Nav-link-mobile-' + index}
                href={ele?.href}>
                <ListItemIcon>{ele?.icon}</ListItemIcon>
                <ListItemText primary={ele.title} />
              </ListItemLink>
            );
          })}
          <ListItemLink
            id={'Nav-link-mobile-Profile'}
            href={`/profile/user/funder/${user?.id}`}>
            <ListItemIcon>
              <AccountBox />
            </ListItemIcon>
            <ListItemText primary="Profile" />
          </ListItemLink>
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={event => {
            event.preventDefault();
            deleteCookie();
            history.push('/sign-in');
          }}
          fullWidth>
          Logout
        </Button>
      </DialogActions>
      <Fab
        onClick={() => handleClose()}
        color="primary"
        className={classes.menuFAB}>
        <Close />
      </Fab>
    </Dialog>
  );
};

MobileMenuPopup.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  pages: PropTypes.array,
};
export default MobileMenuPopup;
