import { Clusters, ClustersByManageCluster, FindClustersByUser } from 'api';

// Keys are used to allow us to assign the same key
// for the same type of request so data can be cached
// with react-query
export const keys = {
  fetch: 'FETCH_CLUSTERS',
};

export const all = () => {
  return Clusters.all();
};

export const allByCluster = id => {
  return Clusters.find(id);
};

export const allByManageClusters = () => {
  return ClustersByManageCluster.all();
};

export const save = cluster => {
  return Clusters.post(cluster);
};

export const update = cluster => {
  return Clusters.update(cluster?.id, cluster);
};

export const remove = id => {
  return Clusters.delete(id);
};

export const addProject = ({ clusterId, projectId }) => {
  return Clusters.update(clusterId, { projectId }, '/addProject');
};

export const removeProject = ({ clusterId, projectId }) => {
  return Clusters.update(clusterId, { projectId }, '/removeProject');
};

export const findAllByUser = () => {
  return FindClustersByUser.all();
};
