import Resource from './Resource';
import AirtableResource from './AirtableResource';

export const Users = new Resource('/api/users');

// ... Pledges
export const Pledges = new Resource('/api/pledges');
export const FindPledgesByNetwork = new Resource('/api/pledges/network');
export const FindPledgesByFunder = new Resource('/api/pledges/funder');

export const Funders = new Resource('/api/funders', true);
export const Clusters = new Resource('/api/clusters');
export const FindClustersByUser = new Resource('/api/clusters/findAllByUser');
export const ClustersByManageCluster = new Resource(
  '/api/clusters/manageCluster'
);
export const Login = new Resource('/api/auth/login');
export const Networks = new Resource('/api/networks', true);
export const Projects = new Resource('/api/projects');
export const ProjectsByOrganization = new Resource(
  '/api/projects/organization'
);
export const ProjectsByMangeProject = new Resource(
  'api/projects/manageProject'
);
export const ProjectsByCluster = new Resource('/api/projects/cluster');
export const Register = new Resource('/api/auth/register');
export const OrgProfiles = new Resource('/api/org-profiles');
export const Organization = new Resource('/api/organizations', true);
export const OrganizationTypes = new Resource('/api/org-types');
export const ForgetPassword = new Resource('/api/auth/forget-password');
export const TokenExists = new Resource('/api/auth/token-exists');
export const ResetPasswordWithToken = new Resource(
  '/api/auth/resetPasswordWithToken'
);
export const CapitalTypes = new Resource('/api/capital-types');
export const ClusterProfile = new Resource('/api/profile/cluster');
export const ProjectProfile = new Resource('/api/profile/project');
export const ClusterProjectsProfile = new Resource(
  '/api/profile/project/cluster'
);
export const ImageBucket = new Resource('/api/image');
export const Roles = new Resource('/api/roles');
export const FunderUserProfile = new Resource('/api/profile/funderUser');
export const FunderOrganizationProfile = new Resource(
  '/api/profile/funderOrganization'
);
export const Demographics = new Resource('/api/demographics');
export const DemographicsMeta = new Resource('/api/demogrpahics/meta');
export const Areas = new Resource('/api/areas');
export const AreasMeta = new Resource('/api/areas/meta');
export const Tags = new Resource('/api/tags');

export const Airtable = new AirtableResource();
