import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Link,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import { PieChart } from 'components';

const useStyles = makeStyles(() => ({
  pledgeBreakdownLink: {
    fontSize: '12px',
  },
}));

const PledgeCapitalTypeBreakdownModal = props => {
  const classes = useStyles();
  const { pledgeCapitalTypeBreakdown } = props;
  const [pledgesBreakdownModal, setPledgesBreakdownModal] = useState(false);

  return (
    <>
      <Dialog
        open={pledgesBreakdownModal}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={'xs'}
      >
        <DialogTitle id="form-dialog-title">
          Pledges Capital Type Breakdown
        </DialogTitle>
        <DialogContent>
          {isEmpty(pledgeCapitalTypeBreakdown) ? (
            <Typography variant="h6">No Data</Typography>
          ) : (
            <PieChart datalist={pledgeCapitalTypeBreakdown} />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setPledgesBreakdownModal(false)}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Link
        component="button"
        className={classes.pledgeBreakdownLink}
        onClick={event => {
          event.preventDefault();
          setPledgesBreakdownModal(true);
        }}
      >
        View Pledges Breakdown...
      </Link>
    </>
  );
};

PledgeCapitalTypeBreakdownModal.propTypes = {
  className: PropTypes.string,
  pledgeCapitalTypeBreakdown: PropTypes.object,
};

export default PledgeCapitalTypeBreakdownModal;
