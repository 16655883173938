import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  Slide,
} from '@material-ui/core';

const TransitionRef = (props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
};

const Transition = forwardRef(TransitionRef);
const ConfirmationModal = props => {
  const {
    openConfirmationModal,
    setOpenConfirmationModal,
    modalTitle,
    modalContent,
    confirmationStatus,
    handleConfirm,
  } = props;

  return (
    <Dialog
      open={openConfirmationModal}
      TransitionComponent={Transition}
      onClose={() => {
        setOpenConfirmationModal(() => false);
      }}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{modalTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {modalContent}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setOpenConfirmationModal(() => false)}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          disabled={confirmationStatus === 'loading'}
          onClick={handleConfirm}
          color="primary"
        >
          {confirmationStatus === 'loading' ? (
            <CircularProgress size={32} />
          ) : (
            'Confirm'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationModal.propTypes = {
  props: PropTypes.object,
  openConfirmationModal: PropTypes.bool,
  setOpenConfirmationModal: PropTypes.func,
  modalTitle: PropTypes.string,
  modalContent: PropTypes.object,
  confirmationStatus: PropTypes.string,
  handleConfirm: PropTypes.func,
};
export default ConfirmationModal;
