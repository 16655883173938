import React from 'react';
import { Main as MainLayout, Minimal as MinimalLayout } from 'layouts';
import { RouteWithLayout } from 'components';
import { loadCookie } from 'common/cookies';

const { user } = loadCookie();
const funderUserProfile = React.lazy(() => import('./views/FunderProfile'));

const ProfilePage = React.lazy(() => import('./Profile'));

const UserProfiles = {
  detail: (
    <RouteWithLayout
      component={ProfilePage}
      exact
      layout={MainLayout}
      path="/profile"
      /* requireActive={false} */
      userAccess={['org', 'net', 'admin', 'manageProject', 'manageCluster']}
      redirect={'/dashboard'}
      key="user-profile"
    />
  ),
  funder: (
    <RouteWithLayout
      component={funderUserProfile}
      exact
      layout={
        user?.isFundUser || user?.isAdminUser ? MainLayout : MinimalLayout
      }
      path="/profile/user/funder/:id"
      isPrivate={false}
      key="user-profile-funder"
    />
  ),
};

export default UserProfiles;
