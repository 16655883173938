import React from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useHistory } from 'react-router-dom';
import {
  Grid,
  Card,
  CardContent,
  Divider,
  Typography,
  LinearProgress,
  CardActionArea,
} from '@material-ui/core';
import Image from 'material-ui-image';
import { makeStyles } from '@material-ui/styles';
import { ProfileCard, ProfileCardHeader } from './ProfilePageComponents';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100%',
    height: 534,
    paddingBottom: theme.spacing(10),
  },
  container: {
    width: '100%',
  },
  fallbackImage: {
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    height: '100%',
    padding: '2em',
    objectPostion: 'center 100%',
  },
  cardTitle: {
    height: '60px',
  },
  cardDescription: {
    height: '80px',
  },
}));

const ProfileCollections = ({ collections, collectionType }) => {
  const classes = useStyles();
  const collectionNavigate =
    collectionType === 'cluster' ? 'project' : 'cluster';

  const history = useHistory();
  const renderCards = collections?.map(
    ({ id, name, description, current, goal, profileImage, primaryAreas }) => (
      <Grid
        key={id}
        item
        xs={12}
        sm={6}
        md={4}
        lg={3}
        style={{ display: 'flex', width: '100%' }}>
        <Card style={{ width: '100%' }}>
          <CardActionArea
            onClick={() =>
              history.push(`/profile/${collectionNavigate}/${id}`)
            }>
            <Grid container direction="column">
              <Grid container item xs>
                <Image
                  style={{
                    padding: '0',
                    width: '100%',
                    height: 'auto',
                    aspectRatio: '1',
                  }}
                  imageStyle={{
                    width: '100%',
                    height: 'auto',
                    aspectRatio: '1',
                    position: 'inherit',
                  }}
                  errorIcon={
                    <img
                      className={classes.fallbackImage}
                      src={'/images/logos/logo--white.svg'}
                      alt="logo"
                    />
                  }
                  src={profileImage ?? 'null'}
                />
              </Grid>
              <Grid item xs>
                <CardContent>
                  <Grid
                    container
                    item
                    xs={12}
                    spacing={3}
                    justifyContent="space-evenly"
                    direction="column">
                    <Grid item className={classes.cardTitle}>
                      <PerfectScrollbar>
                        <Typography variant="h5">{name}</Typography>
                      </PerfectScrollbar>
                    </Grid>
                    <Grid item className={classes.cardDescription}>
                      <PerfectScrollbar>
                        <Typography variant="body2">{description}</Typography>
                      </PerfectScrollbar>
                    </Grid>
                    <Grid item>
                      <LinearProgress
                        variant="determinate"
                        value={(current / goal) * 100}
                        valueBuffer={100}
                      />
                    </Grid>
                    <Grid
                      container
                      alignItems="center"
                      justify="space-between"
                      direction="row"
                      item>
                      <Grid xs={'auto'} item>
                        <Typography variant="h5">Areas of Work</Typography>
                        {primaryAreas.length >= 1 ? (
                          <Typography variant="overline">
                            {primaryAreas[0]}{' '}
                            {primaryAreas.length > 1
                              ? `
                            & ${primaryAreas?.length - 1} more
                              `
                              : null}
                          </Typography>
                        ) : (
                          <Typography variant="overline">NONE</Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Grid>
            </Grid>
          </CardActionArea>
        </Card>
      </Grid>
    )
  );

  return (
    <ProfileCard className={classes.root}>
      <ProfileCardHeader
        title={`${collectionType === 'cluster' ? 'Projects' : 'Clusters'} (${
          collections?.length
        })`}
      />
      <Divider />
      <PerfectScrollbar>
        <CardContent className={classes.container}>
          <Grid container alignItems="stretch" direction="row" spacing={4}>
            {renderCards}
          </Grid>
        </CardContent>
      </PerfectScrollbar>
    </ProfileCard>
  );
};

ProfileCollections.propTypes = {
  collections: PropTypes.array,
  collectionType: PropTypes.string,
};

export default ProfileCollections;
