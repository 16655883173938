import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { RouteWithLayout } from 'components';
import { Main as MainLayout } from 'layouts';

import {
  SignInRoutes,
  SignUpRoutes,
  PledgeRoutes,
  CategoriesRoute,
  ClusterRoutes,
  ProjectRoutes,
  FunderRoutes,
  NotFoundRoutes,
  DashboardRoutes,
  ResetPassword,
  ForgetPasswordRoutes,
  UpdatePasswordRoutes,
  OrganizationRoutes,
  UserProfileRoutes,
  UserSettingsRoute,
} from './views';

const generalRoutes = [
  <Redirect exact from="/" to="/dashboard" key="redirect-dashboard" />,
  <Redirect exact from="/login" to="/sign-in" key="redirect-login" />,
  <Route component={DashboardRoutes} path="/dashboard" key="dashboard" />,
  ProjectRoutes.list,
  ProjectRoutes.create,
  ProjectRoutes.edit,
  ProjectRoutes.profile,
  PledgeRoutes.create,
  PledgeRoutes.list,
  PledgeRoutes.assign,
  ClusterRoutes.list,
  ClusterRoutes.create,
  ClusterRoutes.edit,
  ClusterRoutes.detail,
  ClusterRoutes.profile,
  OrganizationRoutes.list,
  FunderRoutes.list,
  FunderRoutes.profile,
  FunderRoutes.create,
  FunderRoutes.edit,
  UserProfileRoutes.detail,
  UserProfileRoutes.funder,
  UserSettingsRoute.user,
  CategoriesRoute.list,
  <Route component={SignInRoutes} path="/sign-in" key="sign-in" />,
  <Route
    component={ForgetPasswordRoutes}
    path="/forget-password"
    key="forget-password"
  />,
  <Route
    component={UpdatePasswordRoutes}
    path="/update-password"
    key="update-password"
  />,
  <Route component={SignUpRoutes} path="/sign-up" key="sign-up" />,
  <Route component={NotFoundRoutes} path="/not-found" key="not-found" />,
  <RouteWithLayout
    component={ResetPassword}
    exact
    layout={MainLayout}
    path="/reset-password"
    requireActive
    userAccess={['admin']}
    redirect="/"
    key="reset-password"
  />,
  <Redirect from="*" to="/not-found" key="*" />,
];

const Routes = () => {
  const routes = generalRoutes;

  return <Switch>{routes}</Switch>;
};

export default Routes;
