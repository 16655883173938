import { useState, useEffect } from 'react';

const useStatePersist = (defaultValue, key) => {
  const [value, setValue] = useState(() => {
    const storedValue = window.localStorage.getItem(key);
    return storedValue !== null
      ? JSON.parse(storedValue)
      : defaultValue;
  });

  useEffect(() => {
    window.localStorage.setItem(key, value);
  }, [key, value]);

  return [value, setValue];
}

export default useStatePersist;