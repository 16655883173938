import { Funders } from 'api';

// Keys are used to allow us to assign the same key
// for the same type of request so data can be cached
// with react-query
export const keys = {
  fetch: 'FETCH_FUNDERS',
  dashboardData: 'FETCH_FUND_DASHBOARD',
};

export const all = () => {
  return Funders.all();
};

export const save = funder => {
  return Funders.post(funder);
};

export const allByFunder = id => {
  return Funders.find(id);
};
export const getDashboardData = funderId => {
  return Funders.dashboardData(funderId);
};

export const update = funder => {
  return Funders.update(funder.id, funder);
};
