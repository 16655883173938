import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Menu,
  MenuItem,
  Fade,
} from '@material-ui/core';

const SearchFilter = props => {
  const { label, onApplyFilter, options, selectedFilters, styles } = props;
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isOpen = Boolean(anchorEl);

  const handleChange = (event, item) => {
    selectedOptions.includes(item)
      ? setSelectedOptions(
        selectedOptions.filter(opt => opt.name !== item.name)
      )
      : setSelectedOptions([...selectedOptions, item]);
  };

  const openMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    setSelectedOptions(selectedFilters);
  }, [selectedFilters]);

  const handleApply = () => {
    setAnchorEl(null);
    onApplyFilter(selectedOptions);
  };

  return (
    <>
      <Button
        className={styles}
        onClick={openMenu}
        color="primary"
        style={{ fontWeight: selectedOptions.length !== 0 ? 'bold' : '' }}
      >
        {label}
      </Button>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={isOpen}
        onClose={handleApply}
        TransitionComponent={Fade}
      >
        {options.map(item => (
          <MenuItem key={item.id} onClick={e => handleChange(e, item)}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedOptions.includes(item)}
                  name={item.name}
                />
              }
              label={item.name}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

SearchFilter.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array,
  onApplyFilter: PropTypes.func,
  selectedFilters: PropTypes.array,
  styles: PropTypes.string,
};

export default SearchFilter;
