import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { Grid, Button, TextField, Snackbar } from '@material-ui/core';
import { loadCookie } from 'common/cookies';
import { useMutation } from 'react-query';
import { resetPasswordFetcher } from './fetchers';

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64,
    },
  },
  newPassword: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128,
    },
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
  },
  content: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(15),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(5),
      paddingTop: theme.spacing(15),
    },
  },
  contentBody: {
    display: 'flex',
    flexBasis: 500,
    flexDirection: 'column',
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },
}));

const ResetPassword = () => {
  const classes = useStyles();
  const [error, setError] = useState('');
  const [resetPassword, { status }] = useMutation(resetPasswordFetcher);
  const [successOpen, setSuccessOpen] = useState(false);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setError('');
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleReset = async event => {
    event.preventDefault();
    const { user } = loadCookie();
    await resetPassword(
      {
        userId: user.id,
        email: formState.values.email,
        newPassword: formState.values.newPassword,
      },
      {
        onError: error => {
          setError(error?.response?.data?.message);
          setFormState(formState => ({
            isValid: true,
            errors: {},
            touched: {},
            values: {
              ...formState.values,
              newPassword: '',
            },
          }));
        },
        onSuccess: () => {
          setSuccessOpen(true);
          setFormState(formState => ({
            isValid: true,
            errors: {},
            touched: {},
            values: {
              ...formState.values,
              newPassword: '',
            },
          }));
          // saveCookie(data.access_token);
          // const { user } = loadCookie();
          // user.isOrganizationUser
          //   ? history.push('/projects')
          //   : history.push('/dashboard');
        },
      }
    );
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={successOpen}
        autoHideDuration={6000}
        onClose={() => setSuccessOpen(false)}
      >
        <Alert severity="success">Password Updated</Alert>
      </Snackbar>
      <Grid className={classes.content} item xs={12}>
        <div className={classes.contentBody}>
          <form className={classes.form} onSubmit={handleReset}>
            {error && (
              <Alert severity="error" variant="filled">
                {error}
              </Alert>
            )}
            <TextField
              className={classes.textField}
              error={hasError('email')}
              fullWidth
              helperText={hasError('email') ? formState.errors.email[0] : null}
              label="Email address"
              name="email"
              onChange={handleChange}
              type="text"
              value={formState.values.email || ''}
              variant="outlined"
            />
            <TextField
              className={classes.textField}
              error={hasError('newPassword')}
              fullWidth
              helperText={
                hasError('newPassword') ? formState.errors.newPassword[0] : null
              }
              label="New Password"
              name="newPassword"
              onChange={handleChange}
              type="password"
              value={formState.values.newPassword || ''}
              variant="outlined"
            />
            <Button
              className={classes.signInButton}
              color="primary"
              disabled={!formState.isValid || status.loading === 'loading'}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Reset Password
            </Button>
          </form>
        </div>
      </Grid>
    </div>
  );
};

ResetPassword.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ResetPassword);
