// External Dependencies
import jwt from 'jsonwebtoken';
import Cookies from 'universal-cookie';

// Initialize Cookie
const cookieName = '_bp';
const cookies = new Cookies();

const options = {
  path: '/',
  httpOnly: false,
  domain: window.location.hostname,
  secure: !(process.env.NODE_ENV === 'development'),
};

export const saveCookie = token => {
  const decodedToken = jwt.decode(token);
  const tokenUser = decodedToken.user;
  const roles = tokenUser?.roles?.map(role => role.name);

  let user = {
    ...tokenUser,
    isFundUser: roles.includes('fund'),
    isAdminUser: roles.includes('admin'),
    isNetworkUser: roles.includes('net'),
    isOrganizationUser: roles.includes('org'),
    hasOrganizationRole: roles.includes('manageOrganization'),
    hasProjectRole: roles.includes('manageProject'),
    hasClusterRole: roles.includes('manageCluster'),
    hasViewFunderRole: roles.includes('viewFunder'),
  };

  const cookieInfo = {
    user,
    token,
  };

  cookies.set(cookieName, JSON.stringify(cookieInfo), { ...options });
  window.localStorage.setItem(cookieName, JSON.stringify(cookieInfo));
};

export const updateUserCookie = (newUserInfo) => {
  const cookie = JSON.parse(window.localStorage.getItem(cookieName)) || {};

  const newUser = Object.assign({}, cookie.user, newUserInfo);
  const cookieInfo = {
    user: newUser,
    token: cookie.token,
  }

  cookies.set(cookieName, JSON.stringify(cookieInfo), { ...options });
  window.localStorage.setItem(cookieName, JSON.stringify(cookieInfo));
}

export const loadCookie = () => {
  // return cookies.get(cookieName) || {};
  const cookie = JSON.parse(window.localStorage.getItem(cookieName)) || {};
  return cookie;
};

export const deleteCookie = () => {
  cookies.remove(cookieName, { path: '/' });
  window.localStorage.removeItem(cookieName);
};

export const isTokenValid = token => {
  try {
    jwt.verify(token, process.env.REACT_APP_APP_SECRET);
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};
