import React, { useState } from 'react';

import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Button,
  LinearProgress,
} from '@material-ui/core';
import { ShareProfileModal } from 'components/ShareProfileButton/Components';
import { useHistory } from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/styles';

import { loadCookie } from 'common/cookies';
import GuestPledgeModal from './GuestPledgeModal';
import ProfileContext from '../ProfileContext';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100%',
    position: 'relative',
  },
  box: {
    paddingTop: theme.spacing(2),
  },
}));

const FundProgress = withStyles(theme => ({
  determinate: {
    height: theme.spacing(2),
  },
}))(LinearProgress);

const ProfileFundProgress = ({
  fundGoal,
  fundGoalReached,
  collectionDonationCount,
  collectionID,
  collectionType,
  enablePledgeButton,
}) => {
  const { hideFinancials } = React.useContext(ProfileContext);

  const classes = useStyles();
  const history = useHistory();
  const { user } = loadCookie();
  const [open, setOpen] = useState(false);
  const [openPledgeModal, setOpenPledgeModal] = useState(false);

  const handleOpen = () => setOpenPledgeModal(true);
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    notation: 'compact',
    style: 'currency',
    currency: 'USD',
    currencySign: 'accounting',
    compactDisplay: 'short',
  });

  const percentFormatter = new Intl.NumberFormat('en-US', {
    notation: 'compact',
    style: 'percent',
  });
  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid
          justify="space-around"
          alignItems="center"
          spacing={1}
          container
          direction="row"
          xs={12}
        >
          <Grid
            justify="center"
            alignItems="center"
            item
            container
            direction="column"
            lg={2}
            xs={4}
          >
            <Grid item container justify="center" alignItems="center">
              <Typography color="primary" variant="h4">
                {percentFormatter.format(
                  fundGoal <= 0 ? 0 : fundGoalReached / fundGoal
                )}
              </Typography>
            </Grid>
            <Grid item container justify="center" alignItems="center">
              <Typography variant="subtitle2">Funded</Typography>
            </Grid>
          </Grid>
          {hideFinancials ? null : (
            <Grid
              justify="center"
              alignItems="center"
              item
              container
              direction="column"
              lg={2}
              xs={4}>
              <Grid item container justify="center" alignItems="center">
                <Typography color="primary" variant="h4">
                  {`${currencyFormatter.format(
                    fundGoalReached
                  )} of ${currencyFormatter.format(fundGoal)}`}
                </Typography>
              </Grid>
              <Grid item container justify="center" alignItems="center">
                <Typography variant="subtitle2">Goal Reached</Typography>
              </Grid>
            </Grid>
          )}
          <Grid
            justify="center"
            alignItems="center"
            item
            container
            direction="column"
            lg={2}
            xs={4}
          >
            <Grid item container justify="center" alignItems="center">
              <Typography color="primary" variant="h4">
                {collectionDonationCount}
              </Typography>
            </Grid>
            <Grid item container justify="center" alignItems="center">
              <Typography variant="subtitle2">
                {collectionType === 'cluster' ? 'Pledge(s)' : 'Fund(s)'}
              </Typography>
            </Grid>
          </Grid>
          {collectionType === 'cluster' &&
          (user?.isFundUser || user === undefined) ? (
              <Grid container justify="center" alignItems="center" lg={2} xs={4}>
                <Button
                  disabled={!enablePledgeButton}
                  fullWidth
                  onClick={
                    user
                      ? () => history.push(`/pledges/create/${collectionID}`)
                      : handleOpen
                  }
                  variant="contained"
                  color="primary"
                >
                  <Typography style={{ color: 'white' }} variant="button">
                  Pledge
                  </Typography>
                </Button>
              </Grid>
            ) : null}
        </Grid>
      </CardContent>
      <FundProgress
        className={classes.progress}
        value={
          fundGoal > 0
            ? (fundGoalReached / fundGoal) * 100 > 100
              ? 100
              : (fundGoalReached / fundGoal) * 100
            : 0
        }
        valueBuffer={100}
        variant="determinate"
      />
      <ShareProfileModal
        open={open}
        handleClose={() => setOpen(() => false)}
        profileLink={`${window.location.origin}/profile/${collectionType}/${collectionID}`}
      />
      <GuestPledgeModal
        open={openPledgeModal}
        clusterId={collectionID}
        handleClose={() => setOpenPledgeModal(() => false)}
      />
    </Card>
  );
};

ProfileFundProgress.propTypes = {
  fundGoal: PropTypes.number,
  fundGoalReached: PropTypes.number,
  collectionDonationCount: PropTypes.number,
  collectionID: PropTypes.string,
  collectionType: PropTypes.string,
  enablePledgeButton: PropTypes.bool,
};

export default ProfileFundProgress;
