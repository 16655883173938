import palette from 'theme/palette';

export let data = {
  labels: [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC',
  ],
  datasets: [
    {
      label: 'Goal',
      fill: false,
      barThickness: 12,
      barPercentage: 0.5,
      maxBarThickness: 10,
      categoryPercentage: 0.5,
      borderColor: palette.primary.main,
      backgroundColor: palette.primary.main,
      data: [],
      datalabels: { anchor: 'end', align: 'left' },
    },
    {
      label: 'Pledges',
      fill: false,
      barThickness: 12,
      barPercentage: 0.5,
      maxBarThickness: 10,
      categoryPercentage: 0.5,
      borderColor: palette.pink,
      backgroundColor: palette.pink,
      data: [],
      datalabels: { anchor: 'end', align: 'right' },
    },
  ],
};

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  animation: false,
  legend: { display: false },
  cornerRadius: 20,
  tooltips: {
    enabled: true,
    mode: 'index',
    intersect: false,
    borderWidth: 1,
    borderColor: palette.divider,
    backgroundColor: palette.white,
    titleFontColor: palette.text.primary,
    bodyFontColor: palette.text.secondary,
    footerFontColor: palette.text.secondary,
    callbacks: {
      label: function(tooltipItem, data) {
        let label = data.datasets[tooltipItem.datasetIndex].label;
        label += ': ';

        label += new Intl.NumberFormat('USA', {
          style: 'currency',
          currency: 'USD',
        }).format(Math.round(tooltipItem.yLabel));

        return label;
      },
    },
  },
  plugins: {
    datalabels: {
      display: false,
      formatter: (value, ctx) => {
        if (
          ctx.chart.data.datasets[0].data[ctx.dataIndex] === 0 &&
          ctx.chart.data.datasets[1].data[ctx.dataIndex] === 0
        ) {
          return '';
        }
        let sum =
          ctx.chart.data.datasets[0].data[ctx.dataIndex] +
          ctx.chart.data.datasets[1].data[ctx.dataIndex];
        let percentage = ((value * 100) / sum).toFixed(2) + '%';
        return percentage;
      },
      color: '#808080',
    },
  },
  layout: { padding: 0 },
  scales: {
    xAxes: [
      {
        ticks: {
          fontColor: palette.text.secondary,
        },
        gridLines: {
          display: false,
          drawBorder: false,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          fontColor: palette.text.secondary,
          beginAtZero: true,
          min: 0,
          callback: function(value) {
            return new Intl.NumberFormat('USA', {
              style: 'currency',
              currency: 'USD',
            }).format(value);
          },
        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: palette.divider,
        },
      },
    ],
  },
};
