import { Pledges, FindPledgesByNetwork, FindPledgesByFunder } from 'api';

// Keys are used to allow us to assign the same key
// for the same type of request so data can be cached
// with react-query
export const keys = {
  fetch: 'FETCH_PLEDGES',
  fetchByNetwork: 'FETCH_PLEDGES_BY_NETWORK',
  fetchByFunder: 'FETCH_PLEDGES_BY_FUNDER',
};

export const all = () => {
  return Pledges.all();
};

export const allByOne = id => {
  return Pledges.find(id);
};

export const findByNetwork = networkID => {
  return FindPledgesByNetwork.find(networkID);
};

export const findByFunder = funderID => {
  return FindPledgesByFunder.find(funderID);
};

export const save = pledges => {
  return Pledges.post(pledges);
};

export const savePublic = pledgeData => {
  return Pledges.post(pledgeData, '/guest');
};

export const update = pledges => {
  return Pledges.update(pledges?.id, pledges);
};

export const remove = id => {
  return Pledges.delete(id);
};

export const requestCapitalAllocation = pledgeData => {
  return Pledges.post(pledgeData, '/capitalAllocation');
};

export const assign = ({ id }) => {
  return Pledges.update(id, {}, '/assign');
};

export const undo = id => {
  return Pledges.post({}, `/undo/${id}`);
};
