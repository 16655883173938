import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  ProfileCard,
  AreasAndDemos,
  ContactInfo,
  LocationChip,
} from './ProfilePageComponents';
import {
  CardContent,
  Button,
  Grid,
  Avatar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { ShareProfileModal } from 'components/ShareProfileButton/Components';
import Image from 'material-ui-image';
import { makeStyles, withStyles } from '@material-ui/styles';
import {
  LocationOnOutlined,
  LinkOutlined,
  MarkunreadMailboxOutlined,
  LanguageOutlined,
  Timelapse,
} from '@material-ui/icons';
import { loadCookie } from 'common/cookies';

const useStyles = makeStyles(theme => ({
  placeholder: {
    height: '16px',
    width: 'auto',
    color: '#94a4ab',
  },
  smallScreenStyles: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
  },

  fallbackImage: {
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    height: '100%',
    padding: '1em',
    objectPostion: 'center 100%',
  },
}));

// eslint-disable-next-line no-unused-vars
const redesignStyles = makeStyles(theme => ({
  header: {
    height: 'fit-content',
  },

  card: {
    marginBottom: '1.5rem',
  },

  headerBannerImage: {
    width: '100%',
  },

  areasAndDemographics: {
    display: 'flex',
    width: '100%',
    maxHeight: '15rem',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },

  headerBannerImageContainer: {
    height: 'fit-content',
  },

  headerProfilePic: {
    border: '3px solid #fff',
    height: '11em',
    width: '11em',
    '& > img': {
      margin: 0,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '-15%',
    },
    [theme.breakpoints.down('xs')]: {
      height: '7em',
      width: '7em',
    },
  },

  headerContent: {
    marginTop: '1.2rem',
    '& > .MuiGrid-container': {
      marginBottom: '12px',
    },
  },

  headerOverview: {},
}));

const StyledTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#3f51b5',
  },

  arrow: {
    color: '#3f51b5',
  },
}))(Tooltip);

const ProfileHeader = props => {
  const { user } = loadCookie();
  const isFunder = user?.isFundUser;

  const classes = useStyles();
  const redesignClasses = redesignStyles();
  const [open, setOpen] = useState(false);

  const { details, areas, demographics, collectionType, pledgeAction } = props;

  const {
    profileImage,
    bannerImage,
    name,
    id,
    website,
    geography,
    email,
    description,
    tag,
  } = details;

  const orgName =
    collectionType === 'cluster'
      ? details.networkName
      : collectionType === 'project'
        ? details.orgName
        : '';

  const listComponents = (arr, callback, emptyText) => {
    if (arr?.length > 0) return <>{arr.map(callback)}</>;
    return (
      <Typography className={classes.placeholder} variant="overline">
        {emptyText ?? 'None'}
      </Typography>
    );
  };

  const renderProjectTag = tag => {
    if (collectionType === 'cluster') return;
    const getTagColor = name => {
      switch (name) {
        case 'acceleration':
          return 'red';
        case 'equity participation':
          return 'orange';
        case 'community ownership':
          return 'green';
        case 'pre-incubation':
          return 'gold';
        case 'incubation':
          return 'purple';
        default:
          return 'grey';
      }
    };
    const color = getTagColor(tag?.name);
    return (
      <Grid
        container
        className={classes.smallScreenStyles}
        style={{ color: `${color}`, marginTop: '1em', marginBottom: '1.5em' }}>
        <Grid item>
          <Grid container direction="row" alignItems="center">
            <Typography
              variant="overline"
              style={{
                fontSize: '14px',
                fontWeight: 'bold',
                marginRight: '.5rem',
              }}>
              Stage:
            </Typography>

            <Timelapse />
            <StyledTooltip
              disableFocusListener
              arrow
              title={
                <Typography style={{ color: 'white' }} variant="overline">
                  {tag?.description}
                </Typography>
              }>
              <Typography
                variant="overline"
                style={{
                  fontSize: '14px',
                  color: `${color}`,
                  marginLeft: '.25rem',
                  fontWeight: 'bold',
                  cursor: 'default',
                }}>
                {tag?.name.toUpperCase() ?? 'NOT SET'}
              </Typography>
            </StyledTooltip>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <ProfileCard
        variant="outlined"
        className={`${redesignClasses.header} ${redesignClasses.card}`}>
        <CardContent style={{ padding: '0' }}>
          <Grid container direction="column">
            <Grid container item xs={12}>
              <Image
                title={name}
                style={{ padding: '0', width: '100%', aspectRatio: '3.9' }}
                imageStyle={{
                  position: 'inherit',
                }}
                errorIcon={
                  <img
                    className={classes.fallbackImage}
                    style={{ objectFit: 'cover', padding: 0 }}
                    src={'/images/spencer-backman-oWEf4NRgHd0-unsplash.jpg'}
                    alt="forest"
                  />
                }
                src={bannerImage ?? 'null'}
              />
            </Grid>
            <Grid
              container
              alignItems="flex-start"
              justifyContent="space-between"
              align
              spacing={3}
              item
              xs={12}
              style={{ padding: '2rem' }}>
              <Grid
                container
                item
                xs={12}
                md
                style={{ marginTop: '-8%' }}
                justifyContent="center">
                <Avatar
                  className={redesignClasses.headerProfilePic}
                  src={profileImage}>
                  <img
                    className={classes.fallbackImage}
                    src={'/images/logos/logo--white.svg'}
                    alt="logo"
                  />
                </Avatar>
              </Grid>
              <Grid item xs md={6} style={{ paddingBottom: '2rem' }}>
                <Grid container item xs md={12} direction="column">
                  <Typography
                    variant="h2"
                    style={{ fontWeight: 'bold' }}
                    className={classes.smallScreenStyles}>
                    {name}
                  </Typography>

                  {renderProjectTag(tag)}
                  <Grid item xs>
                    <Typography
                      variant="p"
                      className={redesignClasses.headerOverview}>
                      {description}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    justifyContent="flex-start"
                    spacing={1}
                    style={{ margin: '1.5rem 0' }}
                    alignItems="center"
                    direction="row">
                    {listComponents(
                      geography,
                      (el, index) => (
                        <Grid item key={el}>
                          <LocationChip
                            color="primary"
                            icon={<LocationOnOutlined />}
                            key={`Demographic-Location-Profile-${index}`}
                            label={
                              <Typography
                                style={{ color: 'white', fontWeight: 'bold' }}
                                variant="overline">
                                {el}
                              </Typography>
                            }
                          />
                        </Grid>
                      ),
                      'No Locations'
                    )}
                  </Grid>
                  <Grid
                    container
                    justifyContent="flex-start"
                    spacing={3}
                    direction="row">
                    {email ? (
                      <ContactInfo
                        icon={<MarkunreadMailboxOutlined color="primary" />}
                        content={
                          <Typography color="primary">
                            {' '}
                            <a href={`mailto:${email}`}> {email} </a>{' '}
                          </Typography>
                        }
                      />
                    ) : null}
                    {website ? (
                      <ContactInfo
                        icon={
                          <LinkOutlined
                            color="primary"
                            style={{ transform: 'rotate(-45deg)' }}
                          />
                        }
                        content={
                          <Typography color="primary">
                            {website ? (
                              <a
                                href={
                                  website.startsWith('https://') ||
                                    website.startsWith('http://')
                                    ? website
                                    : `https://${website}`
                                }
                                target="_blank"
                                rel="noopener noreferrer">
                                {website}
                              </a>
                            ) : (
                                'None'
                              )}
                          </Typography>
                        }
                      />
                    ) : null}
                    <ContactInfo
                      icon={<LanguageOutlined color="primary" />}
                      content={
                        <Typography
                          color="primary"
                          className={orgName ? null : classes.placeholder}>
                          {orgName || 'No associated organization'}
                        </Typography>
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item md justifyContent="left" spacing={1}>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setOpen(true)}>
                    Share
                  </Button>{' '}
                </Grid>
                {pledgeAction && isFunder ? (
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={pledgeAction}>
                      Pledge
                    </Button>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </ProfileCard>
      <Grid container justify="space-between" spacing={2} alignItems="stretch">
        <Grid item xs={12} md style={{ display: 'flex' }}>
          <ProfileCard
            variant="outlined"
            className={redesignClasses.areasAndDemographics}>
            <AreasAndDemos
              title="Areas of Work"
              items={areas}
              color="primary"
            />
          </ProfileCard>
        </Grid>
        <Grid item xs={12} md style={{ display: 'flex' }}>
          <ProfileCard
            variant="outlined"
            className={redesignClasses.areasAndDemographics}>
            <AreasAndDemos
              title="Demographics"
              items={demographics}
              color="secondary"
            />
          </ProfileCard>
        </Grid>
      </Grid>
      <ShareProfileModal
        open={open}
        handleClose={() => setOpen(() => false)}
        profileLink={`${window.location.origin}/profile/${collectionType}/${id}`}
      />
    </>
  );
};

ProfileHeader.propTypes = {
  details: PropTypes.object,
  admin: PropTypes.string,
  areas: PropTypes.array,
  demographics: PropTypes.array,
  collectionType: PropTypes.string,
};

export default ProfileHeader;
