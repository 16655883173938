import React from 'react';
import { RouteWithLayout } from 'components';
import { Minimal as MinimalLayout } from 'layouts';
import { Switch, Redirect } from 'react-router-dom';

import ForgetPassword from './ForgetPassword';

const ForgetPasswordRoutes = () => {
  return (
    <Switch>
      <RouteWithLayout
        component={ForgetPassword}
        exact
        isPrivate={false}
        layout={MinimalLayout}
        path="/forget-password"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default ForgetPasswordRoutes;
