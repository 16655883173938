import axios from 'axios';
import { deleteCookie } from 'common/cookies';
import { browserHistory } from 'App';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URI,
});

axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    // If user is Unauthorized
    if (error.response.status === 401) {
      deleteCookie();
      browserHistory.push({
        path: '/sign-in',
        state: { fromForcedLogout: true },
      });
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
