import React from 'react';
import { Main as MainLayout } from 'layouts';
import { RouteWithLayout } from 'components';

const userSettings =  React.lazy(() => import('./Settings')); // prettier-ignore

const Settings = {
  user: (
    <RouteWithLayout
      component={userSettings}
      exact
      layout={MainLayout}
      path="/settings"
      isPrivate
      userAccess={['fund']}
      key="user-settings"
    />
  ),
};

export default Settings;
