import React from 'react';
import { LinearProgress, Tooltip } from '@material-ui/core';
import { loadCookie } from 'common/cookies';

const AmountRaisedCell = props => {
  const { user } = loadCookie();
  const { project } = props;

  const hideFinancials = project.clusters.reduce(
    (m, c) => m || c.hideFinancialsProjectLead || c.hideFinancialsExtracluster,
    false
  );

  if (hideFinancials && project.createdBy !== user.id) {
    return (
      <>
        <Tooltip title={'fpo: obfuscated values tooltip message'}>
          <span>--</span>
        </Tooltip>
      </>
    );
  } else {
    const totalBudgetReached = project?.capitalTypesProjects
      ?.map(({ budgetReached }) => Number(budgetReached))
      ?.reduce((acc, curr) => acc + curr, 0);

    return (
      <>
        {new Intl.NumberFormat('USA', {
          style: 'currency',
          currency: 'USD',
        }).format(totalBudgetReached || 0)}
      </>
    );
  }
};
export default AmountRaisedCell;
