import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  TableCell,
  TableRow,
  Grid,
  Typography,
  Checkbox,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { ConfirmationModal } from 'components';

import {
  KeyboardArrowUp,
  KeyboardArrowDown,
  Edit,
  Cancel,
  Check,
} from '@material-ui/icons';

const EnhancedRow = props => {
  const {
    row,
    labelId,
    isItemSelected,
    headers,
    handleClick,
    collapse,
    CollapseContent,
    admin,
    saveFunc,
    deleteFunc,
    editFunc,
    title,
    primaryLabel,
    batchSelection,
  } = props;
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [rowValues, setRow] = useState(row);
  const [saving, setSaving] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const modalContent = (row, tableTitle) => {
    return (
      <Grid container direction="column">
        <Typography>
          Are you sure you want to delete <b>{row[primaryLabel || 'id']}</b>{' '}
          from {tableTitle}?
        </Typography>
        <Alert
          style={{ marginTop: '1.5rem' }}
          severity="warning"
          variant="outlined">
          Deleting this item will remove it from all associated projects &amp;
          funders.
        </Alert>
      </Grid>
    );
  };

  useEffect(() => {
    setRow(row);
  }, [row]);
  const handleChange = e => {
    e.persist();
    setRow(rowValues => ({ ...rowValues, [e.target.name]: e.target.value }));
  };

  const handleEdit = () => {
    if (editFunc) {
      editFunc(row);
    } else {
      setEdit(true);
    }
  };

  const handleSave = async () => {
    setSaving(true);
    await saveFunc(rowValues).then(() => {
      setSaving(false);
      setEdit(() => false);
    });
  };

  const handleDelete = async () => {
    setSaving(true);
    await deleteFunc(rowValues).then(() => {
      setSaving(false);
      setOpenModal(false);
    });
  };

  const handleCancel = () => {
    setEdit(() => false);
    setRow(row);
  };

  return (
    <Fragment>
      <TableRow
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.name}
        selected={isItemSelected}>
        {batchSelection && (
          <TableCell padding="checkbox">
            <Checkbox
              onClick={event => handleClick(event, row)}
              checked={isItemSelected}
              inputProps={{ 'aria-labelledby': labelId }}
            />
          </TableCell>
        )}

        {headers?.map((header, index) => {
          return (
            <TableCell
              key={`${header?.id}-${index}`}
              align={header?.align || 'left'}>
              {edit ? (
                <TextField
                  name={header?.id}
                  value={rowValues[header?.id]}
                  onChange={handleChange}
                />
              ) : typeof header?.render !== 'undefined' ? (
                header?.render(rowValues, index, rowValues[header?.id])
              ) : (
                rowValues[header?.id]
              )}
            </TableCell>
          );
        })}
        <TableCell align="right">
          {admin ? (
            <>
              {edit ? (
                <IconButton color="secondary" onClick={handleSave}>
                  {saving ? (
                    <CircularProgress size={14} color="secondary" />
                  ) : (
                    <Check />
                  )}
                </IconButton>
              ) : (
                <IconButton onClick={handleEdit}>
                  <Edit />
                </IconButton>
              )}
              {edit ? (
                <IconButton onClick={handleCancel} color="primary">
                  <Cancel />
                </IconButton>
              ) : null}
            </>
          ) : null}
        </TableCell>
        <TableCell>
          {collapse ? (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          ) : null}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 2 }} colSpan={9}>
          {collapse && open && <CollapseContent row={row} />}
        </TableCell>
      </TableRow>
      <ConfirmationModal
        modalContent={modalContent(row, title)}
        modalTitle={'Delete Confirmation'}
        openConfirmationModal={openModal}
        setOpenConfirmationModal={setOpenModal}
        handleConfirm={handleDelete}
      />
    </Fragment>
  );
};
EnhancedRow.propTypes = {
  row: PropTypes.object.isRequired,
  title: PropTypes.string,
  labelId: PropTypes.string.isRequired,
  isItemSelected: PropTypes.bool.isRequired,
  headers: PropTypes.array.isRequired,
  handleClick: PropTypes.func.isRequired,
  collapse: PropTypes.bool,
  CollapseContent: PropTypes.func,
  actions: PropTypes.array,
  admin: PropTypes.bool,
  primaryLabel: PropTypes.string,
  saveFunc: PropTypes.func,
  deleteFunc: PropTypes.func,
  editFunc: PropTypes.func,
  batchSelection: PropTypes.bool,
};
export default EnhancedRow;
