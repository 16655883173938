import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button } from '@material-ui/core';
import AirtablePull from './components/AirtablePull/AirtablePull';
const UsersTable = React.lazy(() => import('./components/UsersTable'));
const NetworksTable = React.lazy(() => import('./components/NetworksTable'));

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
  },
  table: {
    width: '100%',
  },
}));

const AdminDashboard = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={12}>
        <div className={classes.table}>
          <UsersTable />
        </div>
      </Grid>
      <Grid container spacing={12}>
        <div className={classes.table}>
          <NetworksTable />
        </div>
      </Grid>
      <Grid container spacing={12}>
        <div className={classes.table}>
          <AirtablePull />
        </div>
      </Grid>
    </div>
  );
};

export default AdminDashboard;
