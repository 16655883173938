import React from 'react';
import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';
import { useVisibilityTimeout } from 'common/hooks';
import { TableRow, TableCell } from '@material-ui/core';

const Loader = () => {
  return (
    <ContentLoader speed={2} width="100%" height="45">
      <rect x="20" y="13" rx="3" ry="3" width="80" height="10" />
      <rect x="230" y="13" rx="3" ry="3" width="100" height="10" />
      <rect x="630" y="13" rx="3" ry="3" width="100" height="10" />
      <rect x="850" y="13" rx="3" ry="3" width="100" height="10" />
      <rect x="1130" y="13" rx="3" ry="3" width="100" height="10" />
    </ContentLoader>
  );
};

Loader.propTypes = {
  style: PropTypes.object,
};

const TableLoader = props => {
  const isShowing = useVisibilityTimeout();

  return isShowing ? (
    <TableRow>
      <TableCell colSpan={props.colSpan}>
        {Array(props.rows)
          .fill('')
          .map((e, i) => (
            <Loader key={i} style={{ opacity: Number(2 / i).toFixed(1) }} />
          ))}
      </TableCell>
    </TableRow>
  ) : null;
};

TableLoader.defaultProps = {
  rows: 5,
  colSpan: 5,
};

TableLoader.propTypes = {
  rows: PropTypes.number,
  colSpan: PropTypes.number,
};

export default TableLoader;
