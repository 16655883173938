import React, { useEffect, useState } from 'react';
import { airtableFetcher } from 'common/fetchers';
import { Button, Card, Typography, CardContent, Snackbar, TextField, Switch, Grid, CircularProgress, Select, FormControl, InputLabel, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useQuery } from 'react-query';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  root: {},
  title: {},
  // actions: {
  //   justifyContent: 'flex-end',
  // },
  // progress: {
  //   maxWidth: 100,
  //   marginTop: theme.spacing(1),
  // },
  // filterContainer: {
  //   margin: theme.spacing(2),
  //   padding: theme.spacing(1),
  // },
  // tableHead: {
  //   background: theme.palette.grey[200],
  // },
}));

const AirtablePull = () => {
  const classes = useStyles();

  const [UIIsDisabled, setUIIsDisabled] = useState(false);
  const [errorState, setErrorState] = useState(false);
  const [successIsOpen, setSuccessIsOpen] = useState(false);
  const [errorIsOpen, setErrorIsOpen] = useState(false);
  const [cronState, setCronState] = useState({ enabled: false, crontab: "* * * * *" });

  const { status: cronStatus, data: cronData, refetch: refetchCron } = useQuery(
    "crontab",
    airtableFetcher.getCron,
    {
      onSuccess: (res) => {
        setCronState(res.data.cronState);
      }
    }
  );

  useEffect(() => {
    const eventSource = new EventSource(process.env.REACT_APP_API_URI + 'api/airtable/sse');
    eventSource.onmessage = ({ data }) => {
      switch (JSON.parse(data).status) {
        case 'BEGIN':
          break;
        case 'ERROR':
          setErrorState(true)
          setErrorIsOpen(true)
          break;
        case 'END':
          if (!errorState) {
            setSuccessIsOpen(true)
            setUIIsDisabled(false)
          }
          break;
      }
    };
    return () => {
      eventSource.close();
    };
  }, []);

  const handleUpdateCron = async (val) => {
    await airtableFetcher.postCron(val);
    refetchCron();
  }

  const handleAirtableButtonClick = () => {
    airtableFetcher.airtable2Blueprint();
    setUIIsDisabled(true)
    setErrorState(false)
  }

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item style={{ height: 69, paddingTop: 18 }} xs={12}>
          <Typography
            id={`page-title-airtable-pull`}
            variant={'h4'}
            style={{ fontWeight: '400' }}
            className={classes.title}
          >
            Airtable
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container>
                <Grid item xs={6}>
                  <Grid container>
                    <Grid item xs={12}>
                      Cron
                    </Grid>
                    <Grid item xs={12}>
                      enabled: <Switch
                        checked={cronState.enabled}
                        onChange={() => {
                          handleUpdateCron(Object.assign(cronState, {
                            enabled: !cronState.enabled,
                          }))
                        }}
                        name="enabled"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl >
                        <Select
                          labelId="ctontab-select-label"
                          id="crontab-select"
                          value={cronState.crontab}
                          label="Age"
                          onChange={(e) => {
                            handleUpdateCron(Object.assign(cronState, {
                              crontab: e.target.value,
                            }))
                          }}
                        >
                          <MenuItem value={"* * * * *"}>Every Minute</MenuItem>
                          <MenuItem value={"0 * * * *"}>Every Hour</MenuItem>
                          <MenuItem value={"0 0 * * *"}>Once A Day</MenuItem>
                          <MenuItem value={"0 0 * * FRI"}>Once A Week</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      Manual Pull
                    </Grid>
                    <Grid item>
                      <Button
                        disabled={UIIsDisabled}
                        onClick={handleAirtableButtonClick}
                        variant="contained"
                        color="primary">
                        Execute
                      </Button>
                    </Grid>
                    <Grid item >
                      {UIIsDisabled ? <CircularProgress /> : null}
                    </Grid>
                    <Grid item xs={12}>
                      {errorState ? (<div><div>There was an issue in the process...</div><div>Please contact system administrator before reattempting data pull</div></div>) : null}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

        </Grid>
      </Grid>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={successIsOpen}
        autoHideDuration={6000}
        onClose={() => setSuccessIsOpen(false)}>
        <Alert severity="success">Airtable data pull success</Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={errorIsOpen}
        autoHideDuration={6000}
        onClose={() => setErrorIsOpen(false)}>
        <Alert severity="warning">Issue during Airtable data pull process</Alert>
      </Snackbar>
    </div >
  )
}

export default AirtablePull
