import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar,
  LinearProgress,
  Tooltip,
} from '@material-ui/core';
import AttachMoney from '@material-ui/icons/AttachMoney';
import { Skeleton } from '@material-ui/lab';
import { CapitalBreakdownModal } from 'components';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
  content: {
    alignItems: 'center',
    display: 'flex',
  },
  title: {
    fontWeight: 700,
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    height: 56,
    width: 56,
  },
  icon: {
    height: 32,
    width: 32,
  },
  progress: {
    marginTop: theme.spacing(3),
  },
  budget: {
    marginTop: '3%',
    marginBottom: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifySelf: 'flex-end',
    justifyContent: 'space-between',
  },
  budgetValue: {
    fontWeight: 500,
  },
}));

const BudgetGoal = props => {
  const {
    className,
    budgetData,
    isLoading,
    pledgeData,
    isFunder,
    clusterCount,
    budgetCapitalTypeBreakdown,
    clustersFunded,
    ...rest
  } = props;
  const classes = useStyles();
  let totalBudget = 0;
  let totalRaised = 0;
  let totalPledges = 0;

  budgetData.forEach(budget => {
    totalBudget += Number(budget.projectGoal);
    totalRaised += Number(budget.projectAmountRaised);
  });

  pledgeData.forEach(pledge => {
    totalPledges += Number(pledge.amount);
  });

  const linearProgress =
    ((totalRaised + totalPledges) / totalBudget) * 100 || 0;

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        {isLoading ? (
          <Skeleton />
        ) : (
          <>
            <Grid container justify="space-between">
              <Grid item>
                <Typography
                  className={classes.title}
                  id="total-budget-title"
                  color="textSecondary"
                  gutterBottom
                  variant="body2"
                >
                  TOTAL BUDGET FOR {isFunder ? '' : 'MY'} CLUSTERS
                </Typography>
                <Typography id="total-budget-clusters-value" variant="h3">
                  $ {new Intl.NumberFormat().format(totalBudget)}
                </Typography>
              </Grid>
              <Grid item>
                <Avatar className={classes.avatar}>
                  <AttachMoney className={classes.icon} />
                </Avatar>
              </Grid>
            </Grid>
            <Tooltip
              title={`${new Intl.NumberFormat('USA', {
                style: 'currency',
                currency: 'USD',
              }).format(totalRaised + totalPledges)}`}
              aria-label="progress"
            >
              <LinearProgress
                className={classes.progress}
                value={linearProgress >= 100 ? 100 : linearProgress}
                variant="determinate"
              />
            </Tooltip>
            <div className={classes.budget}>
              <Typography variant="body2">
                <span className={classes.budgetValue}>{clustersFunded}</span> of{' '}
                <span className={classes.budgetValue}>{clusterCount} </span>
                clusters funded
              </Typography>
              <CapitalBreakdownModal
                capitalTypeBreakdown={budgetCapitalTypeBreakdown}
              />
            </div>
          </>
        )}
      </CardContent>
    </Card>
  );
};

BudgetGoal.propTypes = {
  className: PropTypes.string,
  isOrganizationView: PropTypes.bool,
  isFunder: PropTypes.bool,
  isLoading: PropTypes.bool,
  budgetData: PropTypes.array.isRequired,
  pledgeData: PropTypes.array.isRequired,
  capitalTypeBreakdown: PropTypes.object,
  clusterCount: PropTypes.number,
  clustersFunded: PropTypes.number,
  onClick: PropTypes.func,
  budgetCapitalTypeBreakdown: PropTypes.object,
};

BudgetGoal.defaultProps = {
  isFunder: false,
};

export default BudgetGoal;
