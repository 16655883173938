import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { GenericToolbar } from 'components';
import { ControlPoint } from '@material-ui/icons';

const Toolbar = props => {
  const history = useHistory();
  const { isNetworkUser } = props;

  const handleNavigateToAddClusters = event => {
    event.preventDefault();
    history.push('/clusters/create');
  };

  return isNetworkUser ? (
    <GenericToolbar
      title="Add New Cluster"
      icon={<ControlPoint color="primary" />}
      action={handleNavigateToAddClusters}
    />
  ) : null;
};

Toolbar.propTypes = {
  className: PropTypes.string,
  isNetworkUser: PropTypes.bool,
};

export default Toolbar;
