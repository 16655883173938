import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Menu, MenuItem, Typography, IconButton } from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: '0.8rem',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {},
  sectionDesktop: {
    display: 'none',
    flexGrow: 2,
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
  sectionMobile: {
    display: 'flex',
    flexGrow: 2,
    justifyContent: 'flex-end',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

const PageTopBar = props => {
  const classes = useStyles();
  const { PageTitle, Widgets, titleSize } = props;
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const handleMobileMenuOpen = event => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const mobileMenuId = 'primary-search-account-menu-mobile';

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {Widgets.map((ele, index) => {
        return (
          <MenuItem
            key={mobileMenuId + '-' + index}
            id={mobileMenuId + '-' + index}
          >
            {ele}
          </MenuItem>
        );
      })}
    </Menu>
  );
  return (
    <div className={classes.root}>
      <Typography
        id={`page-title-${PageTitle}`}
        variant={titleSize || 'h2'}
        style={{ fontWeight: '400' }}
        className={classes.title}
      >
        {PageTitle}
      </Typography>
      <div className={classes.sectionDesktop}>{Widgets.map(ele => ele)}</div>
      <div className={classes.sectionMobile}>
        {Widgets?.length > 0 ? (
          <IconButton
            aria-label="show more"
            aria-controls={mobileMenuId}
            aria-haspopup="true"
            onClick={handleMobileMenuOpen}
            color="inherit"
          >
            <MoreIcon />
          </IconButton>
        ) : null}
      </div>
      {renderMobileMenu}
    </div>
  );
};

PageTopBar.propTypes = {
  PageTitle: PropTypes.string,
  Widgets: PropTypes.array,
  titleSize: PropTypes.string,
};
export default PageTopBar;
