import React from 'react';
import { Main as MainLayout } from 'layouts';
import { RouteWithLayout } from 'components';

const AccountPage = React.lazy(() => import('./Account'));

const AccountRoutes = () => {
  return (
    <>
      <RouteWithLayout
        component={AccountPage}
        exact
        layout={MainLayout}
        path="/account"
      />
    </>
  );
};

export default AccountRoutes;
