import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import {
  Typography,
  Tooltip,
  LinearProgress,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
  Link,
} from '@material-ui/core';
import { isEmpty } from 'lodash';

const useStyles = makeStyles(theme => ({
  capitalBreakdown: {
    marginTop: theme.spacing(1),
    width: '100%',
  },
  capitalBreakdownLink: {
    fontSize: '12px',
  },
}));

const CapitalBreakdownModal = props => {
  const classes = useStyles();
  const { capitalTypeBreakdown } = props;
  const [projectBudgetModal, setProjectBudgetModal] = useState(false);

  return (
    <>
      <Dialog
        open={projectBudgetModal}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={'xs'}
      >
        <DialogTitle id="form-dialog-title">Capital Breakdown</DialogTitle>
        <DialogContent>
          {isEmpty(capitalTypeBreakdown) ? (
            <Typography variant="h6">No Data</Typography>
          ) : (
            <Grid container direction="column" spacing={2}>
              {capitalTypeBreakdown?.map(
                ({
                  name,
                  budgetReached,
                  budgetGoal,
                  totalCapitalTypeFunds,
                }) => {
                  const totalBudgetReached =
                    Number(budgetReached) + Number(totalCapitalTypeFunds);
                  const progress =
                    (totalBudgetReached / Number(budgetGoal)) * 100;
                  return (
                    <Grid item key={name}>
                      <Grid container justify="space-between">
                        <Grid item size={6}>
                          <DialogContentText>
                            {name.toUpperCase()}
                          </DialogContentText>
                        </Grid>
                        <Grid item size={6}>
                          <Typography variant="h5">
                            {`$ ${new Intl.NumberFormat().format(
                              totalBudgetReached
                            )} / $ ${new Intl.NumberFormat().format(
                              budgetGoal
                            )}`}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Tooltip
                        title={`${new Intl.NumberFormat('USA', {
                          style: 'currency',
                          currency: 'USD',
                        }).format(totalBudgetReached)}`}
                        aria-label="progress"
                      >
                        <LinearProgress
                          className={classes.capitalBreakdown}
                          value={progress <= 100 ? progress : 100}
                          variant="determinate"
                        />
                      </Tooltip>
                    </Grid>
                  );
                }
              )}
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setProjectBudgetModal(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Link
        component="button"
        className={classes.capitalBreakdownLink}
        onClick={event => {
          event.preventDefault();
          setProjectBudgetModal(true);
        }}
      >
        View Capital Breakdown
      </Link>
    </>
  );
};

CapitalBreakdownModal.propTypes = {
  className: PropTypes.string,
  capitalTypeBreakdown: PropTypes.array,
};

export default CapitalBreakdownModal;
