import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  },
}));

const Footer = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Typography variant="body1">
        &copy; Blueprint {new Date().getFullYear()}
      </Typography>
      <Typography variant="caption">
        Created with{' '}
        <span aria-labelledby="love" role="img">
          💙
        </span>{' '}
        by Full Spectrum Capital Partners
      </Typography>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
