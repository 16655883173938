import { Tags } from 'api';

// Keys are used to allow us to assign the same key
// for the same type of request so data can be cached
// with react-query
export const keys = {
  fetch: 'FETCH_DEVELOPMENT_TAGS',
  fetchMeta: 'FETCH_DEVELOPMENT_TAGS',
};

export const all = () => {
  return Tags.all();
};

export const save = tag => {
  return Tags.post(tag);
};

export const update = tag => {
  return Tags.update(tag?.id, tag);
};

export const remove = tags => {
  return Tags.post(tags, '/batchDelete');
};
