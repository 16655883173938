import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
// import {  deleteCookie } from 'common/cookies';
import { loadCookie, isTokenValid } from 'common/cookies';
const RouteWithLayout = props => {
  const {
    isPrivate = true,
    layout: Layout,
    component: Component,
    ...rest
  } = props;
  // const history = useHistory();
  //Determines if a user can view a route
  const isAllowed = () => {
    let canView = false;
    const cookie = loadCookie();
    // Viewing a route is dependent on the following
    //1. is the route private ?
    //2. does the current user have a valid cookie
    //3. is the current user active ?
    //4. Can the current user's role access this route
    if (!isPrivate) {
      // any one can see non-private routes
      canView = true;
    } else {
      if (cookie?.token && cookie?.user && isTokenValid(cookie.token)) {
        if (props.requireActive && !cookie.user.active) {
          //requires an active user to view the route
          canView = false;
        } else if (props.userAccess.length > 0) {
          //requires specific user roles to view the route
          props.userAccess.forEach(userRole => {
            if (userRole === 'admin') {
              if (cookie.user.isAdminUser) {
                canView = true;
              }
            } else if (userRole === 'net') {
              if (cookie.user.isNetworkUser) {
                canView = true;
              }
            } else if (userRole === 'org') {
              if (cookie.user.isOrganizationUser) {
                canView = true;
              }
            } else if (userRole === 'fund') {
              if (cookie.user.isFundUser) {
                canView = true;
              }
            } else if (userRole === 'manageProject') {
              if (cookie.user.hasProjectRole) {
                canView = true;
              }
            } else if (userRole === 'manageCluster') {
              if (cookie.user.hasClusterRole) {
                canView = true;
              }
            } else if (userRole === 'manageOrganization') {
              if (cookie.user.hasOrganizationRole) {
                canView = true;
              }
            } else if (userRole === 'viewFunder') {
              if (cookie.user.hasViewFunderRole) {
                canView = true;
              }
            }
          });
        } else {
          //no specific user role required
          canView = true;
        }
      } else {
        //without a token, you cannot see private routes
        canView = false;
        //console.log('no token, redirect to sign in');
        // deleteCookie();
        // history.push('/sign-in');
      }
    }
    return canView;
  };

  return isAllowed() ? (
    <Route
      {...rest}
      render={matchProps => (
        <Layout>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  ) : (
    <Redirect
      to={
        {
          pathname: props?.redirect,
          state: {
            redirectLink:
              props?.path !== '/dashboard'
                ? props?.location.pathname
                : props?.location?.state?.redirectLink,
            redirect: true,
          },
        } || '/not-found'
      }
    />
  );
};

RouteWithLayout.defaultProps = {
  requireActive: true,
  userAccess: ['admin'],
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  isPrivate: PropTypes.bool,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
  requireActive: PropTypes.bool.isRequired,
  userAccess: PropTypes.array.isRequired,
  redirect: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    state: PropTypes.shape({
      redirectLink: PropTypes.string,
    }),
  }),
};

export default RouteWithLayout;
