import React, { useState, Suspense } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';

import { LoadingPage } from 'components';
import { Sidebar, Footer } from './components';

let useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100%',
    [theme.breakpoints.up('sm')]: {},
  },
  content: {
    height: '100%',
    [theme.breakpoints.up('md')]: {},
  },
}));

const Main = props => {
  const { children } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [openSidebar, setOpenSidebar] = useState(false);
  const [drawerWidth, setDrawerWidth] = useState(73);
  const [padding, setPadding] = useState(73);

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });
  const shouldOpenSidebar = isDesktop ? true : openSidebar;

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const handleCollapse = (collapsed) => {
    if (collapsed) {
      setDrawerWidth(73);
    } else {
      setDrawerWidth(240);
    }

    if (!isDesktop) {
      setPadding(0);
    } else {
      setPadding(drawerWidth);
    }
  }

  return (
    <div
      className={clsx({
        [classes.root]: true,
      })}
      style={{ paddingLeft: padding, transition: 'width 0.2s ease-out' }}
    >
      <Sidebar
        handleCollapse={handleCollapse}
        onClose={handleSidebarClose}
        open={shouldOpenSidebar}
        variant={isDesktop ? 'permanent' : 'temporary'}
      />
      <main className={classes.content}>
        <Suspense fallback={<LoadingPage />}>{children}</Suspense>
      </main>

      <Footer />
    </div >
  );
};

Main.propTypes = {
  children: PropTypes.node,
};

export default Main;
