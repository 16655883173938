/* eslint-disable react/jsx-boolean-value */
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { fundersFetcher, pledgesFetcher } from 'common/fetchers';
import { useQuery } from 'react-query';
import {
  TotalClusters,
  LatestPledges,
  LatestClusters,
  BudgetGoal,
  TotalPledges,
} from './components';

import { loadCookie } from 'common/cookies';
const useStyles = makeStyles(() => ({
  root: {},
}));

const Dashboard = () => {
  const classes = useStyles();
  const { user } = loadCookie();

  const { status, data } = useQuery(
    fundersFetcher.keys.dashboardData,
    () => fundersFetcher.getDashboardData(user?.funderId),
    { refetchOnWindowFocus: false }
  );
  const dashboardData = data?.data || [];
  var isLoading = status === 'loading';

  const { data: fundersPledgeData } = useQuery(
    pledgesFetcher.keys.fetchByFunder,
    () => pledgesFetcher.findByFunder(user?.funderId),
    { refetchOnWindowFocus: false }
  );
  const pledges = fundersPledgeData?.data || [];

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item lg={4} sm={4} xl={4} xs={12}>
          <TotalClusters
            isLoading={isLoading}
            totalClusters={dashboardData?.totalClusters || 0}
            clustersFunded={dashboardData?.funderClustersReceived || 0}
          />
        </Grid>
        <Grid item lg={4} sm={4} xl={4} xs={12}>
          <BudgetGoal
            isFunder={true}
            isLoading={isLoading}
            budgetData={dashboardData?.budgets || []}
            pledgeData={dashboardData?.pledges || []}
            clusterCount={dashboardData?.totalClusters || 0}
            clustersFunded={dashboardData?.clustersFunded || 0}
            budgetCapitalTypeBreakdown={
              dashboardData?.budgetCapitalTypeBreakdown || []
            }
          />
        </Grid>
        <Grid item lg={4} sm={4} xl={4} xs={12}>
          <TotalPledges
            isFunder={true}
            isLoading={isLoading}
            pledgeData={dashboardData?.pledges || []}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <LatestClusters />
        </Grid>
        <Grid item lg={6} xs={12}>
          <LatestPledges
            subHeader={'Made by your Funder Organization'}
            pledges={pledges}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
